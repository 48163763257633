import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import redirectURL from '../redirectURL';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import $ from "jquery";
var moment = require('moment');
// import io from 'socket.io-client';

const material_list = [
    { label: "Dimensional Cargo", value: "Dimensional Cargo" },
    { label: "Cabin", value: "Cabin" },
    { label: "Frame Assembly", value: "Frame Assembly" },
    { label: "FSM", value: "FSM" },
    { label: "General", value: "General" },
    { label: "Others", value: "others" },
]

const bidvalidityoptions = [
    { value: "2", label: "2 Hours" },
    { value: "4", label: "4 Hours" },
    { value: "6", label: "6 Hours" },
    { value: "8", label: "8 Hours" },
    { value: "12", label: "12 Hours" },
    { value: "16", label: "16 Hours" },
    { value: "20", label: "20 Hours" },
    { value: "24", label: "24 Hours" },
]

const unitsOptions1 = [
    { value: "truck", label: "Truck(FTL)" },
    { value: "metric_tons", label: "Metric Tonnes(PTL)" },
    { value: "kilogram", label: "Kilogram(Courier)" },
    { value: "box", label: "Box(FTL/PTL)" }]

const truck_list = [
    { value: "32 Single axle", label: "32 Single axle" },
    { value: "32 multi axle", label: "32 multi axle" },
    { value: "Trailer", label: "Trailer" },
    { value: "24 feet vehicle", label: "24 feet vehicle" },
    { value: "17 feet vehicle", label: "17 feet vehicle" },
    { value: "19 feet vehicle", label: "19 feet vehicle" },
    { value: "14 feet vehicle", label: "14 feet vehicle" },
    { value: "1MT", label: "1MT" },
    { value: "others", label: "Others" },
]
const typeoftrucksoption = [
    { value: "6 Wheeler", label: "6 Wheeler" },
    { value: "8 Wheeler", label: "8 Wheeler" },
    { value: "10 Wheeler", label: "10 Wheeler" },
    { value: "12 Wheeler", label: "12 Wheeler" },
    { value: "14 Wheeler", label: "14 Wheeler" },
    { value: "Container 20ft", label: "Container 20ft" },
    { value: "Container 40ft", label: "Container 40ft" },
    { value: "Refrigerated Truck", label: "Refrigerated Truck" },
    { value: "Open Truck", label: "Open Truck" },
    { value: "Tanker", label: "Tanker" },
    { value: "Flatbed Truck", label: "Flatbed Truck" },
    { value: "Lowboy Truck", label: "Lowboy Truck" },
    { value: "Mini Truck", label: "Mini Truck" },
    { value: "Tipper Truck", label: "Tipper Truck" },
    { value: "Trailer Truck", label: "Trailer Truck" },
    { value: "Box Truck", label: "Box Truck" }
]; const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    // const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
};

var typeofselection = [{ 'label': 'Spot', 'value': 'spot' }, { 'label': 'Contract', 'value': 'contract' }]
var incotermsoption = [{ 'label': 'EXW', 'value': 'EXW' }, { 'label': 'FOB', 'value': 'FOB' }, { 'label': 'FCA', 'value': 'FCA' }, { 'label': 'FAS', 'value': 'FAS' }, { 'label': 'CIF', 'value': 'CIF' }, { 'label': 'CNF', 'value': 'CNF' }, { 'label': 'CPT', 'value': 'CPT' }, { 'label': 'CIP', 'value': 'CIP' }]
var pick_drop_option = [{ 'label': 'PORT(ORIGIN) TO PORT(DESTINATION)', 'value': 'port_to_port' }, { 'label': 'PORT(ORIGIN) TO DOOR(DESTINATION)', 'value': 'pprt_to_door' }, { 'label': 'DOOR(ORIGIN) TO PORT(DESTINATION)', 'value': 'door_to_port' }, { 'label': 'DOOR(ORIGIN) TO DOOR(DESTINATION)', 'value': 'door_to_door' }]
var origin_port_list = [{ 'label': 'Mundra', 'value': 'mundra' }, { 'label': 'Pipara', 'value': 'pipara' }, { 'label': 'ICS Sanand', 'value': 'icd_sanand' }, { 'label': 'ICD Garhi', 'value': 'icd_garhi' }, { 'label': 'Chennai', 'value': 'chennai' }, { 'label': 'Kattupalli', 'value': 'kattupalli' }]
var destination_port_list = [{ 'label': 'Mundra', 'value': 'mundra' }, { 'label': 'Pipara', 'value': 'pipara' }, { 'label': 'ICS Sanand', 'value': 'icd_sanand' }, { 'label': 'ICD Garhi', 'value': 'icd_garhi' }, { 'label': 'Chennai', 'value': 'chennai' }, { 'label': 'Kattupalli', 'value': 'kattupalli' }]
var stuffing_location_list = [{ 'label': 'Dock/CFS', 'value': 'dock_cfs' }, { 'label': 'Factory', 'value': 'factory' }]
var destuffing_location_list = [{ 'label': 'Dock/CFS', 'value': 'dock_cfs' }, { 'label': 'Factory', 'value': 'factory' }, { 'label': 'DPD', 'value': 'dpd' }]
var hsn_code_list = [{ 'label': '84', 'value': '84' }, { 'label': '85', 'value': '85' }, { 'label': '27', 'value': '27' }, { 'label': '72', 'value': '72' }, { 'label': '87', 'value': '87' }, { 'label': '39', 'value': '39' }, { 'label': '90', 'value': '90' }, { 'label': '28-38', 'value': '28-38' }, { 'label': '50-63', 'value': '50-63' }, { 'label': '44', 'value': '44' }]
var payment_terms_list = [{ 'label': 'As per agreed terms', 'value': 'As per agreed terms' }]
const now = new Date();

const CreateRFQComponent = (props) => {
    const { rfq_id } = props.match.params
    const [rfqForm, setRfqForm] = useState({
        auction_type: 'spot',
        bid_start_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        additional_comments: '',
        quotation_validity_hours: 8,
        dealer_pincode: '',
        dealer_city: '',
        payment_terms: 'As per agreed terms'
        // { 'label': 'As per agreed terms', 'value': 'As per agreed terms' }
    });
    const [vendorslist,setvendorslist] = useState([])
    const [vendorsemail,setvendorsemail] = useState([])
    const [group_names,setgroup_names] = useState([])
    const [rfqFormforsea, setRfqFormforsea] = useState({
        auction_type: 'spot',
        bid_start_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        additional_comments: '',
        quotation_validity_hours: 8,
        payment_terms1: '',
        destuffing_location: '',
        origin_address: '',
        origin_port: '',
        additional_details: '',
        stuffing_location: '',
        destination_port: '',
        destination_address: '',
        cargo_invoice_value: '',
        hs_code: '',
        product: '',
        pickup_date: '',
        bid_close_date: '',
        upload_file_sea: ''
        // weight_20ft_Unit:{'label':'','value':''}
    });
    const [loadshow, setLoadshow] = useState("show-n")
    const [activeTab, setActiveTab] = useState('totalShipment');
    const [weight_20ft_Unit, setweight_20ft_Unit] = useState('KG')
    const [base_price_sea, set_base_price_sea] = useState('')
    const [volume_20ft_Unit, setvolume_20ft_Unit] = useState('CBM')
    const [weight_20ft, setweight_20ft] = useState('')
    const [volume_20ft, setvolume_20ft] = useState('')
    const [show, setShow] = useState(false)
    const [containerInfo, setcontainerInfo] = useState({
        movementType: 'import',
        shipmentType: 'road',
        cargoMode: 'fcl'
    })
    const [containers, setContainers] = useState([
        { weight: '', unit: '20ft' }
    ]);
    const [basicType, setBasicType] = useState("")
    const [formshow, setformshow] = useState('road')
    const [basicTitle, setBasicTitle] = useState("")
    const [vendorOptions, setVendorOptions] = useState([])
    const [dealersdata, setdealersdata] = useState([])
    const [citiesoption, setcitiesoption] = useState([])
    const [dealer_pincode_list, setdealer_pincode_list] = useState([])
    const [dealer_city_list, setdealer_city_list] = useState([])
    const [drag_file, setdrag_file] = useState('')
    const [source_list, setsource_list] = useState([])
    const [dealers, setDealers] = useState([
        { dealer_code: '', dealer_city: '', dealer_pincode: '', material: '', material_length: '', material_breath: '', material_height: '', material_units: '', quantity: '' }
    ]);

    // const socket = React.useRef(null);
    useEffect(() => {
          var source_list = [{'label':'Hosur','value':'Hosur'},{'label':'Indore','value':'Indore'},{'label':'Alwar','value':'Alwar'},{'label':'Pantnagar','value':'PNR'}]
        // redirectURL.post('/consignments/getsourcelist').then((resp) => {
        //     if (resp.data != '' && resp.data != undefined) {
        //         var source = resp.data
        //         source.map((e) => {
        //             if (e.plant_name != '' && e.plant_name != undefined) {
        //                 source_list.push({ 'label': e.plant_name, 'value': e.plant_name })
        //             }
        //         })
        //         source_list.push({ 'label': 'Others', 'value': 'others' })
        //     }
        // })

        setsource_list(source_list)
        // socket.current = io('http://34.131.191.3:5030', {
        //     transports: ['websocket'], // Ensure WebSocket transport is used
        //     reconnectionAttempts: 5,    // Retry connecting a few times
        //     reconnectionDelay: 1000,    // Optional: delay between retries
        // });

        // // Listen for connect event
        // socket.current.on('connect', () => {
        //     console.log('Connected to the server');
        // });

        // // Listen for connection errors
        // socket.current.on('connect_error', (error) => {
        //     console.error('Connection Error:', error);
        // });

        // // Cleanup function to disconnect the socket when the component is unmounted
        // return () => {
        //     socket.current.disconnect();
        //     socket.current.off('newBid');
        // };
    }, []);
    const [originCharges, setOriginCharges] = useState({
        cfsWarehouse: true,
        port: true,
        customsClearance: true,
    });

    const [destinationCharges, setDestinationCharges] = useState({
        cfsWarehouse1: true,
        port1: true,
        customsClearance1: true,
    });
    // const [containers, setcontainers] = useState([
    //     { label: '20ft', value: '' },
    //     { label: '40ft', value: '' },
    //     { label: '40ft HC', value: '' },
    //     { label: '45ft', value: '' },
    //     { label: 'ISO Tank', value: '' },
    //     { label: 'Flexitank', value: '' },
    // ]);
    const [packageCount, setPackageCount] = useState(0);
    const [packageCount_unit, set_packagecount_unit] = useState('')
    const [length, setLength] = useState('');
    const [breadth, setBreadth] = useState('');
    const [height, setHeight] = useState('');
    const [grossWeight, setGrossWeight] = useState('');
    const [volume, setVolume] = useState(0);
    const [gross_wt_units, setgross_wt_units] = useState('KG')
    const [packages, setPackages] = useState([
        { packageCount: '', packageCount_unit: 'Carton', length: '', breadth: '', height: '', dimensionUnit: 'cm', grossWeight: '', gross_wt_units: 'KG' },
    ]);
    const [vendormaster,setVendormaster] = useState([])

    useEffect(() => {
        redirectURL.post("/consignments/fetchvendorlist").then((resp) => {
            var vendors = resp.data
            var vendorslist = []
            vendors.map((e) => {
                if (e.email != '' && e.email != undefined) {
                    vendorslist.push({ 'label': e.firstname, 'value': e.email })
                }
            })
            setVendormaster(vendorslist)
            // setVendorOptions(vendorslist)

        })
        redirectURL.post("/consignments/fetchdealerslist").then((resp) => {
            var dealers = resp.data
            var dealerslist = []
            var dealercitylist = []
            var dealerpincodelist = []
            setdealersdata(dealers)
            dealers.map((e) => {
                if (e.consignee_code != 'Demo' && e.consignee_city != '' && e.consignee_city != undefined) {
                    dealerslist.push({ 'label': e.internal_code, 'value': e.consignee_name })
                    dealercitylist.push({ 'label': e.consignee_city, 'value': e.consignee_city })
                    dealerpincodelist.push({ 'label': e.consignee_pincode, 'value': e.consignee_pincode })
                }
            })
            setcitiesoption(dealerslist)
            setdealer_city_list(dealercitylist)
            setdealer_pincode_list(dealerpincodelist)
        })
        const fetchData = async () => {


            try {
                const resp = await redirectURL.post('consignments/fetchVendors');
                var vendors = resp.data
                let vendorOptions = [];
                let categoryOptions = [];

                vendors.forEach(e => {
                    // Add vendor name as label and email_id as value (individual vendors)
                    vendorOptions.push({ label: e.vendor_name, value: [e.email_id] });

                    // Find if the category already exists in categoryOptions
                    let categoryOption = categoryOptions.find(f => f.label === e.category);

                    if (!categoryOption) {
                        // If the category doesn't exist, add it to the categoryOptions
                        categoryOptions.push({ label: e.category, value: [e.email_id] });
                    } else {
                        // If the category exists, push the email_id to the category's value array
                        categoryOption.value.push(e.email_id);
                    }
                });

                // Append category options to the end of vendorOptions
                vendorOptions = vendorOptions.concat(categoryOptions);


                //  setVendorOptions(vendorOptions)

                if (rfq_id) {
                    try {
                        const response = await redirectURL.post('consignments/fetchRFQs', { _id: rfq_id });
                        const record = response.data[0];
                        var destination_list = record.destination
                        destination_list = JSON.parse(destination_list)
                        var vendors = record.vendors_names

                        vendors = JSON.parse(vendors)
                        setgroup_names(vendors)
                        var destination = []

                        var vendor_list = []
                        vendors.map((e) => {
                            vendor_list.push({ label: e, value: e })
                        })
                        console.log(vendor_list,'vendor_list392')
                        const vendorEmails = record.vendors || []; // Assuming record.vendors contains an array of email IDs
                        const matchedVendors = vendorEmails
                            .map(email => vendorOptions.find(option => option.value.length === 1 && option.value[0] === email))
                            .filter(Boolean); // Remove any undefined entries if an email has no match
                            var selected_source = record.source
                            if(selected_source=='Hosur'){
                                var vendors = ["Winex","Jai Mata","NEGL","VRL","BGFC","inland"]
                                setVendorOptions([ { label: 'Winex', value: 'Winex' },
                                 { label: 'Jai Mata', value: 'Jai Mata' },
                                 { label: 'NEGL', value: 'NEGL' },
                                 { label: 'VRL', value: 'VRL' },
                                 { label: 'BGFC', value: 'BGFC' },
                                 { label: 'inland', value: 'inland' }])
                             }
                             else if(selected_source =='Indore'){
                                 var vendors = ["Mass","Madhur","Avyansh","Jai Mata","NEGL","VRL"]
                                 setVendorOptions([ { label: 'Mass', value: 'Mass' },
                                     { label: 'Madhur', value: 'Madhur' },
                                     { label: 'Avyansh', value: 'Avyansh' },
                                     { label: 'Jai Mata', value: 'Jai Mata' },
                                     { label: 'NEGL', value: 'NEGL' },
                                     { label: 'VRL', value: 'VRL' }])
                             }
                             else if(selected_source =='Alwar'){
                                 var vendors = ["shree jain","BGFC","Jai Mata","NEGL"]
                                 setVendorOptions([ { label: 'shree jain', value: 'shree jain' },
                                     { label: 'BGFC', value: 'BGFC' },
                                     { label: 'Jai Mata', value: 'Jai Mata' },
                                     { label: 'NEGL', value: 'NEGL' }])
                             }
                             else if(selected_source =='PNR'){
                                 var vendors = ["VRL","BGFC","Jai Mata","NEGL"]
                                 setVendorOptions([  { label: 'VRL', value: 'VRL' },
                                     { label: 'BGFC', value: 'BGFC' },
                                     { label: 'Jai Mata', value: 'Jai Mata' },
                                     { label: 'NEGL', value: 'NEGL' }])
                             }
                             var params ={vendors:vendors}
                             var vendors_mail = []
                             redirectURL.post('/consignments/getvendorsemails',params).then((resp)=>{
                                 console.log('950',resp.data)
                                 var data = resp.data 
                                 var vendors_mail = []
                                 data.map((e)=>{
                                     vendors_mail.push({'label':e.firstname,'value':e.email})
                                 })
                                 console.log(vendors_mail,'vendors_mailvendors_mail')
                                 vendors_mail = vendors_mail
                                 setvendorsemail(vendors_mail)
                                //  setRfqForm({ ...rfqForm, vendors_mail: vendors_mail });
                             })
                        setRfqForm(prevState => ({
                            ...prevState,
                            ...record,
                            bid_start_date: moment(giveUTCDate(record.bid_start_date)).utcOffset("+05:30").format("YYYY-MM-DD HH:mm:ss"),
                            pickup_date: moment(giveUTCDate(record.pickup_date)).format("YYYY-MM-DD HH:mm:ss"),
                            vendors: vendor_list,
                            destination: destination,
                            bidVisibility: record.show_bids,
                            source:record.source,
                            vendors_mail: vendors_mail
                        }));
                        setvendorslist(vendor_list)
                        setDealers(
                            destination_list.map(destination => ({
                                dealer_code: destination.dealer_code,
                                dealer_city: destination.dealer_city,
                                dealer_pincode: destination.dealer_pincode,
                                material: destination.material,
                                material_length: destination.material_length,
                                material_breath: destination.material_breath,
                                material_height: destination.material_height,
                                material_units: destination.material_units,
                                quantity: destination.quantity
                            }))
                        );

                    } catch (error) {
                        console.error("Error fetching RFQ events:", error);
                    }
                }

            } catch (error) {
                console.error("Error fetching participants:", error);
            }
        };

        fetchData();
    }, [rfq_id]);

    const calculateVolume = (l, b, h) => {
        if (l && b && h) {
            return (l * b * h) / 1000000; // Convert cm to CBM
        }
        return 0;
    };

    // Update volume whenever dimensions change
    const updateVolume = () => {
        const vol = calculateVolume(length, breadth, height);
        setVolume(vol.toFixed(2)); // Round to 2 decimal places
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRfqForm({ ...rfqForm, [name]: value });
    };

    const handleInputChange1 = (e) => {
        const { name, value } = e.target
        console.log(name, value, '313')
        setRfqFormforsea({ ...rfqFormforsea, [name]: value });
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (!file) return;  // Check if a file is selected

        const fileName = file.name;
        const fileExtension = fileName.split(".").pop().toLowerCase();

        console.log(fileExtension)// Get file extension in lowercase

        const validExtensions = ["pdf", "png", "jpeg"];

        if (validExtensions.includes(fileExtension)) {
            setdrag_file(e.target.files[0])
        } else {
            alert("Invalid file type. Only PDF, PNG, and JPEG files are allowed.");
            $("#payment_terms").val("")
        }
    };
    const onClickmultipleinput = () => {
        setContainers([...containers, { weight: '', unit: '20ft' }]);
        console.log('Container added');
    };


    const addDealerForm = () => {
        if (dealers.length < 10) {
            setDealers([
                ...dealers,
                { dealer_code: '', dealer_city: '', dealer_pincode: '', material: '', material_length: '', material_breath: '', material_height: '', material_units: '', quantity: '' }
            ]);
        } else {
            alert("You can add up to 10 dealers only.");
        }
    };

    const removeDealerForm = (index) => {
        setDealers(dealers.filter((_, i) => i !== index));
    };


    // Function to handle input change for weight
    const handleWeightChange = (index, value) => {
        const updatedContainers = [...containers];
        updatedContainers[index].weight = value;
        setContainers(updatedContainers);
    };

    // Function to handle select change for unit
    const handleUnitChange = (index, value) => {
        const updatedContainers = [...containers];
        updatedContainers[index].unit = value;
        setContainers(updatedContainers);
    };

    // Function to remove a container input
    const removeContainer = (index) => {
        const updatedContainers = containers.filter((_, i) => i !== index);
        setContainers(updatedContainers);
    };
    const onchangeFileHandler = (e) => {
        if (!e.target || !e.target.files || e.target.files.length === 0) {
            // If there's no file selected, exit the function
            return;
        }

        const file = e.target.files[0];
        const fileName = file.name;
        const fileExtension = fileName.split(".").pop().toLowerCase();

        console.log(fileExtension); // Log file extension in lowercase

        const validExtensions = ["pdf", "png", "jpeg"];

        if (validExtensions.includes(fileExtension)) {
            setRfqForm((prev) => ({
                ...prev,
                uploadFile: file,
            }));
        } else {
            alert("Invalid file type. Only PDF, PNG, and JPEG files are allowed.");
            document.getElementById("uploadFile").value = ""; // Clear the input
        }
    };
    // const handleDivClick = () => {
    //     document.getElementById('fileInput').click(); // Trigger the file input when div is clicked
    // };
    const onchangeFileHandler1 = (e) => {
        const file = e.target.files[0];

        if (!file) return;  // Check if a file is selected

        const fileName = file.name;
        const fileExtension = fileName.split(".").pop().toLowerCase();

        console.log(fileExtension)// Get file extension in lowercase

        const validExtensions = ["pdf", "png", "jpeg"];

        if (validExtensions.includes(fileExtension)) {
            setRfqFormforsea((prev) => {
                return { ...prev, upload_file_sea: e.target.files[0] }
            })
        } else {
            alert("Invalid file type. Only PDF, PNG, and JPEG files are allowed.");
            $("#payment_terms").val("")
        }
    };


    function handleCheckboxChange(field, value) {
        setRfqForm(prevState => ({
            ...prevState,
            [field]: value
        }));
    }
    const handleOriginChange = (e) => {
        const { name, checked } = e.target;
        setOriginCharges((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const handleDestinationChange = (e) => {
        console.log(e.target, '430')
        const { name, checked } = e.target;
        setDestinationCharges((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };
    const onClickButtonHandler1 = (cargoMode, e) => {
        setcontainerInfo({ ...containerInfo, [cargoMode]: e });

    }
    const onClickButtonHandler2 = (movementType, e) => {
        setcontainerInfo({ ...containerInfo, [movementType]: e });
    }
    const onClickButtonHandler = (shipment_type, e) => {
        if (e == 'road') {
            setformshow('road')
            setcontainerInfo({ ...containerInfo, [shipment_type]: e });
        }
        else if (e == 'sea') {
            setformshow('sea')
            setcontainerInfo({ ...containerInfo, [shipment_type]: e });
        }
        else if (e == 'air') {
            setformshow('air')
            setcontainerInfo({ ...containerInfo, [shipment_type]: e });
        }
    }
    const onClickcreaterfqforsea = (e) => {
        e.preventDefault()
        console.log('sea', '299')
        var saveData = new FormData()
        if (rfqFormforsea.upload_file_sea) {
            saveData.append("upload_file_sea", rfqForm.upload_file_sea)
        }
        var selected_containers = []
        var containers_list = []
        var no_of_containers = 0
        var container_type = ''
        console.log(containers, '511')
        containers.map((e) => {
            if (e != '' && e != undefined) {
                if (e.weight != '' && e.weight != undefined) {
                    const weight = parseInt(e.weight, 10) || 0;
                    selected_containers.push({ 'container_type': e.unit, 'container_qty': weight })
                    no_of_containers += weight
                }
            }

        })
        let no_of_participants = 0;
        let selectedvendors = [];

        rfqFormforsea.vendors.forEach(e => {
            e.value.forEach(f => {
                if (!selectedvendors.includes(f)) {
                    selectedvendors.push(f);
                    no_of_participants += 1; // Correct increment
                }
            });
        });
        var movement
        if (containerInfo.movementType == 'import') {
            movement = 'IMPORT'
        }
        else if (containerInfo.movementType == 'export') {
            movement = 'EXPORT'
        }
        //additional details check 
        if (rfqFormforsea.additional_details != '' && rfqFormforsea.additional_details != undefined) {
            saveData.append("additional_comments_check", "1")
        }
        saveData.append("movement_type", movement)
        saveData.append("no_of_participants", no_of_participants)
        saveData.append("vendors", JSON.stringify(selectedvendors))
        saveData.append("cargo_type", rfqFormforsea.cargo_type)
        saveData.append("weight_20ft", weight_20ft)
        saveData.append("weight_20ft_Unit", weight_20ft_Unit)
        saveData.append("no_of_containers", no_of_containers)
        saveData.append("quantity", no_of_containers)
        // saveData.append("container_type", selected_containers.label)
        // saveData.append("type_of_truck", selected_containers.label)
        saveData.append("volume_20ft", volume_20ft)
        saveData.append("volume_20ft_Unit", volume_20ft_Unit)
        saveData.append("containers", JSON.stringify(selected_containers))
        saveData.append("incoterm", rfqFormforsea.incoterm)
        saveData.append("pick_and_drop", rfqFormforsea.pick_and_drop)
        saveData.append("destuffing_location", rfqFormforsea.destuffing_location)
        saveData.append("source", rfqFormforsea.origin_address)
        saveData.append("origin_port", rfqFormforsea.origin_port)
        saveData.append("stuffing_location", rfqFormforsea.stuffing_location)
        saveData.append("destination_port", rfqFormforsea.destination_port)
        saveData.append('destination', rfqFormforsea.destination_address)
        saveData.append("cargo_invoice_value", rfqFormforsea.cargo_invoice_value)
        saveData.append("hs_code", rfqFormforsea.hs_code)
        saveData.append("product", rfqFormforsea.product)
        saveData.append("material", rfqFormforsea.material)
        saveData.append("customsClearance_origin", originCharges.customsClearance)
        saveData.append("cfsWarehouse_origin", originCharges.cfsWarehouse)
        saveData.append("port_origin", originCharges.port)
        saveData.append("customsClearance_destination", destinationCharges.customsClearance1)
        saveData.append("cfsWarehouse_destination", destinationCharges.cfsWarehouse1)
        saveData.append('port_destination', destinationCharges.port1)
        saveData.append('additional_details', rfqFormforsea.additional_details)
        saveData.append('bid_close_date', rfqFormforsea.bid_close_date)
        saveData.append('pickup_date', rfqFormforsea.pickup_date)
        saveData.append('base_price', base_price_sea)
        saveData.append('packageCount_unit', packageCount_unit)
        saveData.append('packageCount', packageCount)
        saveData.append('length', length)
        saveData.append('breadth', breadth)
        saveData.append('height', height)
        saveData.append('gross_wt_units', gross_wt_units)
        saveData.append('grossWeight', grossWeight)
        saveData.append('created_by', localStorage.getItem('email'))
        saveData.append('quotation_validity_hours', rfqFormforsea.quotation_validity_hours)
        console.log(saveData, '348')
        redirectURL.post('consignments/saverfqindentforsea', saveData).then((response) => {
            setShow(true)
            setBasicType(response.data.status == "success" ? "success" : "danger")
            setBasicTitle(response.data.message)
            setLoadshow("show-n")
        })
    }
    const onClickcreaterfq = (e) => {
        e.preventDefault()
        if (formshow == 'road') {

            if ([undefined, null, "", "undefined"].includes(rfqForm.pickup_date)) {
                setShow(true)
                setBasicType(false)
                setBasicTitle("Select pickup date")
                return
            }
            //insert new source in db
            var params = { 'plant_name': rfqForm.selected_source_type }
            redirectURL.post('/consignments/insertsource', params).then((resp) => {

            })
            const formData = new FormData();

            let no_of_participants = 0;
            let selectedvendors = [];
             let vendors_names = [];

           // Check if rfqForm.vendors_mail is empty or undefined
const vendorsToProcess = (rfqForm.vendors_mail && rfqForm.vendors_mail.length > 0)
? rfqForm.vendors_mail // Use rfqForm.vendors_mail if not empty
: vendorsemail;        // Fallback to vendorsemail if empty

// Process the selected vendors
vendorsToProcess.map((e) => {
if (!selectedvendors.includes(e.value)) {
    selectedvendors.push(e.value);
    // vendors_names.push(e.label); // Uncomment if needed
    no_of_participants += 1; // Correct increment
}
});

            var saveData = new FormData()

            if (rfqForm.payment_terms) {
                saveData.append("payment_terms", rfqForm.payment_terms)
            }
            console.log(rfqForm, '730')
            if (rfqForm.source == 'others') {
                saveData.append('source', rfqForm.selected_source_type)
            }
            else {
                saveData.append("source", rfqForm.source)
            }
            if (rfqForm.type_of_truck == 'others') {
                saveData.append('type_of_truck', rfqForm.selected_tryck_type)
            }
            else {
                saveData.append("type_of_truck", rfqForm.type_of_truck)
            }
            saveData.append("podDoc", rfqForm.uploadFile);
            saveData.append("auction_type", rfqForm.auction_type)
            // saveData.append("destination", rfqForm.destination)
            saveData.append("material", rfqForm.material)
            saveData.append("material_type", rfqForm.selected_material_type)

            saveData.append("units", rfqForm.units)
            saveData.append("quantity", rfqForm.quantity)
            saveData.append("bid_start_date", rfqForm.bid_start_date)
            saveData.append("quotation_validity_hours", rfqForm.quotation_validity_hours)
            saveData.append("pickup_date", rfqForm.pickup_date)
            saveData.append("vendors", JSON.stringify(selectedvendors))
            saveData.append("vendors_names", JSON.stringify(group_names))

            saveData.append("destination", JSON.stringify(dealers))

            saveData.append("additional_comments", rfqForm.additional_comments)
            saveData.append('no_of_participants', no_of_participants)
            saveData.append('total_km', rfqForm.total_km)

            if (rfqForm.without_insurance == true) {
                saveData.append("without_insurance", 1)
            }
            if (rfqForm.bidVisibility == true) {
                saveData.append("show_bids", 1)
            }

            if (rfqForm.base_price != '' && rfqForm.base_price != undefined) {
                saveData.append("base_price", rfqForm.base_price)
            }
            else {
                saveData.append("base_price", "0")
            }



            if (rfqForm.over_height == true) {
                saveData.append("over_height", 1)
            }
            if (rfqForm.show_bids == true) {
                saveData.append("show_bids", 1)
            }
            if (rfqForm.over_length == true) {
                saveData.append("over_length", 1)
            }

            if (rfq_id) {
                saveData.append("rfq_id", rfq_id)
            }
            if (rfqForm.indent_id) {
                saveData.append("indent_id", rfqForm.indent_id)
            }

            saveData.append("email", localStorage.getItem("email"))
            console.log(selectedvendors, '730')
            setLoadshow("show-m")
            // Make the POST request
            var dealers_arr = dealers;
            console.log(dealers_arr, '811');

            // Check if any dealer has missing or empty data for `dealer_code`, `material`, or `quantity`
            var missing_dealer_data = dealers_arr.some((e) => {
                return (
                    e.dealer_code === undefined || e.dealer_code === "" ||
                    e.material === undefined || e.material === "" ||
                    e.quantity === undefined || e.quantity === ""
                );
            });
            redirectURL.post('/billing/addOrEditRFQ', saveData)
                .then(response => {
                    setShow(true);
                    setBasicType(response.data.status === "success" ? "success" : "danger");
                    setBasicTitle(response.data.message);
                    setLoadshow("show-n");
                })
                .catch(error => {
                    console.error('Error:', error.response || error.message);
                });
            // var formData = new FormData(e.target);
            // formData.append('uploadFile', rfqForm.uploadFile);
            // redirectURL.post("/consignments/savefileforroad", formData, {
            //     headers: {
            //         'content-type': 'multipart/form-data'
            //     }
            // }).then((response) => {

            // }).catch((e) => {
            //     console.log(e);
            // })

            // if (missing_dealer_data) {
            //     redirectURL.post('consignments/addOrEditRFQ', saveData)
            //         .then(response => {
            //             setShow(true);
            //             setBasicType(response.data.status === "success" ? "success" : "danger");
            //             setBasicTitle(response.data.message);
            //             setLoadshow("show-n");
            //         })
            //         .catch(error => {
            //             console.error('Error:', error.response || error.message);
            //         });
            // }
            // else{
            //     setShow(true);
            //     setBasicType('danger');
            //     setBasicTitle('Please Select All Manditory');
            //     setLoadshow("show-n");
            // }



        }
        // else if(formshow=='sea'){
        //     console.log('sea')
        // }
    }

    const onSelectItems = items => {
        try {
            console.log(items,'952')
            var vendor_names = items
            var selected_vendor_names=[]
            if (vendor_names!= '' && vendor_names != undefined) {
                vendor_names.map(e => {
                    if (!selected_vendor_names.includes(e.value)) {
                        selected_vendor_names.push(e.value);
                    }
                });
            }
            console.log(selected_vendor_names,'selected_vendor_names')
            setgroup_names(selected_vendor_names)
            setRfqForm({ ...rfqForm, vendors: items });
            setvendorslist(items)
            var params ={vendors:selected_vendor_names}
            redirectURL.post('/consignments/getvendorsemails',params).then((resp)=>{
                console.log('950',resp.data)
                var data = resp.data 
                var vendors_mail = []
                data.map((e)=>{
                    vendors_mail.push({'label':e.firstname,'value':e.email})
                })
                setRfqForm({ ...rfqForm, vendors_mail: vendors_mail });

               
            })
        } catch (e) { }
    }
    // const onSelectItemsfordealers = items => {
    //     try {
    //         setRfqForm({ ...rfqForm, destination: items });
    //     } catch (e) { }
    // }
    const onSelectItemsfordealers = items => {
        try {
            if (items.length <= 10) { // Limit to 10 selections
                setRfqForm({ ...rfqForm, destination: items });
            } else {
                alert("You can select up to 10 options only.");
            }
        } catch (e) {
            console.error("Error selecting items:", e);
        }
    };


    const onSelectItems3 = (selectedOptions) => {
        rfqForm.destination = selectedOptions.map(option => ({
            value: Array.isArray(option.value) ? option.value : [option.value],
            label: option.label
        }));
        // Trigger any necessary state updates here
    };


    const onSelectItems1 = items => {

        try {
            setRfqFormforsea({ ...rfqFormforsea, vendors: items });

        } catch (e) { }
    }

    const handleSelectChange = (name, selectedOption) => {
        setRfqForm({ ...rfqForm, [name]: selectedOption.value });
    };
    
    const handleSelectChangeforsource = (name, selectedOption) => {
        setRfqForm({ ...rfqForm, [name]: selectedOption.value });
        // setVendorOptions 
        var selected_source = selectedOption.value 
        if(selected_source=='Hosur'){
           var vendors = ["Winex","Jai Mata","NEGL","VRL","BGFC","inland"]
           setVendorOptions([ { label: 'Winex', value: 'Winex' },
            { label: 'Jai Mata', value: 'Jai Mata' },
            { label: 'NEGL', value: 'NEGL' },
            { label: 'VRL', value: 'VRL' },
            { label: 'BGFC', value: 'BGFC' },
            { label: 'inland', value: 'inland' }])
        }
        else if(selected_source =='Indore'){
            var vendors = ["Mass","Madhur","Avyansh","Jai Mata","NEGL","VRL"]
            setVendorOptions([ { label: 'Mass', value: 'Mass' },
                { label: 'Madhur', value: 'Madhur' },
                { label: 'Avyansh', value: 'Avyansh' },
                { label: 'Jai Mata', value: 'Jai Mata' },
                { label: 'NEGL', value: 'NEGL' },
                { label: 'VRL', value: 'VRL' }])
        }
        else if(selected_source =='Alwar'){
            var vendors = ["shree jain","BGFC","Jai Mata","NEGL"]
            setVendorOptions([ { label: 'shree jain', value: 'shree jain' },
                { label: 'BGFC', value: 'BGFC' },
                { label: 'Jai Mata', value: 'Jai Mata' },
                { label: 'NEGL', value: 'NEGL' }])
        }
        else if(selected_source =='PNR'){
            var vendors = ["VRL","BGFC","Jai Mata","NEGL"]
            setVendorOptions([  { label: 'VRL', value: 'VRL' },
                { label: 'BGFC', value: 'BGFC' },
                { label: 'Jai Mata', value: 'Jai Mata' },
                { label: 'NEGL', value: 'NEGL' }])
        }
        var params = {selected_source:selected_source,vendors:vendors}

    
    };
    const handleInputChangefordealer = (index, field, value) => {
        const updatedDealers = [...dealers];
        updatedDealers[index] = {
            ...updatedDealers[index],
            [field]: value // Dynamically set the field based on the input
        };
        setDealers(updatedDealers);
    };

    const select_dealer_code = (index, selectedOption) => {
        const updatedDealers = [...dealers];
        console.log(selectedOption, 'selectedOption')
        const selected_dealer = dealersdata.find(e => e.consignee_name == selectedOption.value);
        console.log(selected_dealer, 'selected_dealer')
        if (selected_dealer) {
            updatedDealers[index] = {
                ...updatedDealers[index],
                dealer_code: selectedOption.value,
                dealer_city: selected_dealer.consignee_city,
                dealer_pincode: selected_dealer.consignee_pincode
            };
        } else {
            console.warn("Dealer not found in dealersdata for the selected option:", selectedOption.value);
            updatedDealers[index] = {
                ...updatedDealers[index],
                dealer_code: selectedOption.value,
                dealer_city: "",
                dealer_pincode: ""
            };
        }
        console.log(updatedDealers, 'updatedDealers')
        setDealers(updatedDealers);
    };




    const handleSelectChangeforsea = (name, selectedOption) => {
        setRfqFormforsea({ ...rfqFormforsea, [name]: selectedOption.value });
    };



    const handlerStartDateTime = (name, event) => {
        var d = new Date(event._d);



        // Update the form state with the formatted date and time
        setRfqForm({ ...rfqForm, bid_start_date: d });

    }
    const handlercloseDateTime = (name, event) => {
        var d = new Date(event._d);

        // Format date as YYYY-MM-DD
        var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        // Format time as HH:mm
        var starttime = (d.getHours() < 10 ? "0" + d.getHours() : d.getHours()) + ":" + (d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes());

        // Combine date and time
        var formattedStartDateTime = startdate + " " + starttime;

        // Update the form state with the formatted date and time
        setRfqFormforsea({ ...rfqFormforsea, bid_close_date: d });

    }
    const handlerpickupDateTimeforsea = (name, event) => {
        var d = new Date(event._d);

        // Format date as YYYY-MM-DD
        var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        // Format time as HH:mm
        var starttime = (d.getHours() < 10 ? "0" + d.getHours() : d.getHours()) + ":" + (d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes());

        // Combine date and time
        var formattedStartDateTime = startdate + " " + starttime;

        // Update the form state with the formatted date and time
        setRfqFormforsea({ ...rfqFormforsea, pickup_date: d });

    }


    const handlerPickupDateTime = (name, event) => {
        var d = new Date(event._d);
        setRfqForm({ ...rfqForm, pickup_date: d });

    }
    const handlerPickupDateTime1 = (name, event) => {
        var d = new Date(event._d);
        setRfqFormforsea({ ...rfqFormforsea, pickup_date: d });

    }
    const handlerStartDateTime1 = (name, event) => {
        var d = new Date(event._d);
        setRfqFormforsea({ ...rfqFormforsea, bid_start_date: d });

    }

    const headStyle = {
        marginBottom: "1.5%"
    };



    const [dragFile, setDragFile] = useState(null);

    // Function to handle adding new package input fields
    const addNewPackage = () => {
        setPackages([
            ...packages,
            { packageCount: '', packageCount_unit: 'Carton', length: '', breadth: '', height: '', dimensionUnit: 'cm', grossWeight: '', gross_wt_units: 'KG' },
        ]);
    };

    const handlePackageChange = (index, field, value) => {
        const updatedPackages = [...packages];
        updatedPackages[index][field] = value;
        setPackages(updatedPackages);
    };


    const handleDivClick = () => {
        document.getElementById('fileInput').click();
    };
    var Disabledfordestination = false
    var Disabledfordestinationimport = false
    if (containerInfo.movementType == 'export') {
        Disabledfordestination = true
    }
    else if (containerInfo.movementType == 'import') {
        Disabledfordestinationimport = true
    }
    else {
        Disabledfordestination = false
    }
    console.log(rfqForm, '1019')
    return (
        <div className="container-fluid2" style={{ backgroundColor: 'white' }}>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={() => { setShow(false); window.location.href = "/indentsummary"; }}
            >
            </SweetAlert>
            <div className="row">
                <h5 class="col-sm-12 mb-20p mt-30p">
                    <i className='icofont icofont-home'></i><span> / {rfq_id ? `Edit Indent` : "Create Indent"}</span>
                </h5>
                <div className={"row col-xl-12 col-lg-12"}>
                    <>
                        <form className="aircargo-form" encType="multipart/form-data" onSubmit={onClickcreaterfq}>
                            <div className="card">
                                <div className="card-body pt-15px">
                                    {formshow == 'road' ? (
                                        <>

                                            <div className="row col-xl-12">

                                                <div className="col-xl-3 mt-20p">
                                                    <label className="col-xl-12 col-lg-12">RFQ Type<span className='err-txt'>*</span></label>
                                                    <Select
                                                        value={typeofselection.filter(e => e.value == rfqForm.auction_type)}
                                                        closeMenuOnSelect={true}
                                                        onChange={(selectedOption) => handleSelectChange("auction_type", selectedOption)}
                                                        options={typeofselection}
                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                minHeight: '45px', // Add your desired min-height here
                                                            }),
                                                        }}
                                                        required
                                                    />

                                                </div>
                                                <>
                                                    <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Source<span className='err-txt'>*</span></label>
                                                        <Select
                                                            value={source_list.filter(e => e.value == rfqForm.source)}
                                                            closeMenuOnSelect={true}
                                                            onChange={(selectedOption) => handleSelectChangeforsource("source", selectedOption)}
                                                            options={source_list}
                                                            styles={{
                                                                control: (provided, state) => ({
                                                                    ...provided,
                                                                    minHeight: '45px', // Add your desired min-height here
                                                                }),
                                                            }}
                                                            required
                                                        />
                                                    </div>
                                                    {rfqForm.source == 'others' ?
                                                        <div className="col-xl-3 mt-20p">
                                                            <label className="col-xl-12 col-lg-12">Enter Source</label>
                                                            <input
                                                                type="text"
                                                                name="selected_source_type"
                                                                value={rfqForm.selected_source_type}
                                                                className="form-control"
                                                                style={{ height: '45px' }}
                                                                // onChange={handleInputChange}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    handleInputChange(e);  // Call your change handler if valid

                                                                }}

                                                            />
                                                            {/* <input
                                                                type="text"
                                                                name="selected_source_type"
                                                                value={rfqForm.selected_source_type}
                                                                className="form-control"
                                                                // onChange={handleInputChange}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    // Only allow whole numbers (0-9)
                                                                    if (/^\d*$/.test(value)) {
                                                                        handleInputChange(e);  // Call your change handler if valid
                                                                    }
                                                                }}

                                                            /> */}
                                                        </div>
                                                        : ''}
                                                    <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Distance(km)</label>
                                                        <input
                                                            type="text"
                                                            name="total_km"
                                                            value={rfqForm.total_km}
                                                            className="form-control"
                                                            style={{ height: '45px' }}
                                                            autoComplete="off"
                                                            // onChange={handleInputChange}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                // Only allow whole numbers (0-9)
                                                                if (/^\d*$/.test(value)) {
                                                                    handleInputChange(e);  // Call your change handler if valid
                                                                }
                                                            }}

                                                        />
                                                    </div>

                                                    {/* <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Material</label>
                                                        <Select
                                                            value={material_list.filter(e => e.value == rfqForm.material)}
                                                            closeMenuOnSelect={true}
                                                            onChange={(selectedOption) => handleSelectChange("material", selectedOption)}
                                                            options={material_list}
                                                            styles={{
                                                                control: (provided, state) => ({
                                                                    ...provided,
                                                                    minHeight: '45px', // Add your desired min-height here
                                                                }),
                                                            }}
                                                            required
                                                        />
                                                    </div> */}

                                                    {/* <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Material Type</label>
                                                        <Select
                                                            value={material_list.filter(e => e.value == rfqForm.material_type)}
                                                            closeMenuOnSelect={true}
                                                            onChange={(selectedOption) => handleSelectChange("material_type", selectedOption)}
                                                            options={material_list}
                                                            styles={{
                                                                control: (provided, state) => ({
                                                                    ...provided,
                                                                    minHeight: '45px', // Add your desired min-height here
                                                                }),
                                                            }}
                                                            required
                                                        />
                                                    </div> */}
                                                    {/* {rfqForm.material=='others'? <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Enter Material Type<span className='err-txt'>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="selected_material_type"
                                                            value={rfqForm.selected_material_type}
                                                            className="form-control"
                                                            // onChange={handleInputChange}
                                                            onChange={(e) => {
                                                                const value = e.target.value;

                                                                // Only allow whole numbers (0-9)
                                                                if (/^\d*$/.test(value)) {
                                                                    handleInputChange(e);  // Call your change handler if valid
                                                                }
                                                            }}
                                                            required
                                                        />
                                                    </div>:''} */}

                                                    {/* <div className='col-xl-3 mt-20p'>
                                                        <label className="col-xl-12 col-lg-12">Material Dimension</label>

                                                        <div style={{ display: 'flex', gap: '5px' }}>
                                                            <input
                                                                type="number"
                                                                name="material_length"
                                                            value={rfqForm.material_length}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                if (/^\d*$/.test(value)) {
                                                                    handleInputChange(e);  
                                                                }
                                                            }}
                                                                placeholder="L"
                                                                style={{
                                                                    width: '85px',
                                                                    height: '40px',
                                                                    textAlign: 'center',
                                                                    border: '1px solid lightgray',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                            <input
                                                                type="number"
                                                                placeholder="B"
                                                                name="material_breath"
                                                            value={rfqForm.material_breath}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                if (/^\d*$/.test(value)) {
                                                                    handleInputChange(e);  
                                                                }
                                                            }}
                                                                style={{
                                                                    width: '85px',
                                                                    height: '40px',
                                                                    textAlign: 'center',
                                                                    border: '1px solid lightgray',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                            <input
                                                                type="number"
                                                                placeholder="H"
                                                                name="material_height"
                                                                value={rfqForm.material_height}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*$/.test(value)) {
                                                                        handleInputChange(e);  
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: '85px',
                                                                    height: '40px',
                                                                    textAlign: 'center',
                                                                    border: '1px solid lightgray',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                            <select
                                                                 value={rfqForm.material_units}
                                                                 name="material_units"
                                                                 onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*$/.test(value)) {
                                                                        handleInputChange(e);  
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: '95px',
                                                                    height: '40px',
                                                                    border: '1px solid lightgray',
                                                                    borderRadius: '4px',
                                                                }}
                                                            >
                                                                <option value="cm">cm</option>
                                                                <option value="mm">mm</option>
                                                                <option value="in">In</option>
                                                            </select>
                                                        </div>

                                                    </div> */}
                                                    <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Units<span className='err-txt'>*</span></label>
                                                        <Select
                                                            value={unitsOptions1.filter(e => e.value == rfqForm.units)}
                                                            closeMenuOnSelect={true}
                                                            onChange={(selectedOption) => handleSelectChange("units", selectedOption)}
                                                            options={unitsOptions1}
                                                            styles={{
                                                                control: (provided, state) => ({
                                                                    ...provided,
                                                                    minHeight: '45px', // Add your desired min-height here
                                                                }),
                                                            }}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Truck Type</label>
                                                        <Select
                                                            value={truck_list.filter(e => e.value === rfqForm.type_of_truck)}
                                                            closeMenuOnSelect={true}
                                                            onChange={selectedOption => handleSelectChange("type_of_truck", selectedOption)}

                                                            options={truck_list}
                                                            styles={{
                                                                control: (provided, state) => ({
                                                                    ...provided,
                                                                    minHeight: '45px', // Add your desired min-height here
                                                                }),
                                                            }}
                                                        />

                                                    </div>
                                                    {rfqForm.type_of_truck == 'others' ?
                                                        <div className="col-xl-3 mt-20p">
                                                            <label className="col-xl-12 col-lg-12">Enter Truck Type</label>
                                                            <input
                                                                type="text"
                                                                name="selected_tryck_type"
                                                                value={rfqForm.selected_tryck_type}
                                                                className="form-control"
                                                                // onChange={handleInputChange}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    handleInputChange(e);
                                                                }}

                                                            />
                                                        </div>
                                                        : ''}


                                                    {/* <div className='col-xl-3 mt-20p'>
                                                        <label className="col-xl-12 col-lg-12">Truck Dimension</label>
                                                        <div style={{ display: 'flex', gap: '5px' }}>
                                                            <input
                                                                type="number"
                                                                placeholder="L"
                                                                name="truck_length"
                                                                value={rfqForm.truck_length}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*$/.test(value)) {
                                                                        handleInputChange(e);  
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: '85px',
                                                                    height: '40px',
                                                                    textAlign: 'center',
                                                                    border: '1px solid lightgray',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                            <input
                                                                type="number"
                                                                placeholder="B"
                                                                name="truck_breath"
                                                                value={rfqForm.truck_breath}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*$/.test(value)) {
                                                                        handleInputChange(e);  
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: '85px',
                                                                    height: '40px',
                                                                    textAlign: 'center',
                                                                    border: '1px solid lightgray',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                            <input
                                                                type="number"
                                                                placeholder="H"
                                                                name="truck_height"
                                                                value={rfqForm.truck_height}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*$/.test(value)) {
                                                                        handleInputChange(e);  
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: '85px',
                                                                    height: '40px',
                                                                    textAlign: 'center',
                                                                    border: '1px solid lightgray',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                            <select
                                                                 value={rfqForm.truck_units}
                                                                 name="truck_units"
                                                                 onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*$/.test(value)) {
                                                                        handleInputChange(e);  
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: '95px',
                                                                    height: '40px',
                                                                    border: '1px solid lightgray',
                                                                    borderRadius: '4px',
                                                                }}
                                                            >
                                                                <option value="cm">cm</option>
                                                                <option value="mm">mm</option>
                                                                <option value="in">In</option>
                                                            </select>
                                                        </div>

                                                    </div> */}
                                                    {/* <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Quantity<span className='err-txt'>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="quantity"
                                                            value={rfqForm.quantity}
                                                            className="form-control"
                                                            style={{

                                                                height: '42px'
                                                            }}
                                                            // onChange={handleInputChange}
                                                            onChange={(e) => {
                                                                const value = e.target.value;

                                                                // Only allow whole numbers (0-9)
                                                                if (/^\d*$/.test(value)) {
                                                                    handleInputChange(e);  // Call your change handler if valid
                                                                }
                                                            }}
                                                            required
                                                        />
                                                    </div> */}
                                                    <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Base Price</label>
                                                        <input
                                                            type="text"  // Keep it as text
                                                            name="base_price"
                                                            value={rfqForm.base_price}
                                                            className="form-control"
                                                            style={{

                                                                height: '42px'
                                                            }}
                                                            autoComplete="off"
                                                            onChange={(e) => {
                                                                const value = e.target.value;

                                                                // Only allow whole numbers (0-9)
                                                                if (/^\d*$/.test(value)) {
                                                                    handleInputChange(e);  // Call your change handler if valid
                                                                }
                                                            }}

                                                        />
                                                    </div>

                                                    <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Bid Start Date<span className='err-txt'>*</span></label>
                                                        <Datetime
                                                            disableCloseOnClickOutside={false}
                                                            closeOnSelect={true}
                                                            value={rfqForm.bid_start_date}
                                                            isValidDate={current => {
                                                                return current.isSameOrAfter(moment(new Date()), 'day');
                                                            }}
                                                            inputProps={{
                                                                style: {
                                                                    height: '42px',
                                                                    backgroundColor: '#f7f7f7', // Light gray background
                                                                    // color: '#b0b0b0',           // Gray text color
                                                                    cursor: 'not-allowed'       // Show disabled cursor
                                                                },
                                                                disabled: true // Disable input to make it non-editable
                                                            }}
                                                            timeFormat="HH:mm:ss"
                                                            dateFormat="YYYY-MM-DD"
                                                            onChange={(date) => handlerStartDateTime("bid_start_date", date)}
                                                            required
                                                        />

                                                    </div>
                                                    <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Bid Validity<span className='err-txt'>*</span></label>
                                                        <Select
                                                            value={bidvalidityoptions.filter(e => e.value == rfqForm.quotation_validity_hours)}
                                                            closeMenuOnSelect={true}
                                                            onChange={selectedOption => handleSelectChange("quotation_validity_hours", selectedOption)}
                                                            options={bidvalidityoptions}
                                                            styles={{
                                                                control: (provided, state) => ({
                                                                    ...provided,
                                                                    minHeight: '45px', // Add your desired min-height here
                                                                }),
                                                            }}
                                                            required
                                                        />

                                                    </div>
                                                    <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Pickup Date<span className='err-txt'>*</span></label>
                                                        <Datetime
                                                            // className='setdate_style'
                                                            disableCloseOnClickOutside={false}
                                                            closeOnSelect={true}
                                                            isValidDate={current => {
                                                                return current.isSameOrAfter(moment(new Date()), 'day');
                                                            }}
                                                            inputProps={{
                                                                style: {
                                                                    height: '42px'
                                                                }
                                                            }}
                                                            value={rfqForm.pickup_date}
                                                            timeFormat="HH:mm:ss"
                                                            dateFormat="YYYY-MM-DD"
                                                            onChange={(date) => handlerPickupDateTime("pickup_date", date)}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Vendor/Group(s)<span className='err-txt'>*</span></label>
                                                        <Select
                                                            value={vendorslist}
                                                            closeMenuOnSelect={true}
                                                            isMulti={true}
                                                            onChange={onSelectItems}
                                                            options={vendorOptions}
                                                            styles={{
                                                                control: (provided, state) => ({
                                                                    ...provided,
                                                                    minHeight: '45px', // Add your desired min-height here
                                                                }),
                                                            }}
                                                            required
                                                        />

                                                    </div>
                                                    <div className="col-xl-3 mt-20p">
                                                        <label className="col-xl-12 col-lg-12">Payment Terms</label>
                                                        <input
                                                            type="text"  // Keep it as text
                                                            name="payment_terms"
                                                            value={rfqForm.payment_terms}
                                                            className="form-control"
                                                            style={{
                                                                backgroundColor: '#f7f7f7',

                                                                height: '42px'
                                                            }}


                                                        />
                                                        {/* <Select
                                                            value={payment_terms_list.filter(e => e.value == rfqForm.payment_terms)}
                                                            closeMenuOnSelect={true}
                                                            onChange={selectedOption => handleSelectChange("payment_terms", selectedOption)}
                                                            // options={payment_terms_list}
                                                            styles={{
                                                                control: (provided, state) => ({
                                                                    ...provided,
                                                                    backgroundColor: '#f7f7f7',
                                                                    minHeight: '45px', // Add your desired min-height here
                                                                }),
                                                            }}
                                                            

                                                        /> */}
                                                    </div>
                                                    <div >
                                                    </div>
                                                    <>
                                                        {dealers.map((dealer, index) => (
                                                            <div className="col-xl-12 dealer-section" key={index}>
                                                                <div className="col-xl-2 mt-20p">
                                                                    <label className="col-xl-12 col-lg-12">Dealer Code<span className='err-txt'>*</span></label>
                                                                    <Select
                                                                        value={citiesoption.find(e => e.value === dealer.dealer_code)}
                                                                        closeMenuOnSelect={true}
                                                                        onChange={selectedOption => select_dealer_code(index, selectedOption)}
                                                                        options={citiesoption}
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                minHeight: '45px',
                                                                            }),
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className="col-xl-2 mt-20p">
                                                                    <label className="col-xl-12 col-lg-12">Dealer City<span className='err-txt'>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="dealer_city"
                                                                        value={dealer.dealer_city}
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>

                                                                <div className="col-xl-2 mt-20p">
                                                                    <label className="col-xl-12 col-lg-12">Pincode<span className='err-txt'>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="dealer_pincode"
                                                                        value={dealer.dealer_pincode}
                                                                        autoComplete="off"
                                                                        // onChange={(e) => handleInputChange(index, "dealer_pincode", e.target.value)}
                                                                        className="form-control"
                                                                    />
                                                                </div>

                                                                {/* Material and Dimension Fields */}
                                                                <div className="col-xl-2 mt-20p">
                                                                    <label className="col-xl-12 col-lg-12">Material<span className='err-txt'>*</span></label>
                                                                    <Select
                                                                        value={material_list.find(e => e.value === dealer.material)}
                                                                        closeMenuOnSelect={true}
                                                                        onChange={(selectedOption) => handleInputChangefordealer(index, "material", selectedOption.value)}
                                                                        options={material_list}
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                minHeight: '45px',
                                                                            }),
                                                                        }}
                                                                    />

                                                                </div>


                                                                <div className="col-xl-2 mt-20p">
                                                                    <label className="col-xl-12 col-lg-12">Material Dimensions<span className='err-txt'>*</span></label>
                                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                                        <input
                                                                            type="number"
                                                                            name="material_length"
                                                                            value={dealer.material_length}
                                                                            onChange={(e) => handleInputChangefordealer(index, "material_length", e.target.value)}
                                                                            placeholder="L"
                                                                            style={{
                                                                                width: '53px',
                                                                                height: '40px',
                                                                                textAlign: 'center',
                                                                                border: '1px solid lightgray',
                                                                                borderRadius: '4px',
                                                                            }}
                                                                        />
                                                                        <input
                                                                            type="number"
                                                                            name="material_breath"
                                                                            value={dealer.material_breath}
                                                                            onChange={(e) => handleInputChangefordealer(index, "material_breath", e.target.value)}
                                                                            placeholder="B"
                                                                            style={{
                                                                                width: '53px',
                                                                                height: '40px',
                                                                                textAlign: 'center',
                                                                                border: '1px solid lightgray',
                                                                                borderRadius: '4px',
                                                                            }}
                                                                        />
                                                                        <input
                                                                            type="number"
                                                                            name="material_height"
                                                                            value={dealer.material_height}
                                                                            onChange={(e) => handleInputChangefordealer(index, "material_height", e.target.value)}
                                                                            placeholder="H"
                                                                            style={{
                                                                                width: '53px',
                                                                                height: '40px',
                                                                                textAlign: 'center',
                                                                                border: '1px solid lightgray',
                                                                                borderRadius: '4px',
                                                                            }}
                                                                        />
                                                                        <select
                                                                            value={dealer.material_units}
                                                                            name="material_units"
                                                                            onChange={(e) => handleInputChangefordealer(index, "material_units", e.target.value)}
                                                                            style={{
                                                                                width: '70px',
                                                                                height: '40px',
                                                                                border: '1px solid lightgray',
                                                                                borderRadius: '4px',
                                                                            }}
                                                                        >
                                                                            <option value="cm">cm</option>
                                                                            <option value="mm">mm</option>
                                                                            <option value="in">in</option>
                                                                            <option value="ft">ft</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-2 mt-20p">
                                                                    <label className="col-xl-12 col-lg-12">Quantity<span className='err-txt'>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="quantity"
                                                                        value={dealer.quantity}
                                                                        autoComplete="off"
                                                                        onChange={(e) => handleInputChangefordealer(index, "quantity", e.target.value)}
                                                                        className="form-control"
                                                                        style={{ height: '42px' }}
                                                                    />
                                                                </div>

                                                                {dealers.length === 1 || index === dealers.length - 1 ? (
                                                                    <>
                                                                        {dealers.length < 10 && (
                                                                            <button
                                                                                type="button"
                                                                                className="cust-delete-attach"
                                                                                title="Add"
                                                                                style={{ cursor: 'pointer', marginRight: '-16px', marginTop: '-23px' }}
                                                                                onClick={addDealerForm}
                                                                            >
                                                                                +
                                                                            </button>
                                                                        )}

                                                                        {/* Show the - button only if there are multiple dealers */}
                                                                        {dealers.length > 1 && (
                                                                            <button
                                                                                type="button"
                                                                                className="cust-delete-attach"
                                                                                title="Delete"
                                                                                style={{ cursor: 'pointer', marginTop: '70px', marginRight: '-16px' }}
                                                                                onClick={() => removeDealerForm(index)}
                                                                            >
                                                                                -
                                                                            </button>
                                                                        )}
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        ))}
                                                    </>
                                                    <>
                                                        <div className="col-xl-3 mt-20p">
                                                            <label className="col-xl-12 col-lg-12" htmlFor="additional_comments">Additional Comments</label>
                                                            <input
                                                                type="textarea"
                                                                name="additional_comments"
                                                                value={rfqForm.additional_comments}
                                                                autoComplete="off"
                                                                className="form-control"
                                                                style={{

                                                                    height: '42px'
                                                                }}
                                                                onChange={handleInputChange}


                                                            />

                                                        </div>
                                                        <div className="col-xl-2 mt-20p">
                                                            <label className="col-xl-12 col-lg-12">Upload File</label>
                                                            {/* <input
                                                                type="file"
                                                                name="payment_terms"
                                                                id="payment_terms"
                                                                style={{

                                                                    height: '42px'
                                                                }}
                                                                // value={rfqForm.payment_terms}
                                                                className="form-control"
                                                                onChange={onchangeFileHandler}

                                                            /> */}

                                                            <input
                                                                type="file"
                                                                id="uploadFile"
                                                                name="uploadFile"
                                                                onChange={onchangeFileHandler}
                                                                className="form-control"

                                                            />

                                                        </div>
                                                        <div className="col-xl-3 material-section">
                                                            <label>Material</label>
                                                            <div className="checkbox-group">
                                                                <div>
                                                                    <input type="checkbox"
                                                                        name="over_height"
                                                                        id="over_height"
                                                                        checked={rfqForm.over_height}
                                                                        onChange={e => handleCheckboxChange("over_height", e.target.checked)}
                                                                        style={{
                                                                            width: '30px',
                                                                            height: '16px'
                                                                        }} />
                                                                    <label htmlFor="over_height">Over Breath</label>
                                                                </div>
                                                                <div>
                                                                    <input type="checkbox"
                                                                        name="over_length"
                                                                        onChange={e => handleCheckboxChange("over_length", e.target.checked)}
                                                                        checked={rfqForm.over_length}
                                                                        id="overLength" style={{
                                                                            width: '30px',
                                                                            height: '16px'
                                                                        }} />

                                                                    <label htmlFor="over_length">Over Length</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="additional-checkboxes">
                                                            <div>
                                                                <input type="checkbox" name="insurance"
                                                                    id="insurance"
                                                                    checked={rfqForm.without_insurance}
                                                                    onChange={e => handleCheckboxChange("without_insurance", e.target.checked)}

                                                                    style={{
                                                                        width: '30px',
                                                                        height: '16px'
                                                                    }} />
                                                                <label htmlFor="insurance">Transit Insurance</label>
                                                            </div>
                                                            <div>
                                                                <input
                                                                    type="checkbox"
                                                                    id="bidVisibility"
                                                                    style={{
                                                                        width: '30px',
                                                                        height: '16px',
                                                                        opacity: 0.5, // Makes it look disabled
                                                                        cursor: 'not-allowed' // Indicates it's non-interactive
                                                                    }}
                                                                    checked={rfqForm.bidVisibility}
                                                                    disabled // Disables the checkbox
                                                                    onChange={e => handleCheckboxChange("bidVisibility", e.target.checked)}
                                                                />
                                                                <label
                                                                    htmlFor="bidVisibility"
                                                                    style={{
                                                                        color: 'gray', // Disabled label color
                                                                        cursor: 'not-allowed' // Matches the checkbox
                                                                    }}
                                                                >
                                                                    Bid Visibility
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </>


                                                </>
                                            </div>
                                        </>) : ''}
                                    {formshow == 'sea' ? <> <div className="row col-xl-12">
                                        <>
                                            {containerInfo.cargoMode == 'fcl' ? <> <div className='row col-xl-12'>


                                                <div className="col-xl-12">
                                                    <label>Add Containers</label>
                                                    <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                                        <div>
                                                            <div className="row">
                                                                {containers.map((container, index) => (
                                                                    <div key={index} className="col-sm-3" style={{ marginBottom: '10px', }}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '800px' }}>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="0"
                                                                                value={container.weight}
                                                                                onChange={(e) => handleWeightChange(index, e.target.value)}
                                                                                style={{
                                                                                    // width: '100%',
                                                                                    height: '40px',
                                                                                    textAlign: 'left',
                                                                                    border: '1px solid lightgray',
                                                                                    borderRadius: '4px 0 0 4px',
                                                                                    borderRight: 'none',
                                                                                }}
                                                                            />
                                                                            <select
                                                                                value={container.unit}
                                                                                onChange={(e) => handleUnitChange(index, e.target.value)}
                                                                                style={{
                                                                                    // width: '50%',
                                                                                    height: '40px',
                                                                                    border: '1px solid lightgray',
                                                                                    borderRadius: '0 4px 4px 0',
                                                                                    backgroundColor: 'white',
                                                                                    paddingLeft: '10px',
                                                                                }}
                                                                            >
                                                                                <option value="20ft">20ft</option>
                                                                                <option value="40ft">40ft</option>
                                                                                <option value="40ft HC">40ft HC</option>
                                                                                <option value="45ft">45ft</option>
                                                                                <option value="ISO Tank">ISO Tank</option>
                                                                                <option value="Flexitank">Flexitank</option>
                                                                            </select>
                                                                            {/* {containers.length > 1 && (
                                <button
                                    onClick={() => removeContainer(index)}
                                    style={{
                                        // height: '40px',
                                        marginLeft: '5px',
                                        padding: '0 8px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    -
                                </button>
                            )} */}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div onClick={onClickmultipleinput}>
                                                                <label style={{ color: 'blue', cursor: 'pointer' }}>+ Add More Containers</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></> : ''}
                                            <div className='row col-xl-12'>
                                                <h5 class="col-sm-12 mb-20p mt-30p  f20" >
                                                    Weight & Volume
                                                </h5>
                                            </div>
                                            <div className="col-xl-12" style={{ marginLeft: '-16px' }}>
                                                <div className="col-xl-12 tabs">
                                                    <button
                                                        onClick={() => setActiveTab('totalShipment')}
                                                        className={activeTab === 'totalShipment' ? 'active' : ''}
                                                        style={{
                                                            padding: '10px 20px',
                                                            border: 'none',
                                                            background: 'none',
                                                            borderBottom: activeTab === 'totalShipment' ? '2px solid blue' : 'none',
                                                            color: activeTab === 'totalShipment' ? 'blue' : 'black',
                                                        }}
                                                    >
                                                        By Total Shipment
                                                    </button>
                                                    <button
                                                        onClick={() => setActiveTab('packaging')}
                                                        className={activeTab === 'packaging' ? 'active' : ''}
                                                        style={{
                                                            padding: '10px 20px',
                                                            border: 'none',
                                                            background: 'none',
                                                            borderBottom: activeTab === 'packaging' ? '2px solid blue' : 'none',
                                                            color: activeTab === 'packaging' ? 'blue' : 'black',
                                                        }}
                                                    >
                                                        By Packaging
                                                    </button>
                                                </div>
                                                <div className='col-xl-12'>
                                                    <div style={{ padding: '20px', border: '1px solid lightgray', marginTop: '10px' }}>
                                                        {activeTab === 'totalShipment' && (
                                                            <>
                                                                <div style={{ display: 'flex', gap: '130px', justifyContent: 'flex-start' }}>
                                                                    <div style={{ position: 'relative', width: '200px' }}>
                                                                        <label>Weight per 20ft</label>
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue="0"
                                                                                value={weight_20ft}
                                                                                onChange={(e) => setweight_20ft(e.target.value)}
                                                                                style={{
                                                                                    // width: '100%',
                                                                                    height: '40px',
                                                                                    textAlign: 'left',
                                                                                    border: '1px solid lightgray',
                                                                                    borderRadius: '4px 0 0 4px',
                                                                                    borderRight: 'none',
                                                                                }}
                                                                            />
                                                                            <select
                                                                                value={weight_20ft_Unit}
                                                                                onChange={(e) => setweight_20ft_Unit(e.target.value)}
                                                                                style={{
                                                                                    // width: '50%',
                                                                                    height: '40px',
                                                                                    border: '1px solid lightgray',
                                                                                    borderRadius: '0 4px 4px 0',
                                                                                    backgroundColor: 'white',
                                                                                    paddingLeft: '10px',
                                                                                }}
                                                                            >
                                                                                <option value="KG">KG</option>
                                                                                <option value="MT">MT</option>
                                                                                <option value="Ibs">Ibs</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ position: 'relative', width: '200px' }}>
                                                                        <label>Volume per 20ft</label>
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <input
                                                                                type="text"
                                                                                defaultValue="0"
                                                                                value={volume_20ft}
                                                                                onChange={(e) => setvolume_20ft(e.target.value)}
                                                                                style={{
                                                                                    // width: '100%',
                                                                                    height: '40px',
                                                                                    textAlign: 'left',
                                                                                    border: '1px solid lightgray',
                                                                                    borderRadius: '4px 0 0 4px',
                                                                                    borderRight: 'none',
                                                                                }}
                                                                            />
                                                                            <select
                                                                                value={volume_20ft_Unit}
                                                                                onChange={(e) => setvolume_20ft_Unit(e.target.value)}
                                                                                style={{
                                                                                    // width: '60%',
                                                                                    height: '40px',
                                                                                    border: '1px solid lightgray',
                                                                                    borderRadius: '0 4px 4px 0',
                                                                                    backgroundColor: 'white',
                                                                                    paddingLeft: '10px',
                                                                                }}
                                                                            >
                                                                                <option value="CBM">CBM</option>

                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ position: 'relative', width: '240px' }}>
                                                                        <label>Base Price<span className='err-txt'>*</span></label>
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <input
                                                                                type="text"
                                                                                name="origin_port"
                                                                                value={base_price_sea}
                                                                                className="form-control"
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    if (/^\d*$/.test(value)) {
                                                                                        set_base_price_sea(e.target.value)
                                                                                    }
                                                                                }}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ position: 'relative', width: '280px', marginLeft: '-72px' }}>
                                                                        <label>Vendor/Group<span className='err-txt'>*</span></label>
                                                                        <Select
                                                                            value={rfqFormforsea.vendors}
                                                                            closeMenuOnSelect={true}
                                                                            isMulti={true}
                                                                            onChange={onSelectItems1}
                                                                            options={vendorOptions}
                                                                            styles={{
                                                                                control: (provided, state) => ({
                                                                                    ...provided,
                                                                                    minHeight: '45px', // Add your desired min-height here
                                                                                }),
                                                                            }}
                                                                            required
                                                                        />

                                                                    </div>


                                                                </div>
                                                                <div style={{ display: 'flex', gap: '130px', justifyContent: 'flex-start' }}>
                                                                    <div style={{ position: 'relative', width: '280px' }}>
                                                                        <label>Bid Start Date<span className='err-txt'>*</span></label>
                                                                        <Datetime
                                                                            // className='setdate_style'
                                                                            disableCloseOnClickOutside={false}
                                                                            closeOnSelect={true}
                                                                            value={rfqFormforsea.bid_start_date}
                                                                            isValidDate={current => {
                                                                                return current.isSameOrAfter(moment(new Date()), 'day');
                                                                            }}
                                                                            inputProps={{
                                                                                style: {
                                                                                    height: '42px'
                                                                                }
                                                                            }}
                                                                            timeFormat="HH:mm:ss"
                                                                            dateFormat="YYYY-MM-DD"
                                                                            onChange={(date) => handlerStartDateTime1("bid_start_date", date)}
                                                                            required
                                                                        />

                                                                    </div>
                                                                    <div style={{ position: 'relative', width: '280px', marginLeft: '-73px' }}>
                                                                        <label>Bid Validity<span className='err-txt'>*</span></label>
                                                                        <Select
                                                                            value={bidvalidityoptions.filter(e => e.value == rfqFormforsea.quotation_validity_hours)}
                                                                            closeMenuOnSelect={true}
                                                                            onChange={selectedOption => handleSelectChangeforsea("quotation_validity_hours", selectedOption)}
                                                                            options={bidvalidityoptions}
                                                                            styles={{
                                                                                control: (provided, state) => ({
                                                                                    ...provided,
                                                                                    minHeight: '45px', // Add your desired min-height here
                                                                                }),
                                                                            }}
                                                                            required
                                                                        />

                                                                    </div>
                                                                    <div style={{ position: 'relative', width: '280px', marginLeft: '-73px' }}>
                                                                        <label>Bid Pickup Date<span className='err-txt'>*</span></label>
                                                                        <Datetime
                                                                            // className='setdate_style'
                                                                            disableCloseOnClickOutside={false}
                                                                            closeOnSelect={true}
                                                                            isValidDate={current => {
                                                                                return current.isSameOrAfter(moment(new Date()), 'day');
                                                                            }}
                                                                            value={rfqFormforsea.pickup_date}
                                                                            timeFormat="HH:mm:ss"
                                                                            dateFormat="YYYY-MM-DD"
                                                                            onChange={(date) => handlerPickupDateTime1("pickup_date", date)}
                                                                            required
                                                                        />

                                                                    </div>
                                                                </div>

                                                            </>
                                                        )}

                                                        {activeTab === 'packaging' && (
                                                            <div className="col-xl-12">
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-lg-9">
                                                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 0.5fr', paddingBottom: '10px' }}>
                                                                                <span>Number of Packages</span>
                                                                                <span>Dimensions</span>
                                                                                <span>Total G.Wt.</span>
                                                                                <span>Total Units</span>
                                                                            </div>
                                                                            {packages.map((pkg, index) => (
                                                                                <div key={index} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 0.5fr', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                                                                                    <div>
                                                                                        <input
                                                                                            type="number"
                                                                                            value={pkg.packageCount}
                                                                                            onChange={(e) => handlePackageChange(index, 'packageCount', e.target.value)}
                                                                                            style={{
                                                                                                width: '100px',
                                                                                                height: '40px',
                                                                                                textAlign: 'center',
                                                                                                border: '1px solid lightgray',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        />
                                                                                        <select
                                                                                            value={pkg.packageCount_unit}
                                                                                            onChange={(e) => handlePackageChange(index, 'packageCount_unit', e.target.value)}
                                                                                            style={{
                                                                                                width: '100px',
                                                                                                height: '40px',
                                                                                                border: '1px solid lightgray',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        >
                                                                                            <option value="Carton">Carton</option>
                                                                                            <option value="Box">Box</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                                                        <input
                                                                                            type="number"
                                                                                            placeholder="L"
                                                                                            value={pkg.length}
                                                                                            onChange={(e) => handlePackageChange(index, 'length', e.target.value)}
                                                                                            style={{
                                                                                                width: '60px',
                                                                                                height: '40px',
                                                                                                textAlign: 'center',
                                                                                                border: '1px solid lightgray',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        />
                                                                                        <input
                                                                                            type="number"
                                                                                            placeholder="B"
                                                                                            value={pkg.breadth}
                                                                                            onChange={(e) => handlePackageChange(index, 'breadth', e.target.value)}
                                                                                            style={{
                                                                                                width: '60px',
                                                                                                height: '40px',
                                                                                                textAlign: 'center',
                                                                                                border: '1px solid lightgray',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        />
                                                                                        <input
                                                                                            type="number"
                                                                                            placeholder="H"
                                                                                            value={pkg.height}
                                                                                            onChange={(e) => handlePackageChange(index, 'height', e.target.value)}
                                                                                            style={{
                                                                                                width: '60px',
                                                                                                height: '40px',
                                                                                                textAlign: 'center',
                                                                                                border: '1px solid lightgray',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        />
                                                                                        <select
                                                                                            value={pkg.dimensionUnit}
                                                                                            onChange={(e) => handlePackageChange(index, 'dimensionUnit', e.target.value)}
                                                                                            style={{
                                                                                                width: '80px',
                                                                                                height: '40px',
                                                                                                border: '1px solid lightgray',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        >
                                                                                            <option value="cm">cm</option>
                                                                                            <option value="mm">mm</option>
                                                                                            <option value="in">In</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                                                        <input
                                                                                            type="number"
                                                                                            placeholder="0.0"
                                                                                            value={pkg.grossWeight}
                                                                                            onChange={(e) => handlePackageChange(index, 'grossWeight', e.target.value)}
                                                                                            style={{
                                                                                                width: '80px',
                                                                                                height: '40px',
                                                                                                textAlign: 'center',
                                                                                                border: '1px solid lightgray',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        />
                                                                                        <select
                                                                                            value={pkg.gross_wt_units}
                                                                                            onChange={(e) => handlePackageChange(index, 'gross_wt_units', e.target.value)}
                                                                                            style={{
                                                                                                width: '80px',
                                                                                                height: '40px',
                                                                                                border: '1px solid lightgray',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        >
                                                                                            <option value="KG">KG</option>
                                                                                            <option value="MT">MT</option>
                                                                                            <option value="Ibs">Ibs</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                                                        <span>G.Wt: {grossWeight || 0} KG</span>
                                                                                        <span>Vol: {volume} CBM</span>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>

                                                                        {/* Upload Packing List Section */}
                                                                        <div className="col-lg-3" >
                                                                            <div>
                                                                                <span>Upload Packing List</span>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    // width: '150px',
                                                                                    height: '100px',
                                                                                    border: '1px dashed lightgray',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    textAlign: 'center',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={handleDivClick}
                                                                                onDrop={(e) => {
                                                                                    e.preventDefault();
                                                                                    setDragFile(e.dataTransfer.files[0]);
                                                                                }}
                                                                                onDragOver={(e) => e.preventDefault()}
                                                                            >
                                                                                <span>{dragFile ? dragFile.name : 'Drag files here to upload'}</span>
                                                                            </div>

                                                                            {/* Hidden File Input */}
                                                                            <input
                                                                                type="file"
                                                                                id="fileInput"
                                                                                style={{ display: 'none' }}
                                                                                onChange={handleFileChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div onClick={addNewPackage}>
                                                                        <label style={{ color: 'blue', cursor: 'pointer' }}>+ Add More Packages</label>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        )}
                                                    </div>

                                                </div>
                                            </div>



                                            <div className='row col-xl-12'>
                                                <h5 class="col-sm-12 mb-20p mt-30p  f20">
                                                    Address & Services
                                                </h5>
                                            </div>
                                            <div className='col-xl-12'>

                                                <div style={{ padding: '6px 10px 16px', border: '1px solid lightgray' }}>
                                                    <div className='row col-xl-12'>

                                                        <div className="col-xl-3 mt-20p">
                                                            <label className="col-xl-12 col-lg-12">Incoterm<span className='err-txt'>*</span></label>
                                                            <Select
                                                                value={incotermsoption.filter(e => e.value == rfqFormforsea.incoterm)}
                                                                closeMenuOnSelect={true}
                                                                onChange={(selectedOption) => handleSelectChangeforsea("incoterm", selectedOption)}
                                                                options={incotermsoption}
                                                                styles={{
                                                                    control: (provided, state) => ({
                                                                        ...provided,
                                                                        minHeight: '45px', // Add your desired min-height here
                                                                    }),
                                                                }}
                                                            />

                                                        </div>
                                                        <div className="col-xl-3 mt-20p">
                                                            <label className="col-xl-12 col-lg-12">Pick&Drop<span className='err-txt'>*</span></label>
                                                            <Select
                                                                value={pick_drop_option.filter(e => e.value == rfqFormforsea.pick_and_drop)}
                                                                closeMenuOnSelect={true}
                                                                onChange={(selectedOption) => handleSelectChangeforsea("pick_and_drop", selectedOption)}
                                                                options={pick_drop_option}
                                                                styles={{
                                                                    control: (provided, state) => ({
                                                                        ...provided,
                                                                        minHeight: '45px', // Add your desired min-height here
                                                                    }),
                                                                }}
                                                                required
                                                            />
                                                            {rfqFormforsea.pick_and_drop === 'door_to_door' && (
                                                                <span style={{ color: 'red', marginTop: '5px', display: 'block' }}>
                                                                    You have selected: DOOR(ORIGIN) TO DOOR(DESTINATION) Service
                                                                </span>
                                                            )}

                                                        </div>
                                                        <div className="col-xl-3 mt-20p">
                                                            <label className="col-xl-12 col-lg-12">Origin Port<span className='err-txt'>*</span></label>
                                                            <Select
                                                                value={origin_port_list.filter(e => e.value == rfqFormforsea.origin_port)}
                                                                closeMenuOnSelect={true}
                                                                onChange={(selectedOption) => handleSelectChangeforsea("origin_port", selectedOption)}
                                                                options={origin_port_list}
                                                                styles={{
                                                                    control: (provided, state) => ({
                                                                        ...provided,
                                                                        minHeight: '45px', // Add your desired min-height here
                                                                    }),
                                                                }}
                                                                required
                                                            />
                                                            {/* <input
                                                                type="text"
                                                                name="origin_port"
                                                                value={rfqFormforsea.origin_port}
                                                                className="form-control"
                                                                onChange={(e) => handleInputChange1(e)}

                                                                required
                                                            /> */}


                                                        </div>
                                                        <div className="col-xl-3 mt-20p">
                                                            <label className="col-xl-12 col-lg-12">Origin Address<span className='err-txt'>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="origin_address"
                                                                value={rfqFormforsea.origin_address}
                                                                className="form-control"
                                                                onChange={(e) => handleInputChange1(e)}

                                                                required
                                                            />

                                                        </div>
                                                        <div className="col-xl-3 mt-20p">
                                                            <label className="col-xl-12 col-lg-12">Stuffing Location<span className='err-txt'>*</span></label>

                                                            <Select
                                                                value={stuffing_location_list.filter(e => e.value == rfqFormforsea.stuffing_location)}
                                                                closeMenuOnSelect={true}
                                                                onChange={(selectedOption) => handleSelectChangeforsea("stuffing_location", selectedOption)}
                                                                options={stuffing_location_list}
                                                                styles={{
                                                                    control: (provided, state) => ({
                                                                        ...provided,
                                                                        minHeight: '45px', // Add your desired min-height here
                                                                    }),
                                                                }}
                                                                required
                                                            />
                                                            {/* <input
                                                                type="text"
                                                                name="stuffing_location"
                                                                value={rfqFormforsea.stuffing_location}
                                                                className="form-control"
                                                                onChange={(e) => handleInputChange1(e)}

                                                                required
                                                            /> */}

                                                        </div>
                                                        <div className="col-xl-3 mt-20p">
                                                            <label className="col-xl-12 col-lg-12">Destination Port<span className='err-txt'>*</span></label>
                                                            <Select
                                                                value={destination_port_list.filter(e => e.value == rfqFormforsea.destination_port)}
                                                                closeMenuOnSelect={true}
                                                                onChange={(selectedOption) => handleSelectChangeforsea("destination_port", selectedOption)}
                                                                options={destination_port_list}
                                                                styles={{
                                                                    control: (provided, state) => ({
                                                                        ...provided,
                                                                        minHeight: '45px', // Add your desired min-height here
                                                                    }),
                                                                }}
                                                                required
                                                            />
                                                            {/* <input
                                                                type="text"
                                                                name="destination_port"
                                                                value={rfqFormforsea.destination_port}
                                                                className="form-control"
                                                                onChange={(e) => handleInputChange1(e)}

                                                                required
                                                            /> */}
                                                        </div>
                                                        <div className="col-xl-3 mt-20p">
                                                            <label className="col-xl-12 col-lg-12">Destination Address<span className='err-txt'>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="destination_address"
                                                                value={rfqFormforsea.destination_address}
                                                                className="form-control"
                                                                onChange={(e) => handleInputChange1(e)}

                                                                required
                                                            />

                                                        </div>
                                                        <div className="col-xl-3 mt-20p">
                                                            <label className="col-xl-12 col-lg-12">DeStuffing Location<span className='err-txt'>*</span></label>

                                                            <Select
                                                                value={destuffing_location_list.filter(e => e.value == rfqFormforsea.destuffing_location)}
                                                                closeMenuOnSelect={true}
                                                                onChange={(selectedOption) => handleSelectChangeforsea("destuffing_location", selectedOption)}
                                                                options={destuffing_location_list}
                                                                styles={{
                                                                    control: (provided, state) => ({
                                                                        ...provided,
                                                                        minHeight: '45px', // Add your desired min-height here
                                                                    }),
                                                                }}
                                                                required
                                                            />
                                                            {/* <input
                                                                type="text"
                                                                name="destuffing_location"
                                                                value={rfqFormforsea.destuffing_location}
                                                                className="form-control"
                                                                onChange={(e) => handleInputChange1(e)}

                                                                required
                                                            /> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*  */}
                                            <div className='row col-xl-12'>
                                                <h5 class="col-sm-12 mb-20p mt-30p  f20" >
                                                    Product Details
                                                </h5>
                                            </div>
                                            <div className='row col-xl-12'>
                                                <div className="col-xl-3">
                                                    <label className="col-xl-12 col-lg-12">HSN Code<span className='err-txt'>*</span></label>

                                                    <Select
                                                        value={hsn_code_list.filter(e => e.value == rfqFormforsea.hs_code)}
                                                        closeMenuOnSelect={true}
                                                        onChange={(selectedOption) => handleSelectChangeforsea("hs_code", selectedOption)}
                                                        options={hsn_code_list}
                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                minHeight: '45px', // Add your desired min-height here
                                                            }),
                                                        }}
                                                        required
                                                    />


                                                </div>
                                                <div className="col-xl-3">
                                                    <label className="col-xl-12 col-lg-12">Product<span className='err-txt'>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="product"
                                                        value={rfqFormforsea.product}
                                                        className="form-control"
                                                        onChange={(e) => handleInputChange1(e)}
                                                        required
                                                    />


                                                </div>
                                                <div className="col-xl-3">
                                                    <label className="col-xl-12 col-lg-12">Cargo Invoice Value</label>
                                                    <input
                                                        type="text"
                                                        name="cargo_invoice_value"
                                                        value={rfqFormforsea.cargo_invoice_value}
                                                        className="form-control"
                                                        onChange={(e) => handleInputChange1(e)}
                                                    />

                                                </div>
                                            </div>

                                            <div className='row col-xl-12'>
                                                <h5 class="col-sm-12 mt-30p  f20">
                                                    Other Details(Optional)
                                                </h5>
                                            </div>
                                            <div className='row col-xl-12'>
                                                <div className="col-xl-3">
                                                    <label className="col-xl-12 col-lg-12">Payment Terms</label>
                                                    <Select
                                                        value={payment_terms_list.filter(e => e.value == rfqFormforsea.payment_terms1)}
                                                        closeMenuOnSelect={true}
                                                        onChange={selectedOption => handleSelectChangeforsea("payment_terms1", selectedOption)}
                                                        options={payment_terms_list}
                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                minHeight: '45px', // Add your desired min-height here
                                                            }),
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-xl-3">
                                                    <label className="col-xl-12 col-lg-12">Additional Information/Comments</label>
                                                    <input
                                                        type="text"
                                                        name="additional_details"
                                                        value={rfqFormforsea.additional_details}
                                                        className="form-control"
                                                        onChange={(e) => handleInputChange1(e)}
                                                    />

                                                </div>
                                                <div className="col-xl-2">
                                                    <label className="col-xl-12 col-lg-12">Upload File</label>
                                                    <input
                                                        type="file"
                                                        name="upload_file_sea"
                                                        id="upload_file_sea"
                                                        className="form-control"
                                                        onChange={onchangeFileHandler1}
                                                    />

                                                </div>
                                            </div>
                                            <div className='mt-25p ml-10p'>
                                                <label style={{ fontWeight: 'bold', marginBottom: '10px', display: 'block' }}>Required Charges</label>

                                                <div style={{
                                                    marginBottom: '20px', opacity: Disabledfordestinationimport ? 0.5 : 1, // Reduce opacity to make it look disabled
                                                    pointerEvents: Disabledfordestinationimport ? 'none' : 'auto'
                                                }} >
                                                    <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Origin:</label>
                                                    <label style={{ marginRight: '20px' }}>
                                                        <input
                                                            type="checkbox"
                                                            name="cfsWarehouse"
                                                            checked={originCharges.cfsWarehouse}
                                                            onChange={handleOriginChange}
                                                            disabled={Disabledfordestinationimport}
                                                        />
                                                        <span style={{ marginLeft: '5px', color: Disabledfordestinationimport ? 'gray' : 'black' }}>CFS/Warehouse</span>
                                                    </label>
                                                    <label style={{ marginLeft: '15px', marginRight: '20px' }}>
                                                        <input
                                                            type="checkbox"
                                                            name="port"
                                                            checked={originCharges.port}
                                                            onChange={handleOriginChange}
                                                            disabled={Disabledfordestinationimport}
                                                        />
                                                        <span style={{ marginLeft: '5px', color: Disabledfordestinationimport ? 'gray' : 'black' }}>Port</span>
                                                    </label>
                                                    <label style={{ marginLeft: '15px' }}>
                                                        <input
                                                            type="checkbox"
                                                            name="customsClearance"
                                                            checked={originCharges.customsClearance}
                                                            onChange={handleOriginChange}
                                                            disabled={Disabledfordestinationimport}
                                                        />
                                                        <span style={{ marginLeft: '5px', color: Disabledfordestinationimport ? 'gray' : 'black' }}> Customs Clearance</span>
                                                    </label>
                                                </div>

                                                <div
                                                    style={{
                                                        opacity: Disabledfordestination ? 0.5 : 1, // Reduce opacity to make it look disabled
                                                        pointerEvents: Disabledfordestination ? 'none' : 'auto', // Disable interactions if disabled
                                                    }}
                                                >
                                                    <label style={{ fontWeight: 'bold', marginRight: '10px' }}>Destination:</label>

                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="cfsWarehouse1"
                                                            checked={destinationCharges.cfsWarehouse1}
                                                            onChange={handleDestinationChange}
                                                            disabled={Disabledfordestination} // Disable the checkbox if Disabledfordestination is true
                                                        />
                                                        <span style={{ marginLeft: '5px', color: Disabledfordestination ? 'gray' : 'black' }}>
                                                            CFS/Warehouse
                                                        </span>
                                                    </label>

                                                    <label style={{ marginLeft: '15px' }}>
                                                        <input
                                                            type="checkbox"
                                                            name="port1"
                                                            checked={destinationCharges.port1}
                                                            onChange={handleDestinationChange}
                                                            disabled={Disabledfordestination} // Disable the checkbox if Disabledfordestination is true
                                                        />
                                                        <span style={{ marginLeft: '5px', color: Disabledfordestination ? 'gray' : 'black' }}>
                                                            Port
                                                        </span>
                                                    </label>

                                                    <label style={{ marginLeft: '15px' }}>
                                                        <input
                                                            type="checkbox"
                                                            name="customsClearance1"
                                                            checked={destinationCharges.customsClearance1}
                                                            onChange={handleDestinationChange}
                                                            disabled={Disabledfordestination} // Disable the checkbox if Disabledfordestination is true
                                                        />
                                                        <span style={{ marginLeft: '5px', color: Disabledfordestination ? 'gray' : 'black' }}>
                                                            Customs Clearance
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    </div></> : ''}


                                </div>
                            </div>
                            <div className='form_button_container' style={{ marginTop: "20px", marginBottom: '12px' }}>
                                <button className='cancel_button' type='button' style={{ width: "100px" }} onClick={() => {
                                    window.location.href = "/indentsummary"
                                }}>Cancel</button>
                                {/* <button className='draft_button' type='button' >Save as Draft</button> */}
                                <button className='submit_button' type='submit' style={{ width: "100px" }}>{rfq_id ? "Update" : "Submit"}</button>
                            </div>
                        </form>
                    </>

                </div>
            </div >
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
            </div>
            {/* <div className={"overlay-part " + (overlay)} ></div> */}
        </div >
    );
};
export default withRouter(CreateRFQComponent);
// export default CreateRFQComponent;

function giveUTCDate(date) {
    return moment.parseZone(new Date(date)).utcOffset("-05:30")._d
}