import React from "react";
// Styles for buttons and titles
const fileDownloadButtonStyle = {
    whiteSpace: "normal", // Allow text to wrap
    wordWrap: "break-word", // Break long words if necessary
    overflow: "visible", // Ensure text doesn't get hidden
    display: "block", // Block-level element
    width: "100%", // Full-width buttons
    maxWidth: "300px", // Adjust based on your layout
    textAlign: "left", // Align text to the left
    padding: "10px", // Add spacing for better appearance
    marginBottom: "8px", // Add spacing between buttons
    cursor: "pointer", // Pointer cursor
};
const titleStyle = {
    fontSize: "14px", // Adjust font size
    marginBottom: "10px", // Add spacing below the title
};
var downloadBtn = "#1976d2";
var cursorPoint = "pointer";
// Reusable FileDownloadButton Component
const FileDownloadButton = ({ files, title, handleFileDownload }) => {
    // Only render if files are present
    if (!files || files.length === 0) return null;
    return (
        <div>
            <h5 style={titleStyle}>{title}:</h5>
            {files.map((fileName, index) => (
                <button
                key={index}
                className="btn btn-light-blue mb-3 "
                onClick={() => handleFileDownload(fileName)}
                title={fileName} // Tooltip for long file names
                style={{
                    ...fileDownloadButtonStyle,
                    display: "flex", // Flex layout
                    flexDirection: "row", // Ensure the icon stays in the same row
                    alignItems: "flex-start", // Align items to the top
                    justifyContent: "space-between", // Space between text and icon
                    textAlign: "left", // Align text to the left
                    backgroundColor: "#3498DB", // Thicker blue background
                    borderColor: "#3498DB",    // Matching thicker blue border
                    color: "#ffffff",          // White text for contrast
                    padding: "10px 20px",      // Optional: Adjust padding for appearance
                    borderRadius: "5px",       // Optional: Rounded corners
                    fontSize: "14px",          // Optional: Adjust font size
                }}
            >
                <span style={{ flex: 1, wordBreak: "break-word" }}>
                    {(fileName !== undefined && fileName !== "undefined")
                        ? (fileName.includes("charges/")
                            ? fileName.replace("charges/", "").includes('_')
                                ? fileName.replace("charges/", "").split('_').slice(0, -1).join('_')
                                : fileName.replace("charges/", "")
                            : fileName.includes('_')
                                ? fileName.split('_').slice(0, -1).join('_')
                                : fileName)
                        : `Download File ${index + 1}`}
                </span>
                <i
                    style={{
                        color: downloadBtn,
                        cursor: cursorPoint,
                        marginLeft: "10px", // Add space between text and icon
                        flexShrink: 0, // Prevent the icon from shrinking
                    }}
                    className="fa fa-download f20"
                ></i>
            </button>
            ))}
        </div>
    );
};
// Main FileDownloadSection Component
const FileDownloadSection = ({ crane_file, odc_file, detention_file, unloading_file, handleFileDownload }) => {
    return (
        <div>
            <FileDownloadButton files={crane_file} title="Crane Files" handleFileDownload={handleFileDownload} />
            <FileDownloadButton files={odc_file} title="ODC Files" handleFileDownload={handleFileDownload} />
            <FileDownloadButton files={detention_file} title="Detention Files" handleFileDownload={handleFileDownload} />
            <FileDownloadButton files={unloading_file} title="Unloading Files" handleFileDownload={handleFileDownload} />
        </div>
    );
};
export default FileDownloadSection;
