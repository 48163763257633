import React, { useState, useEffect } from 'react';

const CountdownTimerforbid = ({ biddingWindow, createDate }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const calculateTimeLeft = () => {
      if (!biddingWindow || !createDate) return 'Completed';

      const [hours, minutes] = biddingWindow.split(':').map(Number);
      const localCreateDate = new Date(createDate.replace('Z', '') + '+05:30');  // Force it to interpret as IST
      const biddingEndTime = new Date(localCreateDate);
          biddingEndTime.setHours(biddingEndTime.getHours() + hours);
      biddingEndTime.setMinutes(biddingEndTime.getMinutes() + minutes);

      const currentTime = new Date();
      const difference = biddingEndTime - currentTime;

      if (difference <= 0) {
        return 'Completed';
      }

      const h = String(Math.floor(difference / (1000 * 60 * 60))).padStart(2, '0');
      const m = String(Math.floor((difference / (1000 * 60)) % 60)).padStart(2, '0');
      const s = String(Math.floor((difference / 1000) % 60)).padStart(2, '0');

      return `${h}:${m}`;
    };
    const timer = setInterval(() => {
      const time = calculateTimeLeft();
      setTimeLeft(time);
      if (time === 'Completed') clearInterval(timer);
    }, 1000);

    return () => clearInterval(timer);  // Cleanup the interval on component unmount
  }, [biddingWindow, createDate]);

  return (
    <p className="mt-10p">
      <strong>Bidding Time Left</strong>:&nbsp;
      <span className="bidscount">{timeLeft}</span>
    </p>
  );
};

export default CountdownTimerforbid;
