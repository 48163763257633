/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import more from 'material-ui/svg-icons/notification/more';
import CSVFileValidator from 'csv-file-validator';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var moment = require('moment');

export default class OrderShippingMaster extends Component {
    state = {
        show: false,
        basicTitle: '',
        basicType: "default",
        loadshow: "show-n",
        overly: "show-n",
        rowData: [],
        pivotmodeis: false,
        pivotcols: [],
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true

        },
        frameworkComponents: {
        },
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        shippingTypeRowData: [],
        orderTypeRowData: [],
        shipment_slider: 'show-n',
        order_slider: 'show-n',
        shipment_file: '',
        order_file: '',
        shipment_csvcontent: [],
        order_csvcontent: [],

    }

    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        redirectURL.post("/masters/getshippingOrderTypeMaster", {},{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
              }
          }).then(res => {
            let respData = res.data
            this.setState({
                orderTypeRowData: respData.orderType,
                shippingTypeRowData: respData.shippingType
            })
            console.log("resData", respData)
        })
    }
    closeAlert = () => {
        $("#uploadFile").val("")
        $("#uploadFile1").val("")
        this.setState({
            show: false,
            basicType: "",
            basicTitle: ""
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    
    resetUpload = () => {
        $("#uploadFile").val("")
        $("#uploadFile1").val("")      
        // document.getElementById("appednCSVData").innerHTML = ''
        // document.getElementById("appednCSVData1").innerHTML = ''
        // this.setState({
        //     slider: 'show-n',
        //     overly: "show-n",
        //     loadshow: 'show-n'
        // })
    }
    changeShipmentFileHandler = async (e) => {
        //console.log(e.target.files[0])
        if(e.target.files.length !== 0){
            const config = {
                headers: [
                    {
                        name: 'shipping_type',
                        inputName: 'shipping_type',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'shipping_type_desc',
                        inputName: 'shipping_type_desc',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                ]
            }
            const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9- ._,&#@]*$/); // define allowed characters using regex
            
            var data = CSVFileValidator(e.target.files[0], config)
                .then((csvData) => {
                    // console.log("shipping",Object.keys(csvData.data[0]).includes('shipping_type'))              
                    // if(csvData.inValidMessages.length > 0){
                    //     this.setState({
                    //         show: true,
                    //         basicTitle : 'dont upload other fields',
                    //         basicType : 'danger'
                    //     });
                    // }else{
                    //     console.log("else")
                    //     this.setState({
                    //         shipment_csvcontent: csvData.data
                    //     });
                    // }
                    // console.log("csvData ", csvData.inValidMessages)
                    let keys = Object.keys(csvData.data[0])
                    // console.log("keys ", keys)
                    let items = ['shipping_type', 'shipping_type_desc']
                    let check = false
                    if(csvData.inValidMessages.length === 0)
                    {
                        for (let i in keys){
                            // console.log("items.includes(keys[i]) ", items.includes(keys[i]))
                            if(items.includes(keys[i])){                 
                                check = true             
                            }else{
                                check = false
                                break
                            }
                        }
                    }
                    else{
                        check = false
                        
                    }
                    
                    // console.log("check1", check)
                    let csvalues = Object.values(csvData.data[0])
                    if(csvalues.length > 0)
                    {
                        for(let c in csvalues)
                        {
                            // console.log("csvalues value ",csvalues[c])
                            // console.log("aplhanumeichypen",aplhanumeichypen.test(csvalues[c])+"  "+csvalues[c])
                            if(aplhanumeichypen.test(csvalues[c]) === false)
                            {
                                check = false;
                                break;
                            }
                        }
                    }
                    // console.log("check2", check)
                    if (check === false) {
                        $("#uploadFile").val("")
                        // document.getElementById("appednCSVData").innerHTML = ''
                        this.setState({
                            show: true,
                            basicTitle: 'dont upload other fields or special characters',
                            basicType: 'danger'
                        });
                    } else {
                        this.setState({
                            shipment_csvcontent: csvData.data
                        });
                    }
                })
                .catch(err => { })
            // console.log("e.target.files[0]",e.target.result);
            var contentList = []
    
            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    // document.getElementById("appednCSVData").innerHTML = contents
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
            });
            // console.log(contentList);
            this.setState({
                shipment_file: e.target.files[0]
            });
            // console.log("e.target.files[0].type ", e.target.files[0]);
    
            if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))
    
            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    file: "",
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }
    }

    changeOrderFileHandler = async (e) => {
        if(e.target.files.length !== 0){
            const config = {
                headers: [
                    {
                        name: 'order_type',
                        inputName: 'order_type',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'order_type_desc',
                        inputName: 'order_type_desc',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                ]
            }
            const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9- ._:,&#@]*$/); // define allowed characters using regex
            var data = CSVFileValidator(e.target.files[0], config)
                .then((csvData) => {
                    console.log('csvData', csvData)
                    // if (csvData.inValidMessages.length > 0) {
                        
                    //     this.setState({
                    //         show: true,
                    //         basicTitle : 'upload relevant fields',
                    //         basicType : 'danger'
                    //     });
                    // }else{
                    //     console.log("else")
                    //     this.setState({
                    //         order_csvcontent: csvData.data
                    //     });
                    // } 
                    let keys = Object.keys(csvData.data[0])
                    let items = ['order_type', 'order_type_desc']
                    let check = false
                    if(csvData.inValidMessages.length === 0)
                    {
                        for (let i in keys){
                            // console.log("items.includes(keys[i]) ", items.includes(keys[i]))
                            if(items.includes(keys[i])){                 
                                check = true             
                            }else{
                                check = false
                                break
                            }
                        }
                    }
                    else{
                        check = false
                        
                    }
                    // let csvalues = Object.values(csvData.data[0])
                    // if(csvalues.length > 0)
                    // {
                    //     for(let c in csvalues)
                    //     {
                    //         // console.log("csvalues value ",csvalues[c])
                    //         // console.log("aplhanumeichypen",aplhanumeichypen.test(csvalues[c])+"  "+csvalues[c])
                    //         if(aplhanumeichypen.test(csvalues[c]) === false)
                    //         {
                    //             check = false;
                    //             break;
                    //         }
                    //     }
                    // }

                    let csvaluesarr = Object.values(csvData.data)
                    // console.log("csvalues", csvaluesarr)
                    if(csvaluesarr.length > 0)
                    {
                        for(var a=0;a<csvaluesarr.length; a++)
                        {
                            var csvalues = Object.values(csvaluesarr[a])
                            for(var c in csvalues)
                            {
                                
                                if(aplhanumeichypen.test(csvalues[c]) === false)
                                {
                                    check = false;
                                    break;
                                }
                            }
                        }
                    }
                    
                    // console.log("check", check)
                    if (check === false) {
                        $("#uploadFile1").val("")
                        // document.getElementById("appednCSVData1").innerHTML = ''
                        this.setState({
                            show: true,
                            basicTitle: 'dont upload other fields',
                            basicType: 'danger'
                        });
                    } else {
                        this.setState({
                            order_csvcontent : csvData.data
                        });
                    }              
                })
                .catch(err => { })
            // console.log("e.target.files[0]",e.target.result);
            var contentList = []
    
            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    // document.getElementById("appednCSVData1").innerHTML = contents
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
            });
            // console.log(contentList);
            this.setState({
                order_file: e.target.files[0]
            });
            // console.log("e.target.files[0].type ", e.target.files[0]);
    
            if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))
    
            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    file: "",
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }

        }
    }

    onCloseSliderDiv = () => {
        $("#uploadFile").val("")
        $("#uploadFile1").val("")     
        // document.getElementById("appednCSVData").innerHTML = ''
        // document.getElementById("appednCSVData1").innerHTML = ''   
        this.setState({
            overly:"show-n",
            loadshow:"show-n",
            // order_file: '',
            // order_csvcontent: [],
            // shipment_file: "",
            // shipment_csvcontent: [],
            shipment_slider: '',
            order_slider: ''
        })
    }
    onClickHideAll = () => {
        $("#uploadFile").val("")
        $("#uploadFile1").val("")
        // document.getElementById("appednCSVData").innerHTML = ''
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            shipment_slider: '',
            order_slider: ''
        });
    }

    onClickUploadData = (type) =>{
        // console.log("type", type)
        if (type === 1){
            this.setState({
                shipment_slider : 'slider-translate-40p',
                overly:"show-m",
                loadshow:"show-n"
            })
        }else{
            this.setState({
                order_slider : "slider-translate-40p",
                overly:"show-m",
                loadshow:"show-n"      
            })
        }
    }

    uploadBulkFormHandler = event =>{
        event.preventDefault()
        // console.log("event")
        const{shipment_csvcontent} = this.state
        // const formData = new FormData();       
        // formData.append("shipment_csvcontent", shipment_csvcontent);
        // console.log("FormData ", formData);
        if(shipment_csvcontent.length >0){
            redirectURL.post("/masters/saveshipmentMaster", shipment_csvcontent,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                  }
              }).then((resp) => {
                if (resp.data.message === 'success') {
                    this.setState({
                        selected_row_nodes: [],
                        show : true,
                        overly:"show-n",
                        basicType : 'success',
                        basicTitle : 'Succesfully Added',
                        shipment_slider : 'show-n'

                    })
                    // document.getElementById("appednCSVData1").innerHTML = ''   
                    // document.getElementById("appednCSVData").innerHTML = ''   
                }
                setTimeout(() =>{
                    this.loadData()
                }, 1000)
                // window.location.reload();
            })
        }else{
            this.setState({
                show : true,
                basicType : 'warning',
                basicTitle : 'cant insert empty data'
            })
        }
        
    }

    uploadBulkOrderFormHandler = event =>{
        event.preventDefault()
        // console.log("event")
        const{order_csvcontent} = this.state
        // const formData = new FormData();       
        // formData.append("order_csvcontent", order_csvcontent);
        // console.log("FormData ", formData);
        if(order_csvcontent.length > 0){
            redirectURL.post("/masters/saveOrderMaster", order_csvcontent,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                  }
              }).then((resp) => {
                if (resp.data.message === 'success') {
                    this.setState({
                        selected_row_nodes: [],
                        show : true,
                        overly:"show-n",
                        basicType : 'success',
                        basicTitle : 'Succesfully Added',
                        order_slider : 'show-n'
                    })
                }
                setTimeout(() =>{
                    this.loadData()
                }, 1000)
                // document.getElementById("appednCSVData1").innerHTML = ''   
                // document.getElementById("appednCSVData").innerHTML = ''
                // window.location.reload();
            })
        }else{
            this.setState({
                show : true,
                basicType : 'warning',
                basicTitle : 'cant insert empty data'
            })
        }
        
    }

    onCellClicked = (e) => {
        if (e.colDef.field == "delete") {
            const rowId = e.data._id;
            // console.log("delete",e.data);
            const shipmentCollection  = 'master_shipping_type'
            const orderCollection  = 'master_order_type'
            let collection = orderCollection
            if(Object.keys(e.data).includes('shipping_type')) collection = shipmentCollection

            const qry = {
                rowId: rowId,
                collection : collection,
                email : localStorage.getItem('email')
            }
            // console.log("delete_qry",qry);

            //var confirm = confirm("Are You Sure! Do you want really delete?");
            if (window.confirm("Are You Sure! Do you want really delete?")) {
                redirectURL.post("/masters/deleteRowData", qry,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  }).then((response) => {
                    // console.log("response",response);
                    if (response.data.status == "success") {
                        this.setState({
                            basicTitle: "Deleted Successfully.",
                            basicType: "success",
                            show: true
                        })
                        //window.location.reload();
                        this.loadData()
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to Delete.",
                            basicType: "warning",
                            show: true
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }

    render() {
        const { show, basicType, basicTitle, orderTypeRowData, modules, defaultColDef, shippingTypeRowData
            , statusBar, order_slider, shipment_slider } = this.state
        const shippingColumnDefs = [
            {
                headerName: "Shipping Type",
                field: "shipping_type",
                width: 200,
                filter: "agSetColumnFilter",
                resizable: true,
            },
            {
                headerName: "Shipping Type Description",
                field: "shipping_type_desc",
                width: 200,
                filter: "agSetColumnFilter",
                resizable: true,
            },
            {
                pinned: "left",
                headerName: "",
                field: "delete",
                width: 50,
                resizable: true,
                editable: false,
                cellRenderer: params => {
                    return '<i class="icofont icofont-ui-delete"></i>';
                },
                filter: false,
            }
        ]
        const orderTypeColumnDefs = [
            {
                headerName: "Order Type",
                field: "order_type",
                width: 200,
                filter: "agSetColumnFilter",
                resizable: true,
            },
            {
                headerName: "Order Type Desc",
                field: "order_type_desc",
                width: 200,
                filter: "agSetColumnFilter",
                resizable: true,
            },
            {
                pinned: "left",
                headerName: "",
                field: "delete",
                width: 50,
                resizable: true,
                editable: false,
                cellRenderer: params => {
                    return '<i class="icofont icofont-ui-delete"></i>';
                },
                filter: false,
            }
        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className='row'>
                    <div className="card col-xl-12 col-lg-12">
                        <div className="card-header" style={{ padding: '10px' }}>
                            <h4>Shipping & Order Type Master</h4>
                        </div>
                                <div className='button-group d-flex justify-content-between'>
                                    <button type="button" className="btn btn-warning float-right" onClick={() => this.onClickUploadData(1)}>Bulk Upload Shipping Type</button>
                                    <button type="button" className="btn btn-warning float-right" onClick={() => this.onClickUploadData(2)}>Bulk Upload Order Type</button>                                   
                                </div>
                        <div className="height-equal equal-height-lg" style={{ padding: '10px' }}>
                            <div className="card-body" style={{ padding: '10px' }}>
                                <div className='row'>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="card-header" style={{ padding: '10px' }}>
                                            <h5>Shipping Master
                                            </h5>
                                        </div>
                                        <div id="myGrid" style={{ height: "400px" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={modules}
                                                columnDefs={shippingColumnDefs}
                                                defaultColDef={defaultColDef}
                                                rowData={shippingTypeRowData}
                                                enableCharts={false}
                                                enableRangeSelection={true}
                                                statusBar={statusBar}
                                                pagination={true}
                                                groupHeaderHeight={40}
                                                headerHeight={40}
                                                floatingFiltersHeight={50}
                                                pivotGroupHeaderHeight={30}
                                                pivotHeaderHeight={50}
                                                rowHeight={40}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                onCellClicked={this.onCellClicked}
                                            //sideBar={sideBar}
                                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="card-header" style={{ padding: '10px' }}>
                                            <h5>Orders Master

                                            </h5>
                                        </div>
                                        <div id="myGrid" style={{ height: "400px" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={modules}
                                                columnDefs={orderTypeColumnDefs}
                                                defaultColDef={defaultColDef}
                                                rowData={orderTypeRowData}
                                                enableCharts={false}
                                                enableRangeSelection={true}
                                                statusBar={statusBar}
                                                pagination={true}
                                                groupHeaderHeight={40}
                                                headerHeight={40}
                                                floatingFiltersHeight={50}
                                                pivotGroupHeaderHeight={30}
                                                pivotHeaderHeight={50}
                                                rowHeight={40}
                                                onGridReady={this.onGridReady1}
                                                onGridState={this.onGridState1}
                                                onCellClicked={this.onCellClicked}

                                            //sideBar={sideBar}
                                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className={"dataLoadpage " + (this.state.loadshow)}>
                        </div>
                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
                </div>
                {/* bulk upload */}
                <div className={"slide-r " + (shipment_slider)} style={{ overflow: "hidden" }}>
                    <h3 className="subH">Bulk Upload Shipment Type Data
                        <span
                            className="float-right closebtn"
                            style={{ marginRight: "25px" }}
                            onClick={this.onClickHideAll}
                        >
                            X
                        </span>
                    </h3>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="container-fluid">
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label>
                                    <input type="file" id="uploadFile" name="uploadFile" onChange={this.changeShipmentFileHandler} className="form-control" required />
                                    <div style={{color:"#ff0000",marginTop:"10px"}}>
                                        ** Allowed Space and Special Characters are <span style={{fontWeight:"700", fontSize:"18px", fontFamily:"arial"}}>-._,&#@</span>
                                    </div>
                                </div>
                                {/* <div className='p-2' id="appednCSVData"></div> */}
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                            {/* <p className='pl-3' style={{ color: 'red' }}> please format the dates in yyyy-mm-dd hh:mm before uploading</p> */}

                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/json/bulk_shipping_type_upload.csv')} target="_blank">Sample Template</a>

                            </div>
                        </div>
                    </div>
                </div>
                {/* bulk upload */}
                <div className={"slide-r " + (order_slider)} style={{ overflow: "hidden" }}>
                    <h3 className="subH">Bulk Upload Order Type Data
                        <span
                            className="float-right closebtn"
                            style={{ marginRight: "25px" }}
                            onClick={this.onClickHideAll}
                        >
                            X
                        </span>
                    </h3>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="container-fluid">
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkOrderFormHandler}>

                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label>
                                    <input type="file" id="uploadFile1" name="uploadFile" onChange={this.changeOrderFileHandler} className="form-control" required />
                                    <div style={{color:"#ff0000",marginTop:"10px"}}>
                                        ** Allowed Space and Special Characters are <span style={{fontWeight:"700", fontSize:"18px", fontFamily:"arial"}}>-._,&#@</span>
                                    </div>
                                </div>
                                {/* <div className='p-2' id="appednCSVData1"></div> */}
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                            {/* <p className='pl-3' style={{ color: 'red' }}> please format the dates in yyyy-mm-dd hh:mm before uploading</p> */}

                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/json/bulk_order_type_upload.csv')} target="_blank">Sample Template</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}