/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React from 'react';

const ViewPODButton = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickViewPOD(props);
        if (props.data.pod_document !== "" && props.data.pod_document !== undefined) {
            var docvie = props.data.pod_document;
            console.log("docvie ", docvie);
        } else {
            console.log("No document to view");
        }
    };

    return (
        <button onClick={handleClick} className="btn btn-info" style={{padding: "2px 10px", fontSize: "13px", margin: "0"}} title="View Pod">View POD</button>
    );
};

export default ViewPODButton;

