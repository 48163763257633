
import React, { Component } from 'react';

const ConfirmBtnComponentBtn = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickConfirmBtn(props);
    };
    return (
        <div>
            <button onClick={handleClick} className="btn btn-primary" style={{ padding: "2px 10px", fontSize: "13px", margin: "0" }} title="Confirm" >Confirm </button>
        </div>
    );
};

export default ConfirmBtnComponentBtn;
