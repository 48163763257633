const axios = require('axios');

const axiosInstance = axios.create({
  /* Setting the base URL for the axios instance. */
   baseURL: 'https://alqa.autometrics.in/ashokleyland_app/',
  //  baseURL: 'https://al-autometrics.in/ashokleyland/', //prod server
      // baseURL:'http://3.108.127.76:5036/ashokleyland_app',
    //  baseURL:'http://localhost:5036/ashokleyland_app',
  /* other custom settings */
});

module.exports = axiosInstance;
