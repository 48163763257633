/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYY, getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CSVFileValidator from 'csv-file-validator';
import more from 'material-ui/svg-icons/notification/more';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var moment = require('moment');

export default class FreightMaster extends Component {
    state = {
        show: false,
        basicTitle: '',
        basicType: "default",
        loadshow: "show-n",
        overly: "show-n",
        rowData: [],
        pivotmodeis: false,
        pivotcols: [],
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true

        },
        frameworkComponents: {
        },
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        csvcontent: [],
        file: '',
        slider: 'show-n',
        airBtn : 'btn-danger',
        surfaceConsBtn : 'btn-default',
        allrowData:[],
        
        // airRowData:[],
        // respData:[],
        //  shipping_type:""
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        redirectURL.post("/masters/getFreightMaster", {},{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
              }
          }).then(res => {
            try {
                let respData = res.data.records;
                   console.log("respData", respData)
                  
                  var airRowData = [];
                //    var surfaceRowData = [];
                    if(respData.length > 0)
                    {
                        
                        //  respData.sort(GetSortAscOrder("rowind"));
                        
                        respData.map((item) => {
                            // console.log("item.shipping_type", item.shipping_type)
                            var t = item.shipping_type;
                            //   console.log("here trim ", t.trim())
                            if(t == "Air" || t == "AIR" || t == "air" ||  t === "AIR" || t === "Air" || t === "air")
                            {
                                airRowData.push(item);
                            }
                            // console.log(airRowData,"121")
                            
                            
                        })
                    }   
                    
            //   console.log("respDayyyta", respData)
                    this.setState({
                        overly : 'show-n',
                        loadshow :'show-n',
                        rowData: airRowData,
                        allrowData: respData,
                    })
                     
                    // var surfaceRowData = [];
                    // if(respData.length > 0)
                    // {
                    //     surfaceRowData.map((item) => {
                    //         //   console.log("item.shipping_type", item.shipping_type)
                    //         var t = item.shipping_type;
                    //         //  console.log("here ", t)
                    //         if(t === "SURFACE" || t === "Surface" || t === "surface" ||  t === "surface ")
                    //         {
                    //             surfaceRowData.push(item);
                    //         }
                    //         this.setState({
                    //             overly : 'show-n',
                    //             loadshow :'show-n',
                    //             rowData: surfaceRowData,
                    //             allrowData: respData,
                    //         })
                    //     })
                    // }
                    
                    // else
                    // {
                    //     this.setState({
                    //         overly : 'show-n',
                    //          loadshow :'show-n',
                    //         rowData: [],
                    //         allrowData: [],
                    //     })                    
                    // }
              
                // const surfaceRowData = respData.filter(e => console.log(e.shipping_type.toLowerString() === 'air'));
              
                setTimeout(() => {
                    if(this.state.airBtn === 'btn-danger')
                    {
                        this.onClickTab(1);
                    }
                    
                    if(this.state.surfaceConsBtn === 'btn-danger')
                    {
                        this.onClickTab(2);
                    }
                    this.setState({
                        overly : 'show-n',
                        loadshow :'show-n',
                        // rowData: allrowData
                    })
                },500)
                
                
            } catch (error) {
                // console.log(error,'opopo')
            }
            
        })
    }
    closeAlert = () => {
        this.setState({
            show: false,
            basicType: "",
            basicTitle: ""
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    changeFileHandler = async (e) => {
        if(e.target.files.length !== 0){
            const config = {
                headers: [
                    // {
                    //     name: 'plant_code',
                    //     inputName: 'plant_code',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                    // {
                    // 	name: 'destination',
                    // 	inputName: 'destination',
                    // 	required: true,
                    // 	requiredError: function (headerName, rowNumber, columnNumber) {
                    // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    // 	}
                    // },
                    // {
                    //     name: 'dealer_code',
                    //     inputName: 'dealer_code',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                    // {
                    // 	name: 'dealer_name',
                    // 	inputName: 'dealer_name',
                    // 	required: true,
                    // 	requiredError: function (headerName, rowNumber, columnNumber) {
                    // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    // 	}
                    // },
                    // {
                    //     name: 'transporter_code',
                    //     inputName: 'transporter_code',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                    // {
                    //     name: 'customer_city',
                    //     inputName: 'customer_city',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                    // {
                    //     name: 'customer_state',
                    //     inputName: 'customer_state',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                    // {
                    //     name: 'customer_zone',
                    //     inputName: 'customer_zone',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                    // {
                    //     name: 'shipping_type',
                    //     inputName: 'shipping_type',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                    // {
                    //     name: 'freight_rate',
                    //     inputName: 'freight_rate',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                    //  {
                    // 	name: 'oda_charges',
                    // 	inputName: 'oda_charges',
                    // 	required: true,
                    // 	requiredError: function (headerName, rowNumber, columnNumber) {
                    // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    // 	}
                    // },
                    // {
                    // 	name: 'freight_penality_percentage',
                    // 	inputName: 'freight_penality_percentage',
                    // 	required: true,
                    // 	requiredError: function (headerName, rowNumber, columnNumber) {
                    // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    // 	}
                    // },                   
                    // {
                    //     name: 'agreement_start_date',
                    //     inputName: 'agreement_start_date',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                    // {
                    //     name: 'agreement_end_date',
                    //     inputName: 'agreement_end_date',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                    // {
                    //     name: 'rate_revision_start_date',
                    //     inputName: 'rate_revision_start_date',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                    // {
                    //     name: 'freight_increase',
                    //     inputName: 'freight_increase',
                    //     required: true,
                    //     requiredError: function (headerName, rowNumber, columnNumber) {
                    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    //     }
                    // },
                    // {
                    // 	name: 'charges',
                    // 	inputName: 'charges',
                    // 	required: true,
                    // 	requiredError: function (headerName, rowNumber, columnNumber) {
                    // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    // 	}
                    // },
                    // {
                    // 	name: 'oda',
                    // 	inputName: 'oda',
                    // 	required: true,
                    // 	requiredError: function (headerName, rowNumber, columnNumber) {
                    // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    // 	}
                    // },
                    // {
                    // 	name: 'transit_days',
                    // 	inputName: 'transit_days',
                    // 	required: true,
                    // 	requiredError: function (headerName, rowNumber, columnNumber) {
                    // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    // 	}
                    // },
                    
                    // {
                    //     headerName: "Condition Type",
                    //     field: "plant_code",
                    //     width: "140"
                    // },
                    // {
                    //     headerName: "Plant Code",
                    //     field: "plant_code",
                    //     width: "140"
                    // },
                    // {
                    //     headerName: "Destination",
                    //     field: "destination",
                    //     width: "140"
                    // },
                    // {
                    //     headerName: "Dealer Code",
                    //     field: "dealer_code",
                    //     width: "140"
                    // },
                    // {
                    //     headerName: "Dealer Name",
                    //     field: "dealer_name",
                    //     width: "140"
                    // },
                    {
                        name: 'Condition Type',
                        inputName: 'condition_type',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Service Agent',
                        inputName: 'service_agent',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Service Agent Name',
                        inputName: 'service_agent_name',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Departure Zone',
                        inputName: 'departure_zone',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Departure Descrption',
                        inputName: 'plant',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Destinatio Zone',
                        inputName: 'customer_zone',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Destination Descrption',
                        inputName: 'customer_city',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'State',
                        inputName: 'customer_state',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Shipment Type',
                        inputName: 'shipping_type',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Descption',
                        inputName: 'descption',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Amount',
                        inputName: 'charges',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Valid From',
                        inputName: 'agreement_start_date',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Valid To',
                        inputName: 'agreement_end_date',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Released Status',
                        inputName: 'status',
                        required: false,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Rel Status Desc',
                        inputName: 'status_desc',
                        required: false,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Deletion Id',
                        inputName: 'deletion_id',
                        required: false,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Destination Status',
                        inputName: 'destination_status',
                        required: false,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },

                    {
                        name: 'Oda Charges',
                        inputName: 'oda_charges',
                        required: false,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    {
                        name: 'Freight Penality Percentage',
                        inputName: 'freight_penality_percentage',
                        required: false,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                
                ]
            }
            const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9-._/ %&#@]*$/); // define allowed characters using regex
            const allowedCharacters = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{}; ':"\\|,.<>\/?]*$/; // define allowed characters using regex
            const validNumber = new RegExp(/^-?([0-9]|[1-8][0-9]|90)(\.\d+)?$/);
            const validFloat = new RegExp(/^\d+(\.\d{2})*$/)
            const validDigit = new RegExp(/^[0-9 ]*$/);
            const validText = new RegExp(/^[a-zA-Z ]*$/);
            const validAddress = new RegExp(/^[a-zA-Z0-9()-_/#@&,. :]*$/);
            const validDate = new RegExp(/^\d{2}-\d{2}-\d{4}$/)
            
            var data = CSVFileValidator(e.target.files[0], config)
                .then((csvData) => {
                     console.log('csvData', csvData)
                    // if (csvData.inValidMessages.length > 0) {
                    //     $("#uploadFile").val("")
                    //     this.setState({
                    //         show: true,
                    //         basicTitle: 'dont upload other fields',
                    //         basicType: 'danger'
                    //     });
                    // } else {
                    //     this.setState({
                    //        csvcontent: csvData.data
                    //     });
                    // }
                    let keys = Object.keys(csvData.data[0])
                    //   console.log(keys,'keys')
                    let items = ['condition_type','service_agent','service_agent_name','departure_zone','plant','customer_zone','customer_city','customer_state','shipping_type','descption','charges','agreement_start_date','agreement_end_date','status','status_desc','deletion_id','destination_status','oda_charges','freight_penality_percentage',
                    ]
                    let check = true
                    // if(csvData.inValidMessages.length === 0)
                    // {
                    //     for (let i in keys){
                    //         // console.log("items.includes(keys[i]) ", items.includes(keys[i]))
                    //         if(items.includes(keys[i])){                 
                    //             check = true             
                    //         }else{
                    //             check = false
                    //             break
                    //         }
                    //     }
                    // }
                    // else{
                    //     check = false
                        
                    // }
                    var mobMes  = 1;
                    var textMes= 1;
                    var addressMes = 1;
                    var digitMes = 1;
                    var dateMes  = 1;
                    var floatMes = 1;
                    // let csvalues = Object.values(csvData.data[0])
                    // if(csvalues.length > 0)
                    // {
                    //     for(let c in csvalues)
                    //     {
                    //         // console.log("csvalues value ",csvalues[c])
                    //         // console.log("aplhanumeichypen",aplhanumeichypen.test(csvalues[c])+"  "+csvalues[c])
                    //         if(aplhanumeichypen.test(csvalues[c]) === false)
                    //         {
                    //             check = false;
                    //             break;
                    //         }
                    //     }
                    // }

                    let csvaluesarr = Object.values(csvData.data)
                    //  console.log("csvalues", csvaluesarr)
                    if(csvaluesarr.length > 0)
                    {
                        for(var a=0;a<csvaluesarr.length; a++)
                        {
                            //  console.log("csvaluesarr",csvaluesarr)
                            var csvalues = Object.values(csvaluesarr[a])
                            for(var c in csvalues)
                            {
                                //   console.log("c",c)
                                //   console.log("aplhanumeichypen",aplhanumeichypen.test(csvalues[c])+"  "+csvalues[c])
                                // if(parseInt(c) === 2  || parseInt(c) === 7 || parseInt(c) === 8)
                                // {
                                //     console.log("csvalues[c] ",csvalues[c])
                                //     console.log("aplhanumeichypen.test(csvalues[c])",validText.test(csvalues[c]))
                                //      console.log("csvalues value ",c+"====="+validText.test(csvalues[c]))
                                //     if(validText.test(csvalues[c].trim()) === false)
                                //     {
                                //         check = false;
                                //         textMes = 0
                                //         break;
                                //     }
                                // }
                                // if(parseInt(c) === 10)
                                // {
                                //      console.log("csvalues[c] ",csvalues[c])
                                //      console.log("aplhanumeichypen.test(csvalues[c])",validFloat.test(csvalues[c]))
                                //       console.log("csvalues value ",c+"====="+validFloat.test(csvalues[c])) 
                                //     if(validFloat.test(csvalues[c].trim()) === false)
                                //     {
                                //         check = false;
                                //         floatMes = 0
                                //         break;
                                //     }
                                // }
                                // if( parseInt(c) === 3 || parseInt(c) === 5 || parseInt(c) === 8  || parseInt(c) === 15 || parseInt(c) === 17)
                                // {
                                //     //  console.log("csvalues[c] ",csvalues[c])
                                //     //   console.log("aplhanumeichypen.test(csvalues[c])",validDigit.test(csvalues[c]))
                                //     //    console.log("csvalues value ",c+"====="+validDigit.test(csvalues[c].trim()))
                                //     if(validDigit.test(csvalues[c].trim()) === false)
                                //     {
                                //         check = false;
                                //         digitMes = 0
                                //         break;
                                //     }
                                // }
                                
                                // if(parseInt(c) === 11 || parseInt(c) === 12)
                                // {
                                //     if(validDate.test(csvalues[c].trim()) === false)
                                //     {
                                //         check = false;
                                //         dateMes = 0
                                //         break;
                                //     }
                                // } 
                                // if(parseInt(c) === 0  || parseInt(c) === 1 ||  parseInt(c) === 4 || parseInt(c) === 6  || parseInt(c) === 9|| parseInt(c) === 13 || parseInt(c) === 14  || parseInt(c) === 16|| parseInt(c) === 18)
                                 { 
                                //     console.log("csvalues[c] ",csvalues[c])
                                //     console.log("aplhanumeichypen.test(csvalues[c])",aplhanumeichypen.test(csvalues[c]))
                                //      console.log("csvalues value ",c+"====="+aplhanumeichypen.test(csvalues[c]))  
                                    if(aplhanumeichypen.test(csvalues[c].trim()) === false)
                                    {
                                        check = false;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    else{
                        check = false;
                    }
                    
                    //  console.log("check", check)
                    if (check === false) {
                            $("#uploadFile").val("")
                            // console.lpog("textMes",textMes)
                            // document.getElementById("appednCSVData").innerHTML = ''
                            // if(parseInt(textMes) === 0 )
                            // {
                            //     this.setState({
                            //         show: true,
                            //         basicTitle: 'Invalid  Name added',
                            //         basicType: 'danger'
                            //     });
                            // }    
                            // else if(parseInt(floatMes) === 0 )
                            // {
                            //     this.setState({
                            //         show: true,
                            //         basicTitle: 'Invalid number added',
                            //         basicType: 'danger'
                            //     });
                            // }
                            // else if(parseInt(digitMes) === 0 )
                            // {
                            //     this.setState({
                            //         show: true,
                            //         basicTitle: 'Invalid  Number added',
                            //         basicType: 'danger'
                            //     });
                            // }
                        
                            // else if(parseInt(dateMes) === 0 )
                            // {
                            //     this.setState({
                            //         show: true,
                            //         basicTitle: 'Invalid Date added',
                            //         basicType: 'danger'
                            //     });
                            // }
                            // else
                            // {
                                this.setState({
                                    show: true,
                                    basicTitle: 'dont upload other fields or special characters',
                                    basicType: 'danger'
                                });
                            // }    
                            } else {
                                this.setState({
                                    csvcontent : csvData.data
                                });
                            } 
                })
                .catch(err => { })
    
            var Out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    // document.getElementById("appednCSVData").innerHTML = contents
                     console.log("contents ", contents)
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
                // console.log("tt ", tt)
            });
            //console.log("Out ", out);
    
            this.setState({
                file: e.target.files[0]
            });
            //console.log("e.target.files[0].type ", e.target.files[0].type);
    
            if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }
	}
    uploadBulkFormHandler = e => {
        e.preventDefault(); 
        this.setState({
            overlay:"show-m",
            loadshow : 'show-m'
        })
        const {file, csvcontent} = this.state
           console.log("csvcontent",csvcontent)
        if(csvcontent.length > 0){
             redirectURL.post("/masters/uploadFreightMaster",csvcontent,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                  }
              }).then((response) => {
                $("#uploadFile").val("")
                // document.getElementById("appednCSVData").innerHTML = ''
            this.setState({
                slider : 'show-n',
                overlay:"show-n",
                loadshow : 'show-n',
                show: true,
                basicType: 'success',
                basicTitle: 'success',
                csvcontent:[],
            })
            setTimeout(() =>{
                this.loadData()
            }, 1000)
        })
        }

    }

    bulkUploadFreightMaster = () => {
        this.setState({
            slider: 'slider-translate-40p',
            overly: "show-m",
            loadshow: 'show-n'
        })
    }
    resetUpload = () => {
        // document.getElementById("appednCSVData").innerHTML = ''
        $("#uploadFile").val("")
        // this.setState({
        //     slider: 'show-n',
        //     overly: "show-n",
        //     loadshow: 'show-n'
        // })
    }

    onClickHideAll = () => {
        $("#uploadFile").val("")
        // document.getElementById("appednCSVData").innerHTML = ''
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            slider: "",
        });
    }

    onClickTab = (tab) =>{
        // console.log("tab",tab)
         var allrowData = this.state.allrowData
        //    console.log("allrowData", allrowData)
        if (tab === 1) {
            
            if(allrowData.length > 0)
            {
                var airRowData = [];
                allrowData.map((item) => {
                    if(item.shipping_type_name !== null && item.shipping_type_name !== undefined){
                //   console.log("item.shipping_type", item.shipping_type)
                    var t = item.shipping_type_name;
                    //  console.log("here ", t)
                    if(t === "Air" || t === "AIR" || t === "air" ||  t === "AIR" || t === "Air" || t === "air")
                    {
                        airRowData.push(item);
                    }
                } 
                })
            }
            this.setState({
                airBtn : 'btn-danger',
                surfaceConsBtn : 'btn-default',
                rowData : airRowData,
            })
        }
        else if(tab === 2){
            
            if(allrowData.length > 0)
            {  
                var surfaceRowData = [];
                allrowData.map((item) => {
                    //   console.log("item.shipping_type", item.shipping_type)
                    var t = item.shipping_type_name;
                    //  console.log("here ", t)
                    if(t === "SURFACE" || t === "Surface" || t === "surface" ||  t === "surface ")
                    {
                        surfaceRowData.push(item);
                    }
                })
            }
            this.setState({
                airBtn : 'btn-default',
                surfaceConsBtn : 'btn-danger',
                rowData : surfaceRowData   
            })
        }
    }

    onCellClicked = (e) => {
        if (e.colDef.field == "delete") {
            var rowId = e.data._id;
            // console.log("delete",e.data);
            var qry = {
                rowId: rowId,
                collection : 'master_freight_rate',
                email : localStorage.getItem('email')
            }
            //var confirm = confirm("Are You Sure! Do you want really delete?");
            if (window.confirm("Are You Sure! you want  delete?")) {
                redirectURL.post("/masters/deleteRowData", qry,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  }).then((response) => {
                    //  console.log("response",response);
                    if (response.data.status == "success") {
                        this.setState({
                            basicTitle: "Deleted Successfully.",
                            basicType: "success",
                            show: true
                        })
                        //window.location.reload();
                        setTimeout(() =>{
                            this.loadData()
                            
                        }, 1000)
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to Delete.",
                            basicType: "warning",
                            show: true
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }

    render() {
        const { show, basicType, basicTitle, rowData, modules, defaultColDef, statusBar, slider, loadshow, overly,airBtn,surfaceConsBtn } = this.state
        //console.log("rowData",rowData)
        let freight_masterColumns = [
            {
                headerName: "Condition Type",
                field: "condition_type",
                width: "140"
            },
            // {
            //     headerName: "Plant Code",
            //     field: "plant_code",
            //     width: "140"
            // },
            // {
            //     headerName: "Destination",
            //     field: "destination",
            //     width: "140"
            // },
            // {
            //     headerName: "Dealer Code",
            //     field: "dealer_code",
            //     width: "140"
            // },
            // {
            //     headerName: "Dealer Name",
            //     field: "dealer_name",
            //     width: "140"
            // },
            {
                headerName: "Service Agent",
                field: "service_agent",
                width: "140"
            },
            {
                headerName: "Service Agent Name",
                field: "service_agent_name",
                width: "140"
            },
            {
                headerName: "Departure Zone",
                field: "departure_zone",
                width: "140"
            },
            {
                headerName: "Departure Descrption",
                field: "plant",
                width: "140"
            },
            {
                headerName: "Destination Zone",
                field: "customer_zone",
                width: "140"
            },
            {
                headerName: "Destination Descrption",
                field: "customer_city",
                width: "140"
            },
            {
                headerName: "State",
                field: "customer_state",
                width: "140"
            },
           
            {
                headerName: "Shipment Type",
                field: "shipping_type_name",
                width: "140",
                // valueGetter:function(params){
                //     try {
                //         var sdata = params.data.shipping_type; 
                //         var sdatatype = sdata;
                //         if(sdata === "05" || sdata === 5)
                //         {
                //             var sdatatype = "Surface";
                //         }
                //         if(sdata === "07" || sdata === 7)
                //         {
                //             var sdatatype = "Air";
                //         }
                //         if(sdata === "22" || sdata === 22)
                //         {
                //             var sdatatype = "Train";
                //         }
                //         return sdatatype;
                //     } catch (error) {
                        
                //     }
                // }
            },
            {
                headerName: "Descrption",
                field: "descption",
                width: "140"
            },
            {
                headerName: "Amount (Rs)",
                field: "charges",
                width: "100"
            },
            {
                headerName: "Valid From",
                field: "agreement_start_date",
                width: 130,
                filter: "agSetColumnFilter",
                resizable: true,
                valueGetter: (params) => {
                    try{
                        if (params.data.agreement_start_date != "") {
                            return getHyphenDDMMYYYY(params.data.agreement_start_date);
                        }
                    }catch(e){
                        return params.data.agreement_start_date 
                    }
                    
                },
            },
            {
                headerName: "Valid To",
                field: "agreement_end_date",
                width: 130,
                filter: "agSetColumnFilter",
                resizable: true,
                valueGetter: (params) => {
                    try{
                        if (params.data.agreement_end_date != "") {
                            return getHyphenDDMMYYYY(params.data.agreement_end_date);
                        }
                    }catch(e){
                        return params.data.agreement_end_date
                    }
                   
                },
            },
            {
                headerName: "Released Status",
                field: "status",
                width: "140"
            },
            {
                headerName: "Rel Status Desc",
                field: "status_desc",
                width: "140"
            },
            {
                headerName: "Deletion ID",
                field: "deletion_id",
                width: "140"
            },
            {
                headerName: "Destination Status",
                field: "destination_status",
                width: "140"
            },
            {
                headerName: "ODA Charges",
                field: "oda_charges",
                width: "120"
            },
            {
                headerName: "Freight Penality Percentage",
                field: "freight_penality_percentage",
                width: "120"
            },


            // {
            //     headerName: "Customer City",
            //     field: "customer_city",
            //     width: "140"
            // },
            // {
            //     headerName: "Customer State",
            //     field: "customer_state",
            //     width: "140"
            // },
            // {
            //     headerName: "Customer Zone",
            //     field: "customer_zone",
            //     width: "140"
            // },
            
            // {
            //     headerName: "Freight Rate",
            //     field: "freight_rate",
            //     width: "80"
            // },
           
           
            
          
            // {
            //     headerName: "Rate Revision Start Date",
            //     field: "rate_revision_start_date",
            //     width: 130,
            //     filter: "agSetColumnFilter",
            //     resizable: true,
            //     valueGetter: (params) => {
            //         try{
            //             if (params.data.rate_revision_start_date != "") {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.rate_revision_start_date);
            //             }
            //         }catch(e){
            //             return params.data.rate_revision_start_date
            //         }
            //     },
            // },
            // {
            //     headerName: "Freight Increase (%)",
            //     field: "freight_increase",
            //     width: 130,
            //     filter: "agSetColumnFilter",
            //     resizable: true,
            //     valueGetter: (params) => {
            //         if(params.data.freight_increase){
            //             if (typeof params.data.freight_increase == 'string') {
            //                 return params.data.freight_increase
            //             } else {
            //                 return `${parseFloat(params.data.freight_increase) * 100}%`    
            //             }
            //         }else{
            //             return ""
            //         }
                   
            //     },
            // },
            // {
            //     headerName: "ODA Charges (Rs)",
            //     field: "oda",
            //     width: "100"
            // },
            // {
            //     headerName: "Transit Days",
            //     field: "transit_days",
            //     width: "100"
            // },
            {
                pinned: "left",
                headerName: "",
                field: "delete",
                width: 50,
                resizable: true,
                editable: false,
                cellRenderer: params => {
                    return '<i class="icofont icofont-ui-delete"></i>';
                },
                filter: false,
            }   
        ]

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="button-group">
						<button className={"btn " + this.state.airBtn} onClick={() => this.onClickTab(1)}>Air</button>
						<button className={"btn " + this.state.surfaceConsBtn} onClick={() => this.onClickTab(2)}>Surface</button>
					</div>
                <div className='row'>
                    <div className="card col-xl-12 col-lg-12">
                        <div className="card-header" style={{ padding: '10px' }}>
                            <h4>Freight Master</h4>
                            <div className="row">
                                <div className="col-sm-12">
                                    <button className="float-right btn btn-warning" onClick={this.bulkUploadFreightMaster}>
                                        Bulk Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="height-equal equal-height-lg" style={{ padding: '10px' }}>
                            <div className="card-body" style={{ padding: '10px' }}>
                                <div id="myGrid" style={{ height: "400px" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={modules}
                                        columnDefs={freight_masterColumns}
                                        defaultColDef={defaultColDef}
                                        rowData={rowData}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        statusBar={statusBar}
                                        pagination={true}
                                        groupHeaderHeight={40}
                                        headerHeight={50}
                                        floatingFiltersHeight={50}
                                        pivotGroupHeaderHeight={30}
                                        pivotHeaderHeight={50}
                                        rowHeight={40}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        onCellClicked={this.onCellClicked}
                                    //sideBar={sideBar}
                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                    />
                                </div>

                            </div>

                        </div>
                        <div className={"dataLoadpage " + (this.state.loadshow)}>
                        </div>
                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>


                    </div>
                    {/* tpt bulk upload  */}
                    <div className={"slide-r " + (slider)} style={{ overflow: "hidden" }}>
                        <h3 className="subH">Bulk Upload
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll} >X</span>
                        </h3>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="container-fluid">
                                <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                                    <div className="form-group mt-20p">
                                        <label className="">Upload File</label>
                                        <input type="file" name="uploadFile" id="uploadFile"  onChange={this.changeFileHandler} className="form-control" required />
                                            <div style={{color:"#ff0000",marginTop:"10px"}}>
                                                ** Allowed Space and Special Characters are <span style={{fontWeight:"700", fontSize:"18px", fontFamily:"arial"}}>-._:,%&#@</span>
                                            </div>
                                    </div>
                                    {/* <div className='p-2' id="appednCSVData"></div> */}
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
                                    </div>
                                </form>
                                { <p className='pl-3' style={{ color: 'red' }}> please format the dates in yyyy-mm-dd hh:mm:ss or dd-mm-yyyy hh:mm:ss before uploading</p> }
                                <p className='pl-3' style={{ color: 'red' }}> Please provide shipping_type as either Air / Surface</p>
                                <div className="form-group">
                                    <a className="btn btn-primary" href={require('../../assets/json/Bulk_freight_master_update_file.csv')} target="_blank">Sample Template</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 