/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
 
const ViewPODChildButton = (props) => {
    const handleClick = (e) => {
         e.stopPropagation();
        //  props.context.componentParent.onClickViewPOD(props);
         props.agGridReact.props.gridOptions.context.componentParent.onClickViewPOD(props);
        if(props.data.pod_document !== "" && props.data.pod_document !== undefined)
        {
            var docvie = props.data.pod_document;
            //  console.log("docvie ",docvie)
            // const newWindow = window.open(docvie, '_blank');
            // if (newWindow) {
            //     newWindow.opener = null;
            //   }
        }
        else{

        }
        
    };
// console.log("props ", props.data)
    return (
        <div>
            {/* <a href={(props.data.pod_document !== "" && props.data.pod_document !== undefined)?props.data.pod_document:"#"} target="_blank" 
              className={"label label-info"} 
              title="View POD" style={{padding:"0px 5px"}}>
                  <i className="icofont icofont-eye f12"></i>&nbsp; View POD
            </a>          
        </div> */}
    {/* //      <div> */}
            {(props.data.invoice_no !== "Total") &&
                <button onClick={handleClick} className="btn btn-info" style={{ padding: "2px 10px", fontSize: "13px", margin: "0" }} title="View Pod" >View POD </button>
            }
        </div>
    
    );
};

export default ViewPODChildButton;
