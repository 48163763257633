var moment = require('moment');
//let now = moment();
//console.log(moment.parseZone("2020-02-17T10:51:55.000Z").format());
//console.log(moment.parseZone("2020-02-17T10:51:55.000Z").format("DD-MM-YYYY hh:mm:ss"))
export var getDDMMYYYY = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD/MM/YYYY');
    }
}

export var getDDMMMYYYY = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD/MMM/YYYY');
    }
}


export var getDDMMYYYYHHMM = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD/MM/YYYY hh:mm');
    }
}


export var getDDMMYYYYHHMMSS = function(date_string) {
    //console.log("date_string ", date_string);
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD/MM/YYYY HH:mm:ss');
    }
    
}


export var getYYYYMMDD = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('YYYY/MM/DD');
    }
}

export var getYYYYMMDDHHMMSS = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('YYYY/MM/DD HH:mm:ss');
    }
}

export var getHyphenYYYYMMDDHHMMSS = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('YYYY-MM-DD HH:mm:ss');
    }
}


export var getHyphenYYYYMMDD = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('YYYY-MM-DD');
    }
}

export var getHyphenDDMMYYYYHHMMSS = function(date_string) {
    //console.log("date_string ", date_string);
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MM-YYYY HH:mm:ss');
    }
    
}
export var getHyphenDDMMYYYYHHMM = function(date_string) {
    //console.log("date_string ", date_string);
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MM-YYYY HH:mm');
    }
    
}

export var getHyphenDDMMMYYYYHHMM = function(date_string) {
    //console.log("date_string ", date_string);
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MMM-YYYY HH:mm');
    }
    
}




export var getHyphenDDMMYYYY = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MM-YYYY');
    }
}

export var getHyphenDDMMMYYYY = function(date_string) {
    if(date_string == '' || date_string == undefined)
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MMM-YYYY');
    }
}


export var getDDMMMYYYYDefault = function(date_string) {
    //console.log("date_string ", date_string);
    //var d = date_string.split(" ")

    if(date_string == '1970-01-01' || date_string == undefined || date_string == "")
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MMM-YYYY');
    }
    
}


// var getDDMMMYYYYHHMMDefault = function(date_string) {
//     //var d = date_string.split(" ")

//    if(date_string == '' || date_string == undefined)
//     {
//         return '';
//     }
//     else{
//         return moment.parseZone(date_string).format('DD-MMM-YYYY HH:mm');
//     }
    
// }
// exports.getHyphenDDMMMYYYYHHMM = getDDMMMYYYYHHMMDefault;

export var getDDMMMYYYYHHMMDefault = function(date_string) {
    //console.log("date_string ", date_string);
    if(date_string == '' || date_string == undefined || date_string == "1970-01-01T00:00:00.000Z")
    {
        return '';
    }
    else{
        return moment.parseZone(date_string).format('DD-MMM-YYYY HH:mm');
    }
    
}

export var getHyphenYYYYMMDDHHMM = function (date_string) {
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('YYYY-MM-DD HH:mm');
    }
}

export var convertSecondsToHHMM = function(seconds) {
    if(seconds == "0" || seconds == "")
    {
        return "";
    }
    else{
        seconds = Number(seconds);
        var hh = Math.floor((seconds / 3600));
        var hrr = Math.floor(seconds - (hh*3600));
        var mm = Math.floor(hrr/60)
        if(mm < 10)
        {
            var mins = "0"+mm;
        }
        else
        {
            var mins = mm;
        }

        if(hh < 10)
        {
            var hrs = "0"+hh;
        }
        else
        {
            var hrs = hh;
        }
        return hrs+":"+mins;
    }
}






