import React, { Component } from 'react';
 
const   UpdateTptChargesBtn= (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickUpdateChargesBtn(props);
    };
    return (
        <div>
           
            <button type="button"
              className={"btn btn-warning nopadding font12"} 
              title="ViewRequest"
              onClick={handleClick} >
                  <i className="icofont icofont-edit f16"></i>&nbsp;Update Charges
            </button>
        </div>
    );
};

export default UpdateTptChargesBtn;