import React, { useState, useEffect } from 'react';
import moment from 'moment';
import redirectURL from '../redirectURL';

const CountdownTimer = ({ expirationTime, indentId }) => {
  // Function to calculate the time left for a given expiration time
  const calculateTimeLeft = (expirationTime) => {
    const now = moment();
    const difference = moment(expirationTime).diff(now); // Calculate difference in time

    if (difference > 0) {
      return {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return null; // Time has expired
    }
  };

  // State for time left
  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(expirationTime));
  // State to track whether the API has been called for this specific indentId
  const [hasCalledAPI, setHasCalledAPI] = useState(false);

  useEffect(() => {
    // Update the time left every second
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(expirationTime);
      setTimeLeft(newTimeLeft);

      // If time is up for this indentId and API hasn't been called yet, call the API
      if (!newTimeLeft && !hasCalledAPI) {
        setHasCalledAPI(true); // Set the flag to true for this indentId to prevent duplicate calls
        const params = { indent_id: indentId };
        redirectURL
          .post('/consignments/getexpireddate', params)
          .then((resp) => {
            console.log(`API called successfully for indentId ${indentId}:`, resp);
          })
          .catch((err) => {
            console.error(`Error calling API for indentId ${indentId}:`, err);
          });
      }
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [expirationTime, hasCalledAPI, indentId]);

  // If time has expired, show "End"
  const isTimeUp = !timeLeft;

  return (
    <span style={{ marginLeft: '5px' }}>
      {isTimeUp ? (
        <span>End</span>
      ) : (
        <span>
          {timeLeft.hours}h {timeLeft.minutes}m remaining
        </span>
      )}
    </span>
  );
};

export default CountdownTimer;
