/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CSVFileValidator from 'csv-file-validator';
import more from 'material-ui/svg-icons/notification/more';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var moment = require('moment');

export default class specialchargesMasters extends Component {
    state = {
        show: false,
        basicTitle: '',
        basicType: "default",
        loadshow: "show-n",
        overly: "show-n",
        rowData: [],
        pivotmodeis: false,
        pivotcols: [],
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true

        },
        frameworkComponents: {
        },
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        csvcontent: [],
        file: '',
        slider: 'show-n',
        transporterlist:[]
    }

    componentDidMount() {
        this.onLoadData()
        redirectURL.post("/masters/getTransportersMaster",{},{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
            }
        })
        .then((response) => {
            var records = response.data.transporterMasters;
            var trans = []
            if(records.length > 0)
            {
                records.map((itm) => {
                    trans.push(itm.transporter_name+"---"+itm.transporter_code)
                })
            }
            // console.log("trans ", trans)
            this.setState({
                transporterlist:trans
            })
        })
    }
    closeAlert = () => {
        this.setState({
            show: false,
            basicType: "",
            basicTitle: ""
        })
    }
    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    bulkUploadTptMaster = () => {
        this.setState({
            slider: 'slider-translate-40p',
            overly: "show-m",
            loadshow: 'show-n'
        })
    }
    resetUpload = () => {
        $("#uploadFile").val("")
        // document.getElementById("appednCSVData").innerHTML = ''
        // this.setState({
        //     slider: 'show-n',
        //     overly: "show-n",
        //     loadshow: 'show-n'
        // })
    }
    onCloseSliderDiv = () => {
        $("#uploadFile").val("")
        // document.getElementById("appednCSVData").innerHTML = ''
        this.setState({
            slider: 'show-n',
            overly: "show-n",
            loadshow: 'show-n'
        })
    }

    onClickHideAll = () => {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            slider: "",
        });
    }
    onLoadData = () => {
        redirectURL.post('/masters/getMasterCharges', {},{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
              }
          }).then((response) => {
            var records = response.data.records;
            // console.log("records",records )
            var recordsarr =[];
            if(records.length > 0)
            {
                records.map((itm) =>{
                    itm.trasporter = itm.transporter_name+"---"+itm.transporter_code;
                    recordsarr.push(itm)
                })
            }         
            // console.log("recordsarr ", recordsarr)
            this.setState({
                rowData: recordsarr,
                overly: "show-n",
                loadshow: "show-n"
            });
        })

    }
    
    onCellClicked = (e) => {
        if (e.colDef.field == "delete") {
            var rowId = e.data._id;
            // console.log("delete",e.data);
            var qry = {
                rowId: rowId,
                collection : 'master_charges',
                email : localStorage.getItem('email')
            }
            //var confirm = confirm("Are You Sure! Do you want really delete?");
            if (window.confirm("Are You Sure! Do you want really delete?")) {
                redirectURL.post("/masters/deleteRowData", qry,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  }).then((response) => {
                    // console.log("response",response);
                    if (response.data.status == "success") {
                        this.setState({
                            basicTitle: "Deleted Successfully.",
                            basicType: "success",
                            show: true
                        })
                        //window.location.reload();
                        setTimeout(() =>{
                            this.onLoadData()
                        }, 1000)
                    }
                    else {
                        this.setState({
                            basicTitle: "Failed to Delete.",
                            basicType: "warning",
                            show: true
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }
    
    updateRowDataFunc = (event) => {
        event.preventDefault();
        var rowdata = []
        this.gridApi.forEachNode((rownode) => {

        // console.log("rownode ",rownode)

        rowdata.push(rownode.data)
        })
        //   let  rownodes = this.gridApi.getSelectedRows();
        //   console.log("rowdata",rowdata)
        // console.log("params",params)
        var params = {
            rowdata: JSON.stringify(rowdata)
        }
        if(rowdata.length>0){
            redirectURL.post("/masters/updateAllRowData", params,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                }
            }).then(response =>{
                if (response.data.status == "success") {
                    this.setState({
                        basicTitle: "Update Successfully.",
                        basicType: "success",
                        show: true
                    })
                    
                    //  window.location.reload();
                    setTimeout(() =>{
                        this.onLoadData()
                    }, 1000)
                }
            })
        }    
    }

    addNewRow()
    {
        var newRowData = [{transporter_name:"",osp_charge:"",lu_charge:"",special_charge:"", topay_charge:""}]
        var transaction = {
            add: newRowData,
            addIndex: -1,
        };
        this.gridApi.updateRowData(transaction);
    
    }
   
    onClickSaveData(){
        var cnf  = window.confirm("Are you sure you want to proceed?");
        if(cnf === true)
        { 
            var rownodes = [];
            this.gridApi.forEachNode(function (rowNode, index) {
                var data = rowNode.data;
                rownodes.push(data);
            });
            console.log("rownodes ", rownodes)
            var params ={
                rowdata:JSON.stringify(rownodes)
            }
            redirectURL.post("/masters/updateChagresRowData",params,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                }
            })
            .then((response) => {

            })
        }
    }
    render() {
        const { show, basicType, basicTitle, rowData, modules, defaultColDef, statusBar, slider, loadshow, overly, csvcontent, transporterlist } = this.state
        const transportersColumnDefs = [
            {
                headerName: 'Transporter Name',
                field: "trasporter",
                width: 200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: transporterlist
                },
            },
            {
                headerName: 'OSP Charge',
                field: "osp_charge",
                width: 200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Yes","No"]
                },
            },
            {
                headerName: 'LU Charge',
                field: "lu_charge",
                width: 200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Yes","No"]
                },	
            },
            {
                headerName: 'Special Charge',
                field: "special_charge",
                width: 200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Yes","No"]
                },
            },
            {
                headerName: 'To Pay Charge',
                field: "topay_charge",
                width: 200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Yes","No"]
                },
            },
            {
                pinned: "left",
                headerName: "",
                field: "delete",
                width: 50,
                resizable: true,
                editable: false,
                cellRenderer: params => {
                    return '<i class="icofont icofont-ui-delete"></i>';
                },
                filter: false,
            }
         
        ]

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className='row'>
                    <div className="card col-xl-12 col-lg-12">
                        <div className="card-header" style={{ padding: '6px' }}>
                            <h4>Master Special Charges</h4>
                            <div className="row">
                                <div className="col-sm-12">
                                <button className="btn btn-info float-right" onClick={this.addNewRow.bind(this)}>Add Transporter Charge</button>
                                {/* <button className="btn btn-danger float-right" onClick={this.updateRowDataFunc}>Update</button> */}
                                </div>
                            </div>
                        </div>
                        <div className="height-equal equal-height-lg" style={{ padding: '10px' }}>
                            <div className="card-body" style={{ padding: '10px' }}>
                                <div id="myGrid" style={{ height: "400px" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={modules}
                                        columnDefs={transportersColumnDefs}
                                        defaultColDef={defaultColDef}
                                        rowData={rowData}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        statusBar={statusBar}
                                        pagination={true}
                                        groupHeaderHeight={40}
                                        headerHeight={40}
                                        floatingFiltersHeight={50}
                                        pivotGroupHeaderHeight={30}
                                        pivotHeaderHeight={50}
                                        stopEditingWhenGridLosesFocus= {true}
                                        rowHeight={40}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        // onCellEditingStopped={false}
                                        onCellClicked={this.onCellClicked}
                                        // onCellClicked={this.onCellClicked}
                                    //sideBar={sideBar}
                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                    />
                                </div>
                                <button type="button" className="btn btn-danger" onClick={this.onClickSaveData.bind(this)} >Save Data</button>
                            </div>

                        </div>
                        <div className={"dataLoadpage " + (this.state.loadshow)}>
                        </div>
                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>

                    </div>
                </div>
            </div>
        )
    }
}