import React, { useState, useEffect } from 'react';
import moment from 'moment';

const CountdownTimer = ({ expirationTime }) => {
  // Function to calculate the time left for a given expiration time
  const calculateTimeLeft = (expirationTime) => {
    const now = moment();
    const difference = moment(expirationTime).diff(now); // Calculate difference in time

    if (difference > 0) {
      return {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return null; // Time has expired
    }
  };

  // Initializing the state with the calculated time left
  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(expirationTime));

  useEffect(() => {
    // Update the time left every second
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(expirationTime);
      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [expirationTime]); // Run effect when expirationTime changes

  // If time has expired, show "End"
  const isTimeUp = !timeLeft;

  return (
    <span style={{ marginLeft: '5px' }}>
      {isTimeUp ? (
        <span>End</span>
      ) : (
        <span>{timeLeft.hours}h {timeLeft.minutes}m remaining</span>
      )}
    </span>
  );
};

export default CountdownTimer;
