
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Datetime from 'react-datetime';
import Modal from 'react-responsive-modal';
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import 'react-datetime/css/react-datetime.css';
import redirectURL from '../redirectURL';

// import { useParams } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import ExportIcon from "../../assets/icons/export-arrow.svg";
import ImportIcon from "../../assets/icons/import.svg";
import Filter from '../../assets/icons/SEARCH.png'
import CountdownTimer from './runningtime.js';
import IndentAuctionComponentforadmin from './RFQDashboardforadmin.js';
import IndentAuctionComponent1 from './RFQDashboard1.js';
import IndentAuctionComponent2 from './RFQDashboard2.js';
//  import io from 'socket.io-client';
import { getHyphenYYYYMMDDHHMM } from '../common/utils';
// import { FaEdit, FaTrash, FaEye, FaTrashAlt, FaArrowRight, FaCheck, FaPen, FaBell, FaRegBell } from 'react-icons/fa';

const unitsOptions = [
    { value: "truck", label: "FTL" },
    { value: "metric_tons", label: "PTL" },
    { value: "kilogram", label: "Courier" },
    { value: "box", label: "FTL/PTL" }]

var moment = require('moment');


var sourcearray = [
    "Mumbai", "Delhi", "Bangalore", "Hyderabad", "Ahmedabad",
    "Chennai", "Kolkata", "Pune", "Jaipur", "Lucknow",
    "Kanpur", "Nagpur", "Indore", "Thane", "Bhopal",
    "Visakhapatnam", "Pimpri-Chinchwad", "Patna", "Vadodara", "Ghaziabad",
    "Ludhiana", "Agra", "Nashik", "Faridabad", "Meerut",
    "Rajkot", "Kalyan-Dombivli", "Vasai-Virar", "Varanasi", "Srinagar",
    "Aurangabad", "Dhanbad", "Amritsar", "Navi Mumbai", "Allahabad",
    "Ranchi", "Howrah", "Coimbatore", "Jabalpur", "Gwalior",
    "Vijayawada", "Jodhpur", "Madurai", "Raipur", "Kota",
    "Guwahati", "Chandigarh", "Solapur", "Hubli-Dharwad", "Bareilly",
    "Moradabad", "Mysore", "Gurgaon", "Aligarh", "Jalandhar",
    "Tiruchirappalli", "Bhubaneswar", "Salem", "Mira-Bhayandar", "Thiruvananthapuram",
    "Bhiwandi", "Saharanpur", "Gorakhpur", "Guntur", "Amravati",
    "Bikaner", "Noida", "Jamshedpur", "Bhilai Nagar", "Warangal",
    "Cuttack", "Firozabad", "Kochi", "Bhavnagar", "Dehradun",
    "Durgapur", "Asansol", "Nanded-Waghala", "Kolhapur", "Ajmer",
    "Gulbarga", "Jamnagar", "Ujjain", "Loni", "Siliguri",
    "Jhansi", "Ulhasnagar", "Nellore", "Jammu", "Sangli-Miraj & Kupwad",
    "Belgaum", "Mangalore", "Ambattur", "Tirunelveli", "Malegaon",
    "Gaya", "Jalgaon", "Udaipur", "Maheshtala"
]

const customSelectStyles = {
    option: (provided) => ({
        ...provided,
        fontSize: '14px',
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '14px',
    }),
};


const IndentAuctionComponent = () => {
    // const { event_id } = useParams()
    const [open, setopen] = useState(false)
    const [openchat, setopenchat] = useState(false)
    const [open1, setopen1] = useState(false)
    const [selectedrowfornegotiate, setselectedrowfornegotiate] = useState({})
    const [openforadmin, setopenforadmin] = useState(false)
    const [baseprice, setbaseprice] = useState(0)
    const [selectedindent, setselectedindent] = useState({})
    const [draftdata, setdraftdata] = useState([])
    const [uploadpaymentterms, setuploadpaymentterms] = useState('')
    const [loadshow, setLoadshow] = useState("show-n")
    const [overlay, setOverlay] = useState("show-n")
    const [isToggled, setIsToggled] = useState(0);  // 0 for off, 1 for on
    const [show, setShow] = useState(false)
    const [hoveredIconId, setHoveredIconId] = useState(null);
    const [hidebidnowbtn, sethidebidnowbtn] = useState(false)
    var [filterToggle, setFilterToggle] = useState(false)
    const [basicType, setBasicType] = useState("")
    const [basicTitle, setBasicTitle] = useState("")
    const [newBidRate, setNewBidRate] = useState('');
    const [newBidQuantity, setNewBidQuantity] = useState('');
    const [activeDateBtn, setactiveDateBtn] = useState('')
    var filterCardStyle1 = { minHeight: "234px" };
    const [showTooltip, setShowTooltip] = useState(false);
    const [hoveredIcon, setHoveredIcon] = useState(null)
    var filterCardStyle2 = { padding: '20px' };
    var mnFilterClass = { marginLeft: "0px", padding: "0px" };
    const [negotiatedetails, setnegotiatedetails] = useState([])
    const [rfqform, setrfqform] = useState({
        active: 'activet1',
        awarded: '',
        drafts: '',
        cancelled: '',
        myactivity: '',
        archieved: '',
        upcoming: '',
        selectedindentid: '',
        completed: '',
        current_bidding: 'activet',
        bidding_history: '' // Optionally, clear other tabs

    })
    const [indentdetails, setindentdetails] = useState({
        indent_id: '',
        bid_start_date: '',
        end_date: '',
        source: '',
        destination: '', distance: '',
        truck_type: '',
        material: '',
        quantity: 0,
        base_price: 0,
        quote: 0
    })
    const [biddetails, setbiddetails] = useState([])
    const [allindents, setallindents] = useState([])
    const [bulkUploadModal, setBulkUploadModal] = useState({
        overly: "show-n",
        bulkPrioritySlide: ""
    })
    const [modalloadData, setmodalloadData] = useState([])
    const [filterdata, setfiltereddata] = useState([])
    const [originalindents, setoriginalindents] = useState([])
    const [rankingdetails, setrankingdetails] = useState([])
    const [selected_bid_price, setselected_bid_price] = useState('')
    const [negotiate_value, setnegotiate_value] = useState('')

    const [selected_transit_days, setselected_transit_days] = useState('')
    const [transit_time, settransit_time] = useState('')

    const [bidsdata, setbidsdata] = useState([])
    const [rankedBids, setRankedBids] = useState([]);
    const [searchfilter, setsearchfilter] = useState({})
    const [isCardOpen, setIscardOpen] = useState("")
    const [isCardOpen1, setIscardOpen1] = useState("")
    const [cardforadminbifurcation, setcardforadminbifurcation] = useState('')

    const [dataforadminbifur, setdataforadminbifur] = useState([])

    const [isCardOpen2, setIscardOpen2] = useState("")
    const [isCardOpen3, setisCardOpen3] = useState('')
    const [indent_for_approve, setindent_for_approve] = useState([]);

    const [indent_for_approve_for_bid, setindent_for_approve_for_bid] = useState([]);
    const [indent_for_approve_for_bid1, setindent_for_approve_for_bid1] = useState([]);

    const [indent_for_approve1, setindent_for_approve1] = useState([]);
    const [lr_no_data, setlr_no_data] = useState([]);

    const [selecteddata, setselecteddata] = useState({})
    const [showAllRecords, setShowAllRecords] = useState(false);

    const [bidHistory, setBidHistory] = useState([])
    const [inputTextValue, setInputTextValue] = useState("")
    const [inputTextQuantity, setInputTextQuantity] = useState("")
    const [negotiations, setNegotiations] = useState([])
    const [negotiationsHistory, setNegotiationsHistory] = useState([])
    const [isProposedInputOpen, setIsProposedInputOpen] = useState("")
    const [isVendorInputOpen, setIsVendorInputOpen] = useState("")
    const [additionalComments, setAdditionalComments] = useState("")
    const [modelForAdditionalComments, setModelForAdditionalComments] = useState(false)
    const [vendorQuotes, setVendorQoutes] = useState([])
    const [modelForShowBids, setModelForShowBids] = useState(false)
    const [modelForNotifications, setModelForNotifications] = useState(false)
    const [notificationUnread, setNotificationUnread] = useState([])
    const [notificationRead, setNotificationRead] = useState([])
    const [modelForEditAssigned, setModelForEditAssigned] = useState(false)
    const [rejectedVendorBid, setRejectedVendorBid] = useState("")
    const [rejectedVendorIndent, setRejectedVendorIndent] = useState("")
    const [rejectionReason, setRejectionReason] = useState("")
    const [isPreviewMode, setIsPreviewMode] = useState(false)
    const [rfqForm, setRfqForm] = useState({})
    const [dealers, setDealers] = useState([
        {
            dealer_code: "",
            dealer_name: "",
            dealer_city: "",
            dealer_state: "",
            dealer_pincode: "",
            materials: [
                { material_type: "", material_length: 0, material_breath: 0, material_height: 0, material_units: "cm", quantity: 0, basic_requirment: '' },
            ],
        },
    ]);

    const [timeLeft, setTimeLeft] = useState(null);
    const [text, setText] = useState('');
    var [destinationdetails, setdestinationdetails] = useState([])

    const calculateTimeLeft = (expirationTime) => {
        const now = moment();
        const difference = moment(expirationTime).diff(now);

        if (difference > 0) {
            return {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            return null;
        }
    };
    // useEffect(() => {
    //     const timer = setInterval(() => {
    //       allindents.forEach((indent, index) => {
    //         const expirationTime = moment
    //           .parseZone(indent.bid_start_date)
    //           .utcOffset("-05:30")
    //           .add(parseInt(indent.quotation_validity_hours), 'hours')
    //           .toISOString();

    //         const newTimeLeft = calculateTimeLeft(expirationTime);

    //         setTimeLeft(newTimeLeft);

    //         if (!newTimeLeft) {
    //           setText('End');
    //         } else {
    //           setText(`${newTimeLeft.hours}h ${newTimeLeft.minutes}m ${newTimeLeft.seconds}s remaining`);
    //         }
    //       });
    //     }, 1000);

    //     return () => clearInterval(timer);
    //   }, [allindents]);  
    useEffect(() => {
        // const fetchData = (active) => {
        //     fetchInitialData()
        //     getdraftdata()
        // };
        // fetchData();

    }, []);
    // const rejectindent = ()=>{
    //     var params = {
    //         user_type: localStorage.getItem("user_type"),
    //         email: localStorage.getItem("email")
    //     }
    //     redirectURL.post("/consignments/fetchRFQsForDashboard", params).then(response => {
    //         var data = response.data;
    //         setoriginalindents(data);
    //     })

    // }

    const getdraftdata = () => {

    }
   

    // const socket = React.useRef(null);

    // useEffect(() => {
    //     socket.current = io('http://34.131.191.3:5006', {
    //         transports: ['websocket'], // Ensure WebSocket transport is used
    //         reconnectionAttempts: 5,    // Retry connecting a few times
    //         reconnectionDelay: 1000,    // Optional: delay between retries
    //     });

    //     // Listen for connect event
    //     socket.current.on('connect', () => {
    //         console.log('Connected to the server');
    //     });

    //     // Listen for connection errors
    //     socket.current.on('connect_error', (error) => {
    //         console.error('Connection Error:', error);
    //     });

    //     // Listen for new bid updates
    //     socket.current.on('newBid', (data) => {
    //         console.log(selectedindent, "test_190")
    //         fetchInitialData()
    //         if (selectedindent.indent_id) {
    //             getbidhistory(selectedindent.indent_id);
    //         }
    //     });

    //     // Cleanup function to disconnect the socket when the component is unmounted
    //     return () => {
    //         socket.current.disconnect();
    //         socket.current.off('newBid');
    //     };
    // }, [selectedindent]);

    const resetindent = () => {
        // fetchInitialData()
        // if (selectedindent.indent_id) {
        //     getbidhistory(selectedindent.indent_id);
        // }
    }



    const getbidhistory = async (indent_id) => {
        console.log(indent_id)
        await redirectURL.post("/consignments/getBidHistory", { indent_id }).then(response => {
            setBidHistory(response.data.bid_history)
            setNegotiations(response.data.negotiations)
            setNegotiationsHistory(response.data.negotiationslogs)
        })
    }

    const onClickBidNow = async (e) => {

        setIscardOpen(prev => {
            if (prev == e.indent_id) {
                setselectedindent({})
                return ""
            } else {
                getbidhistory(e.indent_id)
                setselectedindent(e)

                return e.indent_id

            }
        })
        var data = originalindents.filter(f => f.indent_id == e.indent_id)
        console.log(data, '371')
        console.log(data[0].destination, 'data[0].destination')
        var destinations = []
        var data1 = data[0].destination
        console.log(data1, 'data1')
        data1 = JSON.parse(data1);
        console.log(data1, 'data1')
        data1.map((g) => {
            destinations.push({
                "indent_id": e.indent_id,
                "dealer_city": g.dealer_city,
                'dealer_pincode': g.dealer_pincode,
                "dealer_code": g.dealer_code,
                //    "materials": g.materials.map(material => ({
                //     "material_type": material.material_type,
                //     "material_dimensions": `${material.material_length} x ${material.material_breath} x ${material.material_height}`,
                //     "quantity": material.quantity
                // })),
                "quantity": g.quantity,

            })
        })
        var params = { "indent_id": e.indent_id, 'vendor_email_id': localStorage.getItem('email') }
        redirectURL.post('/consignments/getstroreddata', params).then((resp) => {
            var transit_data = resp.data; // Response data

            transit_data.forEach((transitRecord) => {
                // Find matching record in destinations
                const destinationRecord = destinations.find(
                    (dest) =>
                        dest.indent_id === transitRecord.indent_id &&
                        dest.dealer_city === transitRecord.dealer_city
                );
                if (destinationRecord) {
                    // Add bid_price from transit_data to destinations
                    destinationRecord.bid_price = transitRecord.bid_price;
                    destinationRecord.transit_days = transitRecord.transit_days;
                }
            });

            console.log(destinations); // Updated destinations array
        });

        setindent_for_approve_for_bid(destinations)
        setindent_for_approve_for_bid1(destinations)
        console.log(destinations, 'destinations382')
        setInputTextValue("")
        setInputTextQuantity("")
        if (e.indent_assigned == 1) {
            onShowdata(e.indent_id)
        }
        var params = { 'indent_id': e.indent_id }
        redirectURL.post('/consignments/getlr_data', params).then((resp) => {
            var data = resp.data
            if (data != '' && data != undefined) {
                data = data.filter(f => f.lr_no != '' && f.lr_no != undefined)
                if (localStorage.getItem('roles') == 'TRANSPORTER') {
                    data = data.filter(f => f.vendor_email_id == localStorage.getItem('email'))
                    setlr_no_data(data)
                }
                else {
                    setlr_no_data(data)
                }
            }
        })
        var params = {
            indent_id: e.indent_id,
            vendor_email_id: localStorage.getItem('email')
        }
        redirectURL.post('/consignments/getstroreddata', params).then((resp) => {
            var data1 = resp.data
            var destinations = []
            data1.map((f) => {
                destinations.push({
                    "indent_id": e.indent_id,
                    "dealer_city": f.dealer_city,
                    'dealer_pincode': f.dealer_pincode,
                    "bid_price": f.bid_price,
                    "transit_days": f.transit_days,
                    'material': f.material,

                })
            })
            console.log(destinations, '432')
            setindent_for_approve1(destinations)

        })

    }
    const changeFileHandler = (e) => {
        const formData = new FormData()
        formData.append("fileUpload", e.target.files[0])

    }

    const closeBulkUploadModal = () => {
        setBulkUploadModal({
            overly: "show-n",
            bulkPrioritySlide: ""
        })
        setOverlay("show-n")
    }
    const onClickHideall = () => {
        setLoadshow('show-m')
        setOverlay('show-n')

    }
    const onShowdealerdata = (indent_id, email) => {
        console.log(indent_id, 'indent_id')
        setisCardOpen3(true)
        var params = {
            indent_id: indent_id,
            vendor_email_id: email
        }
        redirectURL.post('/consignments/getstroreddata', params).then((resp) => {
            var data1 = resp.data
            var destinations = []
            data1.map((e) => {
                destinations.push({
                    "indent_id": indent_id,
                    "dealer_city": e.dealer_city,
                    'dealer_pincode': e.dealer_pincode,
                    "bid_price": e.bid_price,
                    "transit_days": e.transit_days,
                    'material': e.material,

                })
            })
            setindent_for_approve1(destinations)

        })
        redirectURL.post('/consignments/getlr_data', params).then((resp) => {
            var data = resp.data
            if (data != '' && data != undefined) {
                data = data.filter(f => f.lr_no != '' && f.lr_no != undefined)
                if (localStorage.getItem('roles') == 'TRANSPORTER') {
                    data = data.filter(f => f.vendor_email_id == localStorage.getItem('email'))
                    setlr_no_data(data)
                }
                else {
                    setlr_no_data(data)
                }
            }
        })

    }
    const onClickAcceptNegotiation = (indent_id, bid_price, no_of_deliveries, vendor_email_id, indent_created_by) => {
        const params = {
            indent_id,
            bid_price,
            no_of_deliveries,
            vendor_email_id,
            email: localStorage.getItem("email"),
            user_type: localStorage.getItem("user_type"),
            indent_created_by
        };

        redirectURL.post("/consignments/acceptNegotiationValue", params).then(response => {
            const isSuccess = response.data.status === "success";
            setShow(true);
            setBasicType(isSuccess ? "success" : "danger");
            setBasicTitle(response.data.message);

            if (isSuccess) {
                setIsVendorInputOpen("");
                setIsProposedInputOpen("");
                // socket.current.emit('bidUpdate', []);
            }
        }).catch(error => {
            console.log(error)
            setShow(true);
            setBasicType("danger");
            setBasicTitle("An error occurred while processing the request.");
        });
        // if (localStorage.getItem('roles') == 'TRANSPORTER') {
        //     // setIscardOpen1(true)
        //     var data = originalindents.filter(e => e.indent_id == indent_id)
        //     console.log(data, '371')
        //     console.log(data[0].destination, 'data[0].destination')
        //     var destinations = []
        //     var data1 = data[0].destination
        //     data1 = JSON.parse(data1);

        //     data1.map((e) => {
        //         destinations.push({
        //             "indent_id": indent_id,
        //             "dealer_city": e.dealer_city,
        //             "dealer_pincode": e.dealer_pincode,
        //             "material": `${e.material} (${e.material_length} x ${e.material_breath} x ${e.material_height}) - Qty: ${e.quantity}`
        //         });
        //     });

        //     setindent_for_approve(destinations)
        //     setselecteddata({
        //         indent_id: indent_id,
        //         bid_price: bid_price,
        //         no_of_deliveries: no_of_deliveries,
        //         vendor_email_id: vendor_email_id,
        //         indent_created_by: indent_created_by
        //     });
        // }
        // else if (localStorage.getItem('roles') == 'AL_ADMIN') {
        //     const params = {
        //         indent_id,
        //         bid_price,
        //         no_of_deliveries,
        //         vendor_email_id,
        //         email: localStorage.getItem("email"),
        //         user_type: localStorage.getItem("user_type"),
        //         indent_created_by
        //     };

        //     redirectURL.post("/consignments/acceptNegotiationValue", params).then(response => {
        //         const isSuccess = response.data.status === "success";
        //         setShow(true);
        //         setBasicType(isSuccess ? "success" : "danger");
        //         setBasicTitle(response.data.message);

        //         if (isSuccess) {
        //             setIsVendorInputOpen("");
        //             setIsProposedInputOpen("");
        //             // socket.current.emit('bidUpdate', []);
        //         }
        //     }).catch(error => {
        //         console.log(error)
        //         setShow(true);
        //         setBasicType("danger");
        //         setBasicTitle("An error occurred while processing the request.");
        //     });
        // }
    };
    const onShowdata = (indent_id) => {
        // setIscardOpen2(true)
        var params = {
            indent_id: indent_id,
            vendor_email_id: localStorage.getItem('email')
        }
        redirectURL.post('/consignments/getstroreddata', params).then((resp) => {
            var data1 = resp.data
            var destinations = []
            data1.map((e) => {
                destinations.push({
                    "indent_id": indent_id,
                    "dealer_city": e.dealer_city,
                    'dealer_pincode': e.dealer_pincode,
                    "bid_price": e.bid_price,
                    "transit_days": e.transit_days,
                    'material': e.material
                })
            })
            setindent_for_approve1(destinations)

        })

    }

    const onClickAcceptQuotation = (indent_id, bid_price, no_of_deliveries, vendor_email_id, indent_created_by, indent, vendorValue) => {
        if (localStorage.getItem('roles') == 'AL_ADMIN') {
            console.log(vendorValue, '643')
            setcardforadminbifurcation(true)
            var data = originalindents.filter(f => f.indent_id == indent_id)

            var destinations = []
            var data1 = data[0].destination
            data1 = JSON.parse(data1);

            data1.map((g) => {
                destinations.push({
                    "indent_id": indent_id,
                    "dealer_city": g.dealer_city,
                    "dealer_state": g.dealer_state,
                    'dealer_pincode': g.dealer_pincode,
                    "dealer_name": g.dealer_name,
                    "dealer_code": g.dealer_code,
                    "materials": g.materials.map(material => ({
                        "material_type": material.material_type,
                        "material_dimensions": `${material.material_length} x ${material.material_breath} x ${material.material_height}`,
                        "quantity": material.quantity
                    })),
                    "quantity": g.quantity,

                })
            })

            setdataforadminbifur(destinations)
            const params = {
                indent_id,
                bid_price: vendorValue,
                no_of_deliveries,
                vendor_email_id,
                email: localStorage.getItem("email"),
                user_type: localStorage.getItem("user_type"),
                indent_created_by
            };
            console.log(params, 'params')
            redirectURL.post("/consignments/AcceptQuotationwithadmin", params).then(response => {
                const isSuccess = response.data.status === "success";
                // setShow(true);
                // setBasicType(isSuccess ? "success" : "danger");
                // setBasicTitle(response.data.message);

                if (isSuccess) {
                    setIsVendorInputOpen("");
                    setIsProposedInputOpen("");
                    setIscardOpen1(false)
                    //  socket.current.emit('bidUpdate', []);
                }
            })
                .catch(error => {
                    console.log(error)
                    setShow(true);
                    setBasicType("danger");
                    setBasicTitle("An error occurred while processing the request.");
                });
        }

    };
    const onCloseModal = () => {
        setModelForAdditionalComments(false)
        setModelForShowBids(false)
        setModelForNotifications(false)
        setModelForEditAssigned(false)
        setIscardOpen1(false)
        setIscardOpen2(false)
        setisCardOpen3(false)
        setcardforadminbifurcation(false)
    };
    const fetchNotificationsData = (indent_id) => {
        var params = {
            email: localStorage.getItem("email"),
            indent_id: indent_id
        }
        redirectURL.post("/consignments/fetchNotificationsData", params).then(response => {
            setNotificationUnread(response.data.Unreadnotifications)
            setNotificationRead(response.data.Readnotifications)
            setModelForNotifications(true)
            const indent = allindents.find(e => e.indent_id === indent_id);
            if (indent) {
                indent.notification_count = 0;
            }
        })
    }


    const modalStyles = {
        width: '500px !important',
    }
    const modalStyles1 = {
        width: '300px !important',
    }
    const columnwithdefs = [
        {
            headerName: "Rank",
            field: "truck_no",
            width: 200,
            resizable: true,
        },
        {
            headerName: "Bid Price",
            field: "truck_no",
            width: 100,
            resizable: true,
        },
        {
            headerName: "Bid Quantity",
            field: "truck_no",
            width: 100,
            resizable: true,
        },
    ]

    const handleSelectChange = (name, selectedOption) => {
        // Check if selectedOption is not null before accessing value
        setsearchfilter({
            ...searchfilter,
            [name]: selectedOption ? selectedOption.value : null
        });
    };
    const resetSearchFilter = () => {

        if (rfqform.active === 'activet') {
            setallindents(originalindents.filter(e => e.indent_expired != 1));
        }
        else if (rfqform.cancelled === 'activet') {
            setallindents(originalindents.filter(e => e.indent_expired == 1));
        }
        // else if (rfqform.drafts === 'activet') {
        //     setallindents(originalindents.filter(e => e.is_active == 0));
        // }

        setsearchfilter({})

    }

    const getindents = (e) => {
        e.preventDefault();
        let data = []
        if (rfqform.active === 'activet') {
            data = originalindents.filter(e => e.indent_expired != 1)
        }
        else if (rfqform.cancelled === 'activet') {
            data = originalindents.filter(e => e.indent_expired == 1)
        }
        // else if (rfqform.drafts === 'activet') {
        //     data = originalindents.filter(e => e.is_active == 0)
        // }


        // Assuming searchfilter is an object with properties to filter by
        const filterdata = data.filter(indent => {
            return Object.keys(searchfilter).every(key => {
                // Check if the searchfilter value is not empty and matches the indent's corresponding value
                return !searchfilter[key] || indent[key] == searchfilter[key];
            });
        });
        // Now you can set the filtered data or perform other actions with filterdata
        setallindents(filterdata);
    };
    const handleInputChangeforbid = (index, field, value) => {
        const updatedDestinations = [...indent_for_approve_for_bid];
        updatedDestinations[index][field] = value; // Update the specific field
        setindent_for_approve_for_bid(updatedDestinations); // Update state
    };
    const handleInputChangeforaccept = (index, field, value) => {
        const updatedDestinations = [...indent_for_approve];
        updatedDestinations[index][field] = value; // Update the specific field
        setindent_for_approve(updatedDestinations); // Update state
    };
    const handleInputChangeforaccept1 = (index, field, value) => {
        const updatedDestinations = [...indent_for_approve1];
        updatedDestinations[index][field] = value; // Update the specific field
        setindent_for_approve1(updatedDestinations); // Update state
    };
    const handleInputChangeforadmin_bifur = (index, field, value) => {
        const updatedDestinations = [...dataforadminbifur];
        updatedDestinations[index][field] = value; // Update the specific field
        setdataforadminbifur(updatedDestinations); // Update state
    };
    const onSubmitdataforadminbifurcation = (e) => {
        console.log(dataforadminbifur, 'dataforadminbifur')
        var params = dataforadminbifur
        redirectURL.post('/consignments/storeadminbifur', params).then((resp) => {
            setShow(true);
            setBasicType("success");
            setBasicTitle('Submitted Successfully');
            setcardforadminbifurcation(false)
        })

    }
    const onSubmitdata = (e) => {
        const hasInvalidFields = indent_for_approve.some(
            (destination) =>
                !destination.bid_price || !destination.transit_days
        );

        if (hasInvalidFields) {
            alert("Please fill in all Bid Price and Transit Days fields before submitting.");
            return;
        }
        const submissionData = indent_for_approve.map((destination) => ({
            indent_id: destination.indent_id,
            dealer_city: destination.dealer_city,
            dealer_pincode: destination.dealer_pincode,
            bid_price: destination.bid_price,
            transit_days: destination.transit_days,
            material: destination.material,
            'vendor_email_id': localStorage.getItem('email'),
            'vendor_name': localStorage.getItem('firstname')
        }));
        console.log("Prepared submission data:", submissionData);
        var params1 = {
            submissionData: submissionData
        }
        redirectURL.post('/consignments/storedata', params1).then((resp) => {

        })
        var { indent_id, bid_price, no_of_deliveries, vendor_email_id, indent_created_by } = selecteddata
        const params = {
            indent_id,
            bid_price,
            no_of_deliveries,
            vendor_email_id,
            email: localStorage.getItem("email"),
            user_type: localStorage.getItem("user_type"),
            indent_created_by
        };

        redirectURL.post("/consignments/acceptNegotiationValue", params).then(response => {
            const isSuccess = response.data.status === "success";
            setShow(true);
            setBasicType(isSuccess ? "success" : "danger");
            setBasicTitle(response.data.message);
            setIscardOpen1(false)

            if (isSuccess) {
                setIsVendorInputOpen("");
                setIsProposedInputOpen("");
                // socket.current.emit('bidUpdate', []);
            }
        }).catch(error => {
            console.log(error)
            setShow(true);
            setBasicType("danger");
            setBasicTitle("An error occurred while processing the request.");
        });



    }
    const onshowPreview = (e) => {
        console.log('832', e)
        setIsPreviewMode(true)
        setRfqForm(e)
        const destinationDetails = JSON.parse(e.destination || '[]');
        setDealers(destinationDetails);

    }
    const onSubmitdata1 = (e) => {
        const hasInvalidFields = indent_for_approve.some(
            (destination) =>
                !destination.bid_price || !destination.lr_no
        );

        if (hasInvalidFields) {
            alert("Please fill LR No field before submitting");
            return;
        }
        const submissionData = indent_for_approve1.map((destination) => ({
            indent_id: destination.indent_id,
            dealer_city: destination.dealer_city,
            dealer_pincode: destination.dealer_pincode,
            bid_price: destination.bid_price,
            transit_days: destination.transit_days,
            lr_no: destination.lr_no,
            material: destination.material
        }));
        console.log("Prepared submission data:", submissionData);
        var params1 = {
            submissionData: submissionData
        }
        redirectURL.post('/consignments/storelrno', params1).then((resp) => {
            if (resp.data == 'success') {
                setShow(true);
                setBasicType("success");
                setBasicTitle('Successfully Submitted');
                setIscardOpen2(false)
            }

        })
            .catch(error => {
                console.log(error)
                setShow(true);
                setBasicType("danger");
                setBasicTitle("An error occurred while processing the request.");
            });


    }

    const handleInputChange = (e) => {
        setselected_transit_days(e)
        // setNewBidQuantity(e)
    };
    const handlertransittime = (e) => {
        settransit_time(e)
    }
    const handleInputChange1 = (e) => {
        setselected_bid_price(e)
        // setNewBidRate(e)
    };
    const handleInputChange2 = (e) => {
        setnegotiate_value(e)
    };

    const onSubmitquote = (e) => {
        e.preventDefault()
        // var selected_bid_price = 0
        // var selected_bid_quantity = selectedindent.destination
        // selected_bid_quantity = JSON.parse(selected_bid_quantity)
        // console.log(selected_bid_quantity.length, 'selected_bid_quantityselected_bid_quantity')
        // var transit_time = 0
        // indent_for_approve_for_bid.forEach(item => {
        //     selected_bid_price += parseFloat(item.bid_price); // Sum the bid prices
        //     transit_time += parseInt(item.transit_days); // Sum the transit days
        // });

        // var params = {
        //     bid_price: selected_bid_price,
        //     no_of_deliveries: selected_bid_quantity.length,
        //     email: localStorage.getItem('email'),
        //     indent_id: selectedindent.indent_id,
        //     username: localStorage.getItem('user_name'),
        //     transit_time: transit_time
        // }
        var params = {
            bid_price: selected_bid_price,
            transit_time: selected_transit_days,
            email: localStorage.getItem('email'),
            indent_id: selectedindent.indent_id,
            username: localStorage.getItem('user_name')
        }
        redirectURL.post('consignments/submitQuote', params).then((response) => {
            if (response.data.status == 'success') {
                setIscardOpen("")
                setShow(true)
                setBasicType("success")
                setBasicTitle('Your Bid has been successfully placed')
                setselected_bid_price("")
                setselected_transit_days("")
                // if (response.data.status == "success") socket.current.emit('bidUpdate', []);
                // setopen(false)
                // fetchgetindents()
            }
        })
        const submissionData = indent_for_approve_for_bid.map((destination) => ({
            indent_id: selectedindent.indent_id,
            dealer_city: destination.dealer_city,
            dealer_pincode: destination.dealer_pincode,
            bid_price: destination.bid_price,
            transit_days: destination.transit_days,
            material: destination.material,
            'vendor_email_id': localStorage.getItem('email'),
            'vendor_name': localStorage.getItem('firstname')
        }));
        var params3 = { submissionData: submissionData, indent_id: selectedindent.indent_id }
        redirectURL.post('/consignments/updatebidprice', params3).then((resp) => {

        })

        console.log("Prepared submission data:", submissionData);
        var params1 = {
            submissionData: submissionData
        }
        redirectURL.post('/consignments/storedata', params1).then((resp) => {

        })


    }

    const closeAlert = () => {
        setShow(false)
        setopen(false)
    }

    const onIconHover = (rowId) => {
        setHoveredIconId(rowId); // Set the hovered icon ID to the specific row ID
    };
    const onClickparentTab = (e) => {
        var current_date = new Date()
        let data = []
        if (e === 'Expired') {
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: 'activet1',
                active: '',
                completed: '',
                awarded: '',
                rejected: '',
                drafts: '' // Optionally, clear other tabs
            }));

            data = []
            if (localStorage.getItem('user_type') === 'TRANSPORTER') {
                data = originalindents.filter((e) => {
                    // Check if `vendorquotes` exists and is an array
                    if (e.vendorquotes && Array.isArray(e.vendorquotes)) {
                        // Filter `vendorquotes` to find entries that match the email and have `bid_winner` set to 0
                        const vendorquote = e.vendorquotes.find(
                            (quote) =>
                                quote.vendor_email_id === localStorage.getItem('email') &&
                                quote.bid_winner === 0
                        );
                        // Return `true` if a matching vendor quote is found, else `false`
                        return vendorquote !== undefined;
                    }
                    return false; // If `vendorquotes` is empty or not an array, exclude this indent
                });
            }

            //  data = originalindents.filter(e => {
            //     const bidEndDate = moment.parseZone(e.bid_start_date)
            //         .utcOffset("-05:30")
            //         .add(parseInt(e.quotation_validity_hours, 10), 'hours');

            //     return current_date < bidEndDate && (e.vendordetails && e.vendordetails.length === 0);
            // });

        }
        if (e === 'Awarded') {
            setrfqform((prevForm) => ({
                ...prevForm,
                awarded: 'activet1',
                cancelled: '',
                active: '',
                completed: '',
                rejected: '',
                drafts: '' // Optionally, clear other tabs
            }));
            data = []
            if (localStorage.getItem('user_type') === 'TRANSPORTER') {
                // data = originalindents.filter((e) => {
                //     // Check if `vendorquotes` exists and is an array
                //     if (e.vendorquotes && Array.isArray(e.vendorquotes)) {
                //         // Filter `vendorquotes` to find entries that match the email and have `bid_winner` set to 0
                //         const vendorquote = e.vendorquotes.find(
                //             (quote) =>
                //                 quote.vendor_email_id === localStorage.getItem('email') &&
                //                 quote.bid_winner === 1
                //         );
                //         // Return `true` if a matching vendor quote is found, else `false`
                //         return vendorquote !== undefined;
                //     }
                //     return false; // If `vendorquotes` is empty or not an array, exclude this indent
                // });
                data = originalindents.filter(e=>e.accepted_vendor==localStorage.getItem('email'))
            }
            else {
                data = originalindents.filter(e => e.indent_assigned == 1)
            }

        }
        else if (e === 'Active') {
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: '',
                completed: '',
                awarded: '',
                rejected: '',
                active: 'activet1',
                drafts: '' // Optionally, clear other tabs
            }));
            // data = originalindents
            data = originalindents.filter(function (e) {
                return e.vendordetails && e.vendordetails.length > 0 && e.indent_assigned != 1 && e.validity_end != 1 && e.rejected != 1 && e.rejected != 1;
            });
        }
        else if (e === 'Completed') {
            console.log('1019')
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: '',
                completed: 'activet1',
                awarded: '',
                rejected: '',
                active: '',
                drafts: '' // Optionally, clear other tabs
            }));
            data = originalindents.filter(e => e.validity_end == 1)
        }
        else if (e === 'rejected') {
            console.log('1019')
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: '',
                rejected: 'activet1',
                completed: '',
                awarded: '',
                active: '',
                drafts: '' // Optionally, clear other tabs
            }));
            console.log(originalindents, '1142')
            data = originalindents.filter(e => e.rejected == 1 || e.rejectedwithapproval1 == 1)
        }
        else if (e === 'Drafts') {
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: '',
                active: '',
                rejected: '',
                drafts: 'activet1' // Optionally, clear other tabs
            }));
            console.log(draftdata, '1063')
            data = draftdata
        }
        const filterdata = data.filter(indent => {
            return Object.keys(searchfilter).every(key => {
                // Check if the searchfilter value is not empty and matches the indent's corresponding value
                return !searchfilter[key] || indent[key] === searchfilter[key];
            });
        });
        setallindents(data)
    };
    const onClickViewPOD = (e) => {
        // console.log("documnent_view", e)

        let params = {
            pod_document: e,
        }
        // console.log("params",params)
        redirectURL.post("/billing/downloadfileforroad", params, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
            }
        }).then(res => {
            console.log(res, '652')
            const downloadUrl = decodeURIComponent(res.data.url);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", e || ""); // Optional: specify download filename
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        }).catch(function (error) {
            // console.log(error);
        })
    }
    const onClickcreateindent = () => {
        window.location.href = "/createindent";
    }
    const onClickOpenBulkUploadModal = (e) => {
        setOverlay("show-m")
        setBulkUploadModal({
            bulkPrioritySlide: "slider-translate-84p"
        })
        setOverlay('show-m')
    }
    const submitNegotiateValue = (indent_id, vendor_email_id, indent_created_by) => {
        const params = {
            user_type: localStorage.getItem("user_type"),
            email: localStorage.getItem("email"),
            indent_id,
            negotiated_value: inputTextValue,
            negotiated_quantity: inputTextQuantity,
            vendor_email_id,
            indent_created_by
        };
        redirectURL.post("/consignments/submitNegotiateValue", params)
            .then(response => {
                const { status, message } = response.data;

                setShow(true);
                setBasicType(status === 'success' ? 'success' : 'danger');
                setBasicTitle(message);


                if (status === "success") {
                    setIsProposedInputOpen("");
                    setIsVendorInputOpen("");
                    // socket.current.emit('bidUpdate', []);
                }
            })
            .catch(error => {
                console.log(error, '600')
                setShow(true);
                setBasicType("danger");
                setBasicTitle("An error occurred while submitting the negotiation.");
            });
    };
    const columnDef = []
    console.log(bulkUploadModal, 'isVendorInputOpen')
    const toggleShowAllRecords = () => {
        setShowAllRecords((prev) => !prev);
    };

    console.log(isProposedInputOpen, 'isProposedInputOpen')
    return (
        <>
        {localStorage.getItem('email')=='gowsigan@ashokleyland.com'||localStorage.getItem('user_type')=='TRANSPORTER'?<IndentAuctionComponentforadmin/>:''}
        {localStorage.getItem('email')=='Danish.dheer@ashokleyland.com'?<IndentAuctionComponent2/>:''}

        {/* {localStorage.getItem('email')=='gowsigan@ashokleyland.com'?<IndentAuctionComponent1/>:''} */}

        </>
    );
};

export default IndentAuctionComponent;

function giveUTCDate(date) {
    return moment.parseZone(new Date(date)).utcOffset("-05:30")._d
}
const generateUniqueId = () => {
    return 'icon-' + Math.random().toString(36).substr(2, 9); // Generate a simple unique ID
};