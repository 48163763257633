import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');
var highchartoptions;
var criticaldata=[]

export default class PRTOnTimeChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showgrid:'display:none',
            showtransgrid:'display:none',
            rowdata:[],
            test:"TEstng",
            drilldowndata:[]
        }

    }
    componentDidMount = async () =>{
       
        $(".grid-"+this.props.code).hide();
        $(".grid-trans-"+this.props.code).hide();
        $(".dataLoadpagesum"+this.props.code).hide()
        $(".dataLoadpageimgsum"+this.props.code).hide()
        $(".overlay-partsum"+this.props.code).hide()
       
    }
    componentWillReceiveProps (newprops){
        //if(newprops.title != this.props.dept_code)
        //{
            this.setState({
                drilldowndata:newprops.drildown
            })
            //console.log("newprops ",newprops);
        //}

        if(this.props.isfilter == "yes")
        {
            console.log("Yes")
            // highchartoptions.update({
            //  drilldown: {
            //      series: this.props.drildown
            //  }
            // });
            // var ddCurrent = highchartoptions.series[0].userOptions.id;
            // var ddSeries = highchartoptions.options.drilldown.series;
            // if (ddCurrent === undefined) {
            //  highchartoptions.series[0].setData(highchartoptions.options.series[0].data);
            // } else {
            //  for (var i = 0, ie = ddSeries.length; i < ie; ++i) {
            //      if (ddSeries[i].id === ddCurrent) {
            //          highchartoptions.series[0].setData(ddSeries[i].data);
            //      }
            //  }
            // }
            // console.log("highchartoptions ", highchartoptions)
        }
       
    }
    hideGrid(params){
        //console.log(params)
        hideGrid(params)
    }
    hideTransGrid(params){
        hideTransGrid(params)
    }
   
    render(){
        //console.log("this.props.code) ", this.props.code)
    //console.log("drildown ", this.state.drilldowndata)
    //console.log("props drildown ", this.props.drildown)
        var isfilter = this.props.isfilter;
        var drillseries = this.props.drildown;
        const dStyles={
                width:'100%',
                height:'100%'
        }
        highchartoptions = {
            chart: {
                type: 'column',
               
                // events: {
                //  drilldown: function (e) {
                //      if(isfilter == 'yes')
                //      {
                //          this.chart.update({ drilldown: { series: drillseries } });

                //          var ddCurrent = this.chart.series[0].userOptions.id; // current drill
                //          var ddSeries = this.chart.options.drilldown.series; // all drill series
   
                //          if (ddCurrent === undefined) {
                //              // update view with root series, if there is no drill
                //              this.chart.series[0].setData(this.chart.options.series[0].data);
                //          } else {
                //              // update view with drill series
                //              for (var i = 0, ie = ddSeries.length; i < ie; ++i) {
                //                  if (ddSeries[i].id === ddCurrent) {
                //                      this.chart.series[0].setData(ddSeries[i].data);
                //                  }
                //              }
                //          }
                //      }
                       
       
                //  }
                // }
            },
            title: {
                text: this.props.title,
                //align:'left'
            },
            subtitle: {
                text: this.props.subtitle!= "" ?this.props.subtitle:this.state.chartTitle,
                style: {
                    color: '#333', // text color
                    fontSize: '14px', // font size
                    fontWeight: 'bold', // font weight
                    
                    // Add other styles as needed
                },
                y:this.props.subtitle!= "" ? 200 : 54
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category',
                title:{
                    text:this.props.xaxistitle
                }
            },
            yAxis: {
                title: {
                    text: this.props.yaxistitle
                }
       
            },
            legend: {
                enabled: false
            },

            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        // format: '{point.y}%',
                        formatter: function () {
                            if (this.point.showTooltip == 1) {
                                return this.y;
                            } else {
                                return this.y + '%';
                            }
                        },
                    }
                }
            },
       
            tooltip: {
                formatter: function () {
                    if (this.point.showTooltip == 1) {
                        return "<span style='font-size:11px'>" + this.point.name + "</span><br>\
                                <span style='color:" + this.point.color + "'>Total: </span>: <b>" + this.point.y + "</b>";
                    } else {
                        return "<span style='font-size:11px'>" + this.point.name + "</span><br>\
                                <span style='color:" + this.point.color + "'>Total: </span>: <b>" + this.point.total_records + "</b><br>\
                                <span style='color:" + this.point.color + "'>On Time: </span>: <b>" + this.point.on_time_records + "</b><br>\
                                <span style='color:" + this.point.color + "'>" + this.point.name + "</span>: <b>" + this.point.y + "%</b>";
                    }
                }
            },
       
            series: this.props.series,
            drilldown: {
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true
                        },
                        cropThreshold: 500
                    }
                },
                series: this.props.drilldown
                //series:this.state.drilldowndata
                //series:[]
            },
            credits:false
        };
       
        return (
            <div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
            </div>
            );
        }
}


function hideGrid(id)
{
    console.log("id ",id)
    $(".grid-"+id).fadeOut()
}

function hideTransGrid(id)
{
    console.log("id ",id)
    $(".gridtrans-"+id).fadeOut()
}


function GetSortOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  }

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function arrayMin(arr) {
    return arr.reduce(function (p, v) {
      return ( p < v ? p : v );
    });
  }
 
  function arrayMax(arr) {
    return arr.reduce(function (p, v) {
      return ( p > v ? p : v );
    });
  }

// function getData(){
//  alert("cons")
// }

$(document).ready(function(){
   
})

$(document).on('click',".clicktransport", function(){
    var criticaldata=[];
    var transporter = $(this).attr("transporter")
    var deptcode = $(this).attr("deptcode");
    var transporterdata = $(this).attr("data")
    $(".dataLoadpagesum"+deptcode).show()
    $(".dataLoadpageimgsum"+deptcode).show()        
    $(".overlay-partsum"+deptcode).show()  
    var dept_code = $(this).attr("dept_code");
    var rqparams={
        dept_code:dept_code
    }
    //console.log("dept_code ",dept_code);
    if(dept_code == "LOG-PRT (Courier)")
    {
        var blocktitle='Consignment Code';
    }
    else{
        var blocktitle='Truck No';
    }
    redirectURL.post("/consignments/summarysecondleveldata",rqparams)
    .then(async (res) =>{
        criticaldata=res.data.message[0].critical_data;
        $(".dataLoadpagesum"+deptcode).hide()
        $(".dataLoadpageimgsum"+deptcode).hide()
        $(".overlay-partsum"+deptcode).hide();  
        var criticaldataarr=[]
        if(criticaldata.length > 0)
        {
            criticaldata.map((item) =>{
                if(dept_code == 'LOG-SC')
                {
                    criticaldataarr.push({
                        container_no:item.container_no,
                        country_code:item.country_code,
                        critical_msil_eta:item.critical_msil_eta,
                        delay_reason:item.delay_reason,
                        delay_type:item.delay_type,
                        dept_code:item.dept_code,
                        dept_name:item.dept_name,
                        delay_reason:item.delay_reason,
                        spoc_contact_name:item.spoc_contact_name,
                        spoc_contact_number:item.spoc_contact_number,
                        status:item.status,
                        transit_delay_days:parseInt(item.transit_delay_days),
                        transporters_name:item.transporters_name,
                        supplier_code:(item.supplier_code == "")?"NA":item.supplier_code,
                        uom:item.uom,
                    })
                }
                else
                {
                    criticaldataarr.push({
                        consignee_city:item.consignee_city,
                        consignee_code:item.consignee_code,
                        consignee_name:item.consignee_name,
                        consigner_code:item.consigner_code,
                        consignment_code:item.consignment_code,
                        delay_type:item.delay_type,
                        dept_code:item.dept_code,
                        dept_name:item.dept_name,
                        delay_reason:item.delay_reason,
                        expected_trip_end:item.expected_trip_end,
                        invoice_date:item.invoice_date,
                        plant_location:item.plant_location,
                        spoc_contact_name:item.spoc_contact_name,
                        spoc_contact_number:item.spoc_contact_number,
                        status:parseInt(item.status),
                        transit_delay_days:parseInt(item.transit_delay_days),
                        transporter_name:item.transporter_name,
                        transporters_name:item.transporters_name,
                        uom:item.uom,
                    })
                }
            })
        }
        //alert(transporter)
        //console.log("criticaldata here,", criticaldata)
        criticaldataarr.sort(GetSortOrder("transit_delay_days"));

        var filterdata=[]
        var containerimprt=[]
        if(criticaldataarr.length > 0)
        {
            criticaldataarr.map((item)=>{
                if(item.dept_code == 'LOG-SC')
                {
                    console.log("Itemm ", item)
                    if(item.transporters_name == transporter){
                        containerimprt.push(item)
                    }
                }
                else
                {
                    if(item.transporter_name == transporter){
                        filterdata.push(item)
                    }
                }
            })
        }
        console.log("containerimprt ",containerimprt)
        var html = '<div class="col-xl-12 col-lg-12 mt-10p"><div class="col-xl-12 col-lg-12 n-p-0"><h4 class="summary-grid-title">'+transporter+'</h4></div>';
        if(filterdata.length > 0)
        {
            var i=0;
            filterdata.map((itm) =>{
                if(i%2 == 0)
                {
                    var classtxt=''
                }
                else{
                    var classtxt='oddbg'
                }
                html += '<div class="col-xl-12 col-lg-12 row form-group bordergray pl-0px pr-0px ml-0p">';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">'+blocktitle+'</span><br /><span class="f16">'+itm.consignment_code+'</span></label></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">Loading Plant</span><br /><span class="f16">'+itm.consigner_code+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray  n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Consignee Name</span><br /><span class="f16">'+itm.consignee_name+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Destination City</span><br /><span class="f16">'+itm.consignee_city+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Original ETA</span><br /><span class="f16">'+getDDMMYYYY(itm.expected_trip_end)+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Transit Delay (Days)</span><br /><span class="f16">'+itm.transit_delay_days+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Name</span><br /><span class="f16">'+itm.spoc_contact_name+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right n-p-0 f16 brd-btm-gray  textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Contact Number</span><br /><span class="f16">'+itm.spoc_contact_number+'</span></div>';
                html += '<div class="col-xl-12 col-lg-12 brd-right n-p-0 f16"><label class="col-xl-12 col-lg-12 "><span class="f11">Remarks</span><br /><span class="f14">'+itm.delay_reason+'</span></div>';
                html += '</div>';
                i=i+1
            })
        }

        /*LOG-SC Container Imports Critical Delay view*/
        if(containerimprt.length > 0)
        {
            var n=0;
            containerimprt.map((itm) =>{
                if(n%2 == 0)
                {
                    var classtxt=''
                }
                else{
                    var classtxt='oddbg'
                }
                html += '<div class="col-xl-12 col-lg-12 row form-group bordergray pl-0px pr-0px ml-0p">';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">Container No</span><br /><span class="f16">'+itm.container_no+'</span></label></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">POL/Country of Origin</span><br /><span class="f16">'+itm.country_code+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray  n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Supplier</span><br /><span class="f16">'+itm.supplier_code+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Current Status </span><br /><span class="f16">'+itm.status+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">MSIL Requirement Date</span><br /><span class="f16">'+getDDMMYYYY(itm.critical_msil_eta)+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Transit Delay (Days)</span><br /><span class="f16">'+itm.transit_delay_days+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Name</span><br /><span class="f16">'+itm.spoc_contact_name+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right n-p-0 f16 brd-btm-gray  textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Contact Number</span><br /><span class="f16">'+itm.spoc_contact_number+'</span></div>';
                html += '<div class="col-xl-12 col-lg-12 brd-right n-p-0 f16"><label class="col-xl-12 col-lg-12 "><span class="f11">Remarks</span><br /><span class="f14">'+itm.delay_reason+'</span></div>';
                html += '</div>';
                i=i+1
            })
        }
        html += '</div>'
        //console.log(html)
        //console.log("deptcode ",deptcode)
        $(".grid-"+deptcode).hide();
        $(".grid-trans-"+deptcode).show();
        $("#gridtrans"+deptcode).html(html);
    })
    .catch(function(e){
        console.log("Error ",e)
    })

});


$(document).on('click',".clickconsigner", function(){
    var criticaldata=[];
    var consigner = $(this).attr("consigner")
    var transporter = $(this).attr("transporter")
    var deptcode = $(this).attr("deptcode");
    $(".dataLoadpagesum"+deptcode).show()
    $(".dataLoadpageimgsum"+deptcode).show()        
    $(".overlay-partsum"+deptcode).show()  
    var dept_code = $(this).attr("dept_code");
    var rqparams={
        dept_code:dept_code
    }
    if(dept_code == "LOG-PRT (Courier)")
    {
        var blocktitle='Consignment Code';
    }
    else{
        var blocktitle='Truck No';
    }
    //console.log("rqparams ",rqparams);
    redirectURL.post("/consignments/summarysecondleveldata",rqparams)
    .then(async (res) =>{
        //console.log("res.data.message[0] ",res.data.message[0])
        criticaldata=res.data.message[0].critical_data;
        var dept_code=res.data.message[0].dept_code;
        $(".dataLoadpagesum"+deptcode).hide()
        $(".dataLoadpageimgsum"+deptcode).hide()
        $(".overlay-partsum"+deptcode).hide();  
   

        var criticaldataarr=[]
        if(criticaldata.length > 0)
        {
            criticaldata.map((item) =>{
                if(dept_code == 'LOG-SC')
                {
                    criticaldataarr.push({
                        container_no:item.container_no,
                        country_code:item.country_code,
                        critical_msil_eta:item.critical_msil_eta,
                        delay_reason:item.delay_reason,
                        delay_type:item.delay_type,
                        dept_code:item.dept_code,
                        dept_name:item.dept_name,
                        delay_reason:item.delay_reason,
                        spoc_contact_name:item.spoc_contact_name,
                        spoc_contact_number:item.spoc_contact_number,
                        status:item.status,
                        transit_delay_days:parseInt(item.transit_delay_days),
                        transporters_name:item.transporters_name,
                        supplier_code:(item.supplier_code == "")?"NA":item.supplier_code,
                        uom:item.uom,
                    })
                }
                else
                {
                    criticaldataarr.push({
                        consignee_city:item.consignee_city,
                        consignee_code:item.consignee_code,
                        consignee_name:item.consignee_name,
                        consigner_code:item.consigner_code,
                        consignment_code:item.consignment_code,
                        delay_type:item.delay_type,
                        dept_code:item.dept_code,
                        dept_name:item.dept_name,
                        delay_reason:item.delay_reason,
                        expected_trip_end:item.expected_trip_end,
                        invoice_date:item.invoice_date,
                        plant_location:item.plant_location,
                        spoc_contact_name:item.spoc_contact_name,
                        spoc_contact_number:item.spoc_contact_number,
                        status:parseInt(item.status),
                        transit_delay_days:parseInt(item.transit_delay_days),
                        transporter_name:item.transporter_name,
                        transporters_name:item.transporters_name,
                        uom:item.uom,
                    })
                }
            })
        }
        //alert(transporter)
        console.log("criticaldata here,", criticaldata)
        criticaldataarr.sort(GetSortOrder("transit_delay_days"));

        var filterdata=[]
        var containerimprt= []
        if(criticaldataarr.length > 0)
        {
            criticaldataarr.map((item)=>{
                if(item.dept_code == 'LOG-SC')
                {
                    if(item.country_code == consigner && item.transporters_name == transporter)
                    {
                        containerimprt.push(item)
                    }
                }
                else
                {
                    if(item.consigner_code == consigner && item.transporter_name == transporter){
                        filterdata.push(item)
                    }
                }
            })
        }
        var html = '<div class="col-xl-12 col-lg-12 mt-10p"><div class="col-xl-12 col-lg-12 n-p-0"><h4 class="summary-grid-title">'+transporter+" - "+consigner+'</h4></div>';
        if(filterdata.length > 0)
        {
            var i=0;
            filterdata.map((itm) =>{
                if(i%2 == 0)
                {
                    var classtxt=''
                }
                else{
                    var classtxt='oddbg'
                }
                html += '<div class="col-xl-12 col-lg-12 row form-group bordergray pl-0px pr-0px ml-0p">';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">'+blocktitle+'</span><br /><span class="f16">'+itm.consignment_code+'</span></label></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">Loading Plant</span><br /><span class="f16">'+itm.consigner_code+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray  n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Consignee Name</span><br /><span class="f16">'+itm.consignee_name+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Destination City</span><br /><span class="f16">'+itm.consignee_city+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Original ETA</span><br /><span class="f16">'+getDDMMYYYY(itm.expected_trip_end)+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Transit Delay (Days)</span><br /><span class="f16">'+itm.transit_delay_days+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Name</span><br /><span class="f16">'+itm.spoc_contact_name+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right n-p-0 f16 brd-btm-gray  textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Contact Number</span><br /><span class="f16">'+itm.spoc_contact_number+'</span></div>';
                html += '<div class="col-xl-12 col-lg-12 brd-right n-p-0 f16"><label class="col-xl-12 col-lg-12 "><span class="f11">Remarks</span><br /><span class="f14">'+itm.delay_reason+'</span></div>';
                html += '</div>';
                i=i+1
            })
        }

        /*LOG-SC Container Imports Critical Delay view*/
        if(containerimprt.length > 0)
        {
            var n=0;
            containerimprt.map((itm) =>{
                if(n%2 == 0)
                {
                    var classtxt=''
                }
                else{
                    var classtxt='oddbg'
                }
                html += '<div class="col-xl-12 col-lg-12 row form-group bordergray pl-0px pr-0px ml-0p">';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">Container No</span><br /><span class="f16">'+itm.container_no+'</span></label></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">POL/Country of Origin</span><br /><span class="f16">'+itm.country_code+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray  n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Supplier</span><br /><span class="f16">'+itm.supplier_code+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Current Status</span><br /><span class="f16">'+itm.status+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">MSIL Requirement Date</span><br /><span class="f16">'+getDDMMYYYY(itm.critical_msil_eta)+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Transit Delay (Days)</span><br /><span class="f16">'+itm.transit_delay_days+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Name</span><br /><span class="f16">'+itm.spoc_contact_name+'</span></div>';
                html += '<div class="col-xl-6 col-lg-6 brd-right n-p-0 f16 brd-btm-gray  textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Contact Number</span><br /><span class="f16">'+itm.spoc_contact_number+'</span></div>';
                html += '<div class="col-xl-12 col-lg-12 brd-right n-p-0 f16"><label class="col-xl-12 col-lg-12 "><span class="f11">Remarks</span><br /><span class="f14">'+itm.delay_reason+'</span></div>';
                html += '</div>';
                i=i+1
            })
        }

        html += '</div>'
        //console.log(html)
        //console.log("deptcode ",deptcode)
        $(".grid-"+deptcode).hide();
        $(".grid-trans-"+deptcode).show();
        $("#gridtrans"+deptcode).html(html);
    })
    .catch(function(e){
        console.log("Error ",e)
    })

   
   //alert(transporter)
    //console.log("criticaldata here,", criticaldata)
   
});

$(document).on('click',".hidetransport", function(){
    var id = $(this).attr("id")
    $(".grid-"+id).show();
    $(".grid-trans-"+id).hide();
});
