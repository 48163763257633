/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Select from 'react-select';
// import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
// import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
// import 'ag-grid-enterprise';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import redirectURL from '../redirectURL';
import Constants from "../constants";
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYY } from "../common/utils";
// import { createStaticHandler } from '@remix-run/router';
// import BillingTripsData from "./BillingTripsDataGrid";
import CSVFileValidator from 'csv-file-validator';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import ViewPOD from "./viewpodbutton";
import UploadPOD from "./uploadpodbutton";
import RevertPOD from "./revertpodbutton";
import Datetime from 'react-datetime';
// import ViewBatchItems from "./viewbatchitems";
import "react-datetime/css/react-datetime.css";
import download from 'js-file-download';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CourierActions from '../courier/courierActions';
import { times } from 'lodash';
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class EpodScreen extends Component {

    state = {
        loadshow: 'show-n',
        overly: 'show-n',
        oRowData: [],
        totalrecords:[],
        pending:[],
        liabilityCnt: [],
        modules: AllModules,
        alert: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        isuploadcsv: 0,
        rowData: [],
        frameworkComponents: {
            courierActions: CourierActions,
            UploadPOD: UploadPOD,
            ViewPOD:ViewPOD,
            RevertPOD: RevertPOD
        },
        defaultColDef: {
            // flex: 1,
            minWidth: 100,
            filter: true,
            resizable: true,
            initialWidth: 200,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            sortable: true
        },
        detailCellRendererParams: {
            detailGridOptions: {
                columnDefs: [
                    { field: 'callId' },
                    { field: 'direction' },
                    { field: 'number', minWidth: 150 },
                    { field: 'duration', valueFormatter: "x.toLocaleString() + 's'" },
                    { field: 'switchCode', minWidth: 150 },
                ],
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                params.successCallback(params.data.callRecords);
            },
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        supportField: '',
        flTransporter:{
            label: "All",
            value: ""
        },
        filterTransporters:[{
            label: "All",
            value: ""
        }],
        rowSelection: "multiple",
        startDate: moment.parseZone().format('YYYY-MM'),
        delivered_date: '',
        gc_date: '',
        shipment_completion_date: '',
        manual_csvcontent: [],
        manual_file: '',
        gcWayBillList: [],
        shipType:{label:"All",value:"all"},
        multiplePodDocs: [],
        tabOne:"btn-current",
        tabTow:"btn-defaultc",
        tabThree:"btn-defaultc",
        uFile:"",
        dataType:"pending",
        transit_data_for_update: "",
        show_delivered_date: false,
        delivered_date_for_update: '',
        epodDocforupdate: '',
        errMsg: 'd-none',
        totalclosedpods:[],
        invalidDocketRecords:[],
        from_date:moment.parseZone(new Date()).subtract(7,"days").format("YYYY-MM-DD"),
        to_date:moment.parseZone(new Date()).format("YYYY-MM-DD"),

    }

    componentDidMount() {
        // let startDate = moment.parseZone().format('YYYY-MM');
        // this.getLiabilityData(startDate)
        this.loadTrips()
    }
    // getLiabilityData = (startDate) => {
    //     const { startDate: from_date, endDate: to_date } = this.getMonthDates(startDate);
    //     // console.log("from_date, to_date", from_date, to_date)
    //     let params = {
    //         "from_date": from_date,
    //         "to_date": to_date,
    //         "consigner_code": [
    //             {
    //                 "value": "all",
    //                 "label": "All"
    //             }
    //         ],
    //         "pod_status": 1,
    //         "serviceProviders": [],
    //         "is_filter_call": 0
    //     }
    //     this.loadTrips(params);
    // }

    loadTrips = () => {
        // console.log(params,"params123")
        this.setState({
           
            loadshow :'show-m',
            overly :'show-m'
        })
        var fdate = this.state.from_date //by default we are giving 1 week data 
		var todayDate = this.state.to_date
        var fdt = fdate.split("-");
		var fromdate = fdt[2] + "-" + fdt[1] + "-" + fdt[0];
		var tdt = todayDate.split("-");
		var todate = tdt[2] + "-" + tdt[1] + "-" + tdt[0];
        var transporter = this.state.flTransporter

        var params ={from_date:fdate,to_date:todayDate,consigner_code:[{"value":"all","label":"All"}],serviceProviders:[],is_filter_call:0}
        // console.log(params,"params")
        if(localStorage.getItem("supplier_code") !== "" && localStorage.getItem("supplier_code") !== undefined && localStorage.getItem("supplier_code") !== null  && localStorage.getItem("supplier_code") !== "undefined")
        {
            params.user_code = JSON.parse(localStorage.getItem("supplier_code"))
            params.user_type = localStorage.getItem("user_type");
        }
        if (localStorage.getItem("sac_code") !== "" && localStorage.getItem("sac_code") !== undefined && localStorage.getItem("sac_code") !== null && localStorage.getItem("sac_code") !== "undefined") {
            params.sac_code = localStorage.getItem("sac_code").split(",");
        }
        if (transporter !== "" && transporter !== undefined && transporter !== null) {
            if (transporter.value != "") {
                params['transporter'] = [transporter.value]
            }
        }
        redirectURL.post("/consignments/couriers", params,{

        // redirectURL.post("/billing/trips", params,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
              }
          }).then((response) => {
            var records = response.data;
            var invalidDocketRecords = []
             records.map((item)=>{
                if(item.consignment_status == "Invalid docket"){
                    return invalidDocketRecords.push(item)
                }
            })
            records = records.filter((item)=>item.consignment_status != "Invalid docket")
            
            // console.log(invalidDocketRecords,"invalidDocketRecords")
            // var transporter = this.state.flTransporter

            //   if (this.state.shipType.value === "all") {
            //     console.log("entering")
            //       var checkData = records
            //   }
            //   else if (this.state.shipType.value === "PTL") {
            //       var checkData = records.filter((f) => ["47", "48"].includes(f.shipping_type))
            //       console.log(checkData.length, "checkdata123")
            //   }
            //   else if (this.state.shipType.value === "FTL") {
            //       var checkData = records.filter((f) => ["01", "02", "03", "04", "10", "11", "12", "14", "15", "17", "18", "21", "24", "25", "29", "30", "31", "32", "45", "46"].includes(f.shipping_type))
            //   }
            //   else {
            //       var checkData = records.filter((f) => f.shipping_type == this.state.shipType.value)
            //   }
              // console.log("checkData",checkData) 
             var checkData = records    
              if (checkData.length > 0) {
                  if (this.state.dataType === "pending") {
                      checkData = checkData.filter(f => (f.is_submitted_pod === "" || f.is_submitted_pod === undefined || f.is_submitted_pod === 0))
                  }
                  else {
                      checkData = checkData.filter(e => (e.is_submitted_pod === 1 && e.process_status === 0))
                  }
              }
            
            // console.log(records.length)
            var liabilityCnt = []; 
            var pending=[]         
            var recordsarr = [];            
            let gcWayBillList = []
            try {
                records.map(e => {
                    // if(e.consignment_status === "Delivered" || e.consignment_status === "DELIVERED")
                    // {
                        gcWayBillList.push({ 'label': e.gc_waybill_no, 'value': e.gc_waybill_no })
                        if (e.pod_status !== 1) {
                            liabilityCnt.push(e)
                        }
                        if(e.is_submitted_pod === "" || e.is_submitted_pod === undefined || e.is_submitted_pod === 0 || e.pod_status !== 1)
                        {
                            pending.push(e)
                        }
                        recordsarr.push(e)
                    // }
                })
            } catch (error) {
                
            }
            // console.log("liabilityCnt",)
            //  console.log({recordsarr});
            // console.log("pending",pending) 
               
            this.setState({
                // shipType:this.state.shipType,
                liability_data: liabilityCnt,
                rowData: pending,
                totalrecords:records,
                oRowData: recordsarr,
                pending:pending,
                invalidDocketRecords:invalidDocketRecords,
                liabilityCnt: liabilityCnt,
                gcWayBillList,
                loadshow :'show-n',
                overly :'show-n'
            })
            // this.showTabView(1);
        })

        redirectURL.post("/consignments/getConsignmentsNewFilterKeys",{},{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem("userid")
			  }
		    })
			.then((resp) => {
				var transporters = resp.data.transportersList;
				let tlist = [];
				let tlistarr = [];
				try{
					
					// transporters.map((t) => {
					// 	tlistarr.push({
					// 		"value": t.transporter_code,
					// 		"label": t.transporter_name+" - "+t.transporter_code
					// 	});
					// });
					
					var filterTransporters = [];
					if (transporters.length > 0) {
						filterTransporters.push({
							label: "All",
							value: ""
						});
						transporters.map((t) => {
							tlistarr.push({
							"value": t.transporter_code,
							"label": t.transporter_name+" - " +t.transporter_code,
						});
						});
					}
					 let newArray = [];               
						// Declare an empty object 
						let totuniqueObject = {}; 
						var totobjTitle;
						// Loop for the array elements 
						for (let i in tlistarr) { 

							// Extract the title 
							totobjTitle = tlistarr[i]['value']; 

							// Use the title as the index 
							totuniqueObject[totobjTitle] = tlistarr[i]; 
						} 

						// Loop to push unique object into array 
						for (var a in totuniqueObject) { 
							newArray.push(totuniqueObject[a]); 
						}
						tlist.push({value:"",label:"All"})
					if(newArray.length > 0)
					{
						newArray.map((item) => {
							tlist.push(item)
						})
					}
					this.setState({
						filterTransporters: tlist,
					});
				}catch(e){
					this.setState({
						filterTransporters: [],
					});
				}				
			})
    }

    getMonthDates = (monthYear) => {
        // console.log("monthYear", monthYear)
        const [year, month] = monthYear.split('-');
        const startDate = new Date(year, month - 1, 1).toISOString().slice(0, 10);
        // const now = new Date();
        // const currentYear = now.getFullYear();
        // const currentMonth = now.getMonth() + 1;
        const daysInMonth = new Date(year, month, 0).getDate();
        const endDate = new Date(year, month - 1, daysInMonth).toISOString().slice(0, 10);

        return { startDate, endDate };
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onShowCourierTrack = async (params) => {
		console.log(params,"12345")
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.trackCourier,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		if (params != 0) {

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "Transit Date",
								field: "intransit_date",
								valueGetter: function (params) {
									// console.log("IN onShowCourierTrack, params.data = ", typeof(params.data.intransit_date));
									// var intransitdate=getDDMMYYYY(params.data.intransit_date)+" "+params.data.intransit_time
									//    var intransitdate= params.data.intransit_date +" "+ params.data.intransit_time
									//    return intransitdate
									if ((params.data.intransit_date).includes('Z')) return getHyphenDDMMMYYYYHHMM(params.data.intransit_date)
									return (params.data.intransit_date)
								},
								filter: true,
								resizable: true
							},
							{
								headerName: "Location",
								field: "intransit_location",
								filter: true,
								resizable: true
							},
							{
								headerName: "Status",
								field: "intransit_status",
								filter: true,
								resizable: true
							}
						]
					},
					getDetailRowData: function (params) {
						console.log("Step 4 ",params.data.gc_waybill_no)
						redirectURL.post('/consignments/trackcourier', 
							{consignment_code: params.data.consignment_code},{
							headers: {
								'Content-Type': 'application/json',
								'Authorization': localStorage.getItem("userid")
							}
						})
							.then(async (response) => {
								// console.log("Step 1 ",response.data)
								var trackData = response.data;
								// console.log("trackData ", trackData);
								try {
									trackData.sort((a, b) => new Date(a.intransit_date) - new Date(b.intransit_date))
								} catch (e) {
									trackData = []
								}
								// console.log("trackDataAfter ", trackData);

								if (trackData.status == 'failure') {
									params.successCallback([]);
								}
								else {
									params.successCallback(trackData);
								}

								//console.log("Step 2 ",response.data)

							});

					},
					masterDetail: true
				}
			});

			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		//console.log("params.column.colId ", params.column)
		if (params.column.colDef.field == '_id') {
			params.node.setExpanded(!params.node.expanded);
		}
		else {

			params.node.setExpanded(false);
		}
	}

    setCounterData = counter => {
        
    }

    handlerStartDateTime = (event) => {
        var d = new Date(event._d);
        // console.log("d.getMonth()",d.getMonth())
        let month = d.getMonth()
        if (month <= 10) {
            month = `0${month + 1}`
        } else {
            month = `${month + 1}`
        }
        let startDate = d.getFullYear() + "-" + month
        // console.log("startDate", startDate)
        this.setState({
            startDate: startDate,
            loadshow: 'show-m',
            overly: 'show-m'
        });
        this.getLiabilityData(startDate)
    }

    hideSlideBlock = () => {
         $("#podDoc").val("")
         $("#epodDoc").val("")

        this.setState({
            delivered_date :""
        })
        // this.setState({
        //     shipment_slider: '',
        //     slider: "",
        //     slidershow: "show-n",
        //     bulkslidershow: "show-n",
        //     sliderSupportshow: 'show-n',
        //     overly: "show-n",
        //     selected_row_nodes: [],
        //     loadshow: 'show-n',
        //     slidershow: "show-n"
        // })
         document.getElementById("formBulkPOD").reset();
         document.getElementById("formPOD").reset();
         document.getElementById("formBulkSingle").reset();
    }

    onCloseSliderDiv = () => { 
        $("#podDoc").val("")
        $("#epodDoc").val("")
        $("#File").val("")
          
        this.setState({
            manual_upload_slider: 'show-n',
            bulkMarkDeliverSlide: 'show-n',
            shipment_slider: "show-n",
            shipment_csvcontent: [],
            shipment_file: '',
            slidershow: "show-n",
            bulkslidershow: "show-n",
            sliderscanshow: "show-n",
            sliderSupportshow: 'show-n',
            overly: "show-n",
            selected_row_nodes: [],
            loadshow: 'show-n',
            multiplePodDocs: [],
            delivered_date:"",
            update_row_slider:"",
        });
    };

    changeDateTimeValue = (event, currentDate, selectedDate) => {
        // console.log("event", event)
        var d = new Date(event._d);
        // var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        // console.log("getHyphenDDMMMYYYYHHMM(d) ",getHyphenDDMMMYYYYHHMM(d))
        this.setState({
            delivered_date: getHyphenYYYYMMDD(d)
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    changeDateTimeGCValue = (event, currentDate, selectedDate) => {
        // console.log("event", event)
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        // console.log("getHyphenDDMMMYYYYHHMM(d) ",getHyphenDDMMMYYYYHHMM(d))
        this.setState({
            gc_date: getHyphenDDMMMYYYYHHMM(d)
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    changeDateTimeSCompletion = (event, currentDate, selectedDate) => {
        // console.log("event", event)
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        // console.log("getHyphenDDMMMYYYYHHMM(d) ",getHyphenDDMMMYYYYHHMM(d))
        this.setState({
            shipment_completion_date: getHyphenDDMMMYYYYHHMM(d)
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }


    changeHandler = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        })
    }

    changeManualFileHandler = async (e) => {
        if(e.target.files.length !== 0){
			const config = {
				headers: [
					{
						name: 'truck_no',
						inputName: 'truck_no',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'gc_waybill_no',
						inputName: 'gc_waybill_no',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'gc_date',
						inputName: 'gc_date',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'shipment_completion_date',
						inputName: 'shipment_completion_date',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'shipping_type',
						inputName: 'shipping_type',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'consignee_name',
						inputName: 'consignee_name',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'consignee_pincode',
						inputName: 'consignee_pincode',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'allowable_weight',
						inputName: 'allowable_weight',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'per_kg_slab_rate',
						inputName: 'per_kg_slab_rate',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'basic_trans_cost',
						inputName: 'basic_trans_cost',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
	
				]
			}
			var data = CSVFileValidator(e.target.files[0], config)
				.then((csvData) => {
					// console.log('csvData', csvData)
					this.setState({
						manual_csvcontent: csvData.data
					});
					let keys = Object.keys(csvData.data[0])
                let items = ['truck_no','gc_waybill_no','gc_date','shipment_completion_date','shipping_type','consignee_name','consignee_pincode','allowable_weight','per_kg_slab_rate','basic_trans_cost']
                let check = false
                for (let i in items){
                    if(keys.includes(items[i])){                 
                    }else{
                        check = true
                        break
                    }
                }
                // console.log("check", check)
                if (check) {
                    $("#uploadFile").val("")
                    // document.getElementById("appednCSVData").innerHTML = ''
                    this.setState({
                        show: true,
                        basicTitle: 'dont upload other fields',
                        basicType: 'danger'
                    });
                } else {
                    this.setState({
                        manual_csvcontent: csvData.data
                    });
                }
				})
				.catch(err => { })
			// console.log("e.target.files[0]",e.target.result);
			var contentList = []
			var out = new Promise(function (reject, resolve) {
				var reader = new FileReader();
				reader.onload = async function (e) {
					var contents = await e.target.result;
					// document.getElementById("appednCSVData").innerHTML = contents
					resolve(contents);
				};
				if(e.target.files){
					var tt = reader.readAsText(e.target.files[0]);
				}
			});
			
			// console.log(contentList);
			this.setState({
				manual_file: e.target.files[0]
			});
			// console.log("e.target.files[0].type ", e.target.files[0]);
	
			if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
				// console.log(fileData);
				// console.log(typeof(fileData))
	
			}
			else {
				e.target.value = null;
				this.setState({
					uploadFile: '',
					file: "",
					show: true,
					basicType: 'danger',
					basicTitle: 'Please upload file having extensions .csv only.',
				});
			}

		}
    }

    setSelectedGcWayNo = (e) => {
        // console.log("item", e)
        this.setState({
            gc_way_bill_No_sel: e
        })
    }

    resetUpload = () => {
        $("#manualUploadFile").val("")
        this.setState({
            delivered_date:""
        })
        // document.getElementById("appednCSVData").innerHTML = ''
        // this.setState({
        //     uploadDivWidth: '0%',
        //     bulkForceCloseSlide: '',
        //     bulkMarkDeliverSlide: '',
        //     showDiv: 'show-n',
        //     overly: 'show-n',
        //     file: '',
        //     shipment_slider: 'show-n'
        // });
    }

    uploadManualBulkFormHandler = (e) => {
        e.preventDefault()
        const { gc_way_bill_No_sel, manual_csvcontent } = this.state

        if (gc_way_bill_No_sel.value !== "") {
            let params = {
                gc_way_bill_No_sel: gc_way_bill_No_sel.value,
                manual_csvcontent
            }
            redirectURL.post("/consignments/uploadBulkManualData", params,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                  }
              }).then(res => {
                // console.log("response", res)
                if (res.data.message === 'success') {
                    $("#manualUploadFile").val("")
                    // document.getElementById("appednCSVData").innerHTML = ''
                    this.setState({
                        manual_upload_slider: "show-n",
                        manual_csvcontent: [],
                        manual_file: '',
                        show: true,
                        basicType: 'info',
                        basicTitle: 'documents uploaded, data would be reflected after few minutes'
                    })
                }
            })
            // console.log("params", params)
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;

    };

    onCellClicked = async (e) => {
        this.setState({
            delivered_date : getHyphenYYYYMMDD(e.data.delivery_datetime),
            detailCellRendererParams: {}
        })
        if (e.colDef.field == 'gc_waybill_no') {
            const data = e.data
            // console.log("cellClicked", data)
            // if (data.manual_data) {
            await this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: "Truck No",
                                field: "truck_no",
                                filter: true,
                                resizable: true
                            },
                            {
                                headerName: "Gc WayBill No",
                                field: "gc_waybill_no",
                                filter: true,
                                resizable: true
                            },
                            {
                                headerName: "Gc Date",
                                field: "gc_date",
                                valueGetter: function (params) {
                                    try {
                                        if(params.data.gc_date !== "" && params.data.gc_date !== undefined)
                                        {
                                            return getHyphenDDMMMYYYYHHMM(params.data.gc_date)
                                        }
                                        else{
                                            return "";
                                        }   
                                    } catch (error) {
                                        
                                    }
                                },
                                filter: true,
                                resizable: true
                            },
                            {
                                headerName: "Customer name",
                                field: "consignee_name",
                                filter: true,
                                resizable: true
                            },
                            {
                                headerName: "Customer Pincode",
                                field: "consignee_pincode",
                                filter: true,
                                resizable: true
                            },
                            {
                                headerName: "Allowable Weight(Kgs)",
                                field: "allowable_weight",
                                filter: true,
                                resizable: true
                            },
                            {
                                headerName: "Basic Trans Cost",
                                field: "basic_trans_cost",
                                filter: true,
                                resizable: true
                            },
                            {
                                headerName: "Slab Rate Per Kg (Rs)",
                                field: "per_kg_slab_rate",
                                filter: true,
                                resizable: true
                            },
                            {
                                headerName: "Shipping Type",
                                field: "shipping_type",
                                filter: true,
                                resizable: true
                            }
                        ]
                    },
                    getDetailRowData: function (params) {
                        // console.log("Step 4 ", data)
                        let req = {
                            gc_waybill_no: data.gc_waybill_no
                        }
                        redirectURL.post('/consignments/getManualCourierCons', req,{
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': localStorage.getItem("userid")
                              }
                          }).then(async (response) => {
                            // console.log("Step 1 ",response.data)
                            var manualData = response.data;
                            // console.log("manualData ", manualData);
                            try {

                            } catch (e) {
                                manualData = []
                            }
                            // console.log("manualDataAfter ", manualData);

                            if (manualData.length == 0) {
                                params.successCallback([]);
                            }
                            else {
                                params.successCallback(manualData);
                            }
                            //console.log("Step 2 ",response.data)	
                        });
                    },
                    masterDetail: true
                }
            });
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
            //console.log("params.column.colId ", params.column)
            if (e.column.colDef.field == 'gc_waybill_no') {
                e.node.setExpanded(!e.node.expanded);
            }
            else {
                e.node.setExpanded(false);
            }
            // }
        }

        if(e.colDef.field == 'view'){
            // console.log(e.data,"3214")
			this.setState({
				update_row_slider: "slider-translate-30p",
				overly: "show-m",
				row_data : e.data,
                errMsg: 'd-none',
                transit_data_for_update: "",
                status_for_update: "",
                delivered_date_for_update: "",
                show_delivered_date: false,
                gc_row_date : moment.parseZone(e.data.gc_date).format("YYYY-MM-DD HH:mm:ss")
			})
            $("#location_for_update").val("")
            $('#epodDocforupdate').val("")
		}
    }
    changeFileHandler = (event) => {
        // console.log("event.target.files[0] ", event.target.files[0])
        this.setState({
            epodDoc: event.target.files[0]
            
        })
    }
   
    changeBulkFileSaveCode = (event) => {
        const bulkFiles = Array.prototype.slice.call(event.target.files)
        //  console.log("File",File)
            this.setState({
                uFile : event.target.files[0],
            })
        
    }
    changeBulkFileHandler = e => {
        const bulkFiles = Array.prototype.slice.call(e.target.files)
        try{
            var multiplePodNames = bulkFiles.map(e => {
                let [name,date] = e.name.split("_")
                date = date.split(".")[0]
                //  console.log("name", name, "\n date",date)
                return {
                    'gc_waybill_no' : name,
                    'delivered_date' : date
                }            
            })
        }
        catch(e){
            this.setState({
                show: true,
                basicTitle: "Please give file name as mentioned.",
                basicType: "warning"
            })
            // console.log("error",e)
            multiplePodNames = []
        }
        // console.log("multiplePodNames",multiplePodNames)
        if (bulkFiles.length > 0) {
            this.setState({
                multiplePodDocs: bulkFiles,
                multipleFiles : e.target.files,
                multiplePodNames
            })
        }
        // console.log("bulkUploadFiles", bulkFiles)
    }

    onClickUploadManualData = () => {
        this.setState({
            manual_upload_slider: "slider-translate-40p",
            overly: "show-m",
        })
    }
    // onClickViewPOD = (e) => {
    //     //  console.log("documnent_view", e)
    //     let params = {
    //         pod_document : e.data.pod_document
    //     }
    //     //  console.log("params",params)
    //     redirectURL.post("/billing/downloadPodDocument", params,{
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': localStorage.getItem("userid")
    //           }
    //       }).then(res =>{
    //          console.log("response", res)
    //         download(res.data, e.data.pod_document)
    //     }).catch(function(error){
    //         //  console.log(error);
    //     })
    // }
    onClickViewPOD = (e) => {
        let params = {
            pod_document: e.data.pod_document
        };
        
        redirectURL.post("/billing/downloadPodDocument", params, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
            }
        }).then(res => {
            console.log("response", res);
            const fileUrl = res.data.url;
            const newWindow = window.open(fileUrl, '_blank');
            if (newWindow) {
                newWindow.opener = null;
            }
        }).catch(error => {
            console.error(error);
        });
    }
    

    setBulkUploadEpod = () => {
        let  rownodes = this.gridApi.getSelectedRows();
        if (rownodes.length > 1) {
            this.setState({
                rownode: [],
                selected_row_nodes: rownodes,
                bulkslidershow: "slider-translate-40p", // slide30
                overly: "show-m",
                loadshow: 'show-n'
            })
        } else {
            this.setState({
                show: true,
                basicTitle: "Please Select multiple rows.",
                basicType: "info"
            })
        }
        // console.log("rowNodes", rownodes)
    }
    setBulkUploadScan = () => {
        let  rownodes = this.gridApi.getSelectedRows();
        // if (rownodes.length > 0) {
            this.setState({
                rownode: [],
                selected_row_nodes: rownodes,
                sliderscanshow: "slider-translate-40p", // slide30
                overly: "show-m",
                loadshow: 'show-n'
            })
        // }
        //  else {
        //     this.setState({
        //         show: true,
        //         basicTitle: "Please Select multiple rows.",
        //         basicType: "info"
        //     })
        // }
        // console.log("rowNodes", rownodes)
    }

    

    uploadDocument = (event) => {
        event.preventDefault();
        const { delivered_date, epodDoc, selected_row_nodes, multiplePodDocs, multiplePodNames, rownode, multipleFiles } = this.state
        if (delivered_date == "")
        {
            this.setState({
                show:true,
                basicType: "warning",
                basicTitle: "Please select delivered date",
                delivered_date:"",
                epodDoc:""
                
            })
        } 
        else
        {     
            if (rownode.length > 0) { // for single pod upload

                var rowid = this.state.rownode[0]._id;
                var rownodes = this.state.rownode;
                const formData = new FormData();
                formData.append("podDoc", epodDoc);
                formData.append("delivered_date", delivered_date);
                formData.append("rowid", rowid);
                formData.append("rownodes", JSON.stringify(rownodes));
                //    console.log("FormData ", formData);
                redirectURL.post("/billing/savepoddoc", formData)
                    .then((resp) => {
                        if (resp.data.status == 'success') {
                            document.getElementById("formBulkPOD").reset();
                            document.getElementById("formPOD").reset();
                            this.setState({
                                selected_row_nodes: [],
                                show: true,
                                epodDoc:[],
                                slider:'show-n',
                                basicTitle: "Success.",
                                basicType: "success",
                                delivered_date:"",
                                slidershow: "", // slide30
                                overly: "show-n",
                                loadshow: 'show-n'
                            })
                        }
                           window.location.reload();
                    })
            }
            else if (selected_row_nodes.length > 0) {  // for bulk multiple pod uploads
                //   console.log('selected_row_nodes', selected_row_nodes)
                //   console.log('multiplePodDocs', multiplePodDocs)
                //   console.log('multiplePodNames', multiplePodNames)
                var multiplePodNamesarr = [];
                if(multiplePodNames.length > 0)
                {
                    multiplePodNames.map((itm) => {
                        multiplePodNamesarr.push(itm.gc_waybill_no)
                    })
                }
                let matched = false
                let otpt
                if (multiplePodNamesarr.length > 0) {
                    otpt = selected_row_nodes.map((e, i) => {
                        //  console.log("e.gc_waybill_no ", e.gc_waybill_no)
                        //  console.log("multiplePodNames[i].gc_waybill_no ", multiplePodNamesarr.indexOf(e.gc_waybill_no))
                        if (multiplePodNamesarr.indexOf(e.gc_waybill_no) >= 0) {
                            matched = true
                        } else {
                            return false
                        }
                    })
                }
                //   console.log("otpt", otpt)
                if (otpt.includes(false) === true) {
                    this.setState({
                        show: true,
                        basicTitle: "gc_waybill_no should match.",
                        basicType: "info"
                    })
                } else {
                    let rowid = this.state.selected_row_nodes.map(e => e._id);
                    // console.log("rowid", rowid)
                    if(this.state.selected_row_nodes.length > 0)
                    {
                        var selnodes = this.state.selected_row_nodes;
                    }
                    else{
                        var selnodes = [];
                    }
                    const formData = new FormData();
                    formData.append("multiplePodDocs", JSON.stringify(multiplePodDocs));
                    formData.append("delivered_date", JSON.stringify(delivered_date));
                    formData.append("multiplePodNames", JSON.stringify(multiplePodNames));
                    formData.append("rowid", JSON.stringify(rowid));
                    formData.append("rownodes", JSON.stringify(selnodes));
                    //  console.log("multipleFiles ", multipleFiles);

                    if(multipleFiles.length > 1){
                        for (let i = 0; i < multipleFiles.length; i++) {
                            formData.append('files', multipleFiles[i]);
                        }
                      
                         var check = true
                         selected_row_nodes.map(e => {
                            if(e.consignment_status =="In Transit"){
                                check = false
                            }
                         })
                         if(check == true){
                            redirectURL.post("/billing/saveMultipledocs", formData)
                            .then((resp) => {
                                 console.log("response", resp.data)
                                if (resp.data.message == "successs") {
                                    // $("#podDoc").val("");
                                    // document.getElementById("podDoc").reset()
                                    this.setState({
                                        selected_row_nodes: [],
                                        multiplePodDocs: [],
                                        multiplePodNames: [],
                                        show: true,
                                        basicTitle: "Success.",
                                        basicType: "success",
                                        delivered_date:"",
                                        bulkslidershow:"",
                                        overly :'show-n',
                                        loadshow : 'show-n',
                                        slider:'show-n'
                                    })
                                    window.location.reload();
                                }
                                else{
                                    this.setState({
                                        show:true,
                                        basicTitle: "Invalid GC Waybill Number",
                                        basicType: "info",
                                    })
                                }
                                
                                
                            })
                         }else{
                            this.setState({
                                show: true,
                                basicTitle: "Cannot upload epods for intransit consignments",
                                basicType: "info"
                            })
                         }
                        
                    }else{
                        this.setState({
                            show: true,
                            basicTitle: "For Multiple upload only use this Feature",
                            basicType: "info"
                        })
                        
                    }
                }

            }
        }
    }
    uploadScanCode = (event) => {
            event.preventDefault();
            const { delivered_date, podDoc, selected_row_nodes, multiplePodDocs, multiplePodNames, rownode, singleFiles } = this.state
    
            // if (rownode.length > 0) { // for single pod upload
    
                // var rowid = this.state.rownode[0]._id;
                // var rownodes = this.state.rownode;
                var uFile=this.state.uFile;

                const formData = new FormData();
                formData.append("uFile", uFile);
                formData.append("delivered_date", delivered_date);
                // formData.append("rowid", rowid);
                // formData.append("rownodes", JSON.stringify(rownodes));
                //   console.log("FormData ", formData);
                redirectURL.post("/billing/savescancode", formData)
                    .then((resp) => {
                        if (resp.data.status == 'success') {
                            document.getElementById("formBulkSingle").reset();
                            // document.getElementById("formPOD").reset();
                            this.setState({
                                selected_row_nodes: [],
                                overly: 'show-n',
                                loadshow: 'show-n',
                                show: true,
                                basicTitle: "Success",
                                basicType: "success",
                                delivered_date: ""
                            })
                        }
                        // window.location.reload();
                    })
        }
    onClickHideAll = () => {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderForceCloseTranslate: '',
			sliderMarkDeliverTranslate: "",
			bulkPrioritySlide: "",
			bulkForceCloseSlide: "",
			bulkMarkDeliverSlide: "",
            bulkslidershow:"",
            sliderscanshow:"",
            slidershow:"",
            delivered_date:"",
            update_row_slider:'',
			status_for_update:'',
			transit_data_for_update:'',
		});
        $("#location_for_update").val("")
		$("#epodDocforupdate").val("")
        document.getElementById("formPOD").reset();
        document.getElementById("formBulkPOD").reset();
        document.getElementById("formBulkSingle").reset();

	}



    onClickUploadPOD = (rownode) => {
        // console.log("rownode",rownode)
        this.setState({
            slidershow: "slider-translate-40p", // slide30
            overly: "show-m",
            rownode: [rownode.data]
        });
    }
    onClickRevertPOD  = (rownode) => {
        var rownode = rownode.data;
        // console.log("rownode", rownode);
        // var gc_waybill_no = rownode.gc_waybill_no
        var consignment_code = rownode.consignment_code
        
        // console.log("gc_waybill_no", gc_waybill_no);
    
        const params = {
            // gc_waybill_no: gc_waybill_no
            consignment_code: consignment_code
        };
        console.log("params",params)
        redirectURL.post("/billing/revertepoddata", params)
        .then((resp) => {
            if (resp.data.status == 'success') {
                
                this.setState({
                    rownode: [],
                    overly: 'show-n',
                    loadshow: 'show-n',
                    show: true,
                    basicTitle: "Success",
                    basicType: "success",
                })
            }
            // window.location.reload();
            this.loadTrips(params)
            
        })

    }
    
    handlerStartDeliveryDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.to_date != "" && startdate > this.state.to_date) {
            this.setState({
                show: true,
                basicTitle: 'Start date should be less than End date',
                basicType: "danger",
                loadshow: 'show-n',
                startDate: "",
            })
        }
        else {
            this.setState({
                from_date: startdate
            });
        }
    }
    handlerEndDeliveryDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var edate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.from_date != "" && edate < this.state.from_date) {
            this.setState({
                show: true,
                basicTitle: 'End date should be greater than Start date',
                basicType: "danger",
                loadshow: 'show-n',
                endDate: "",
            })
        }
        else {
            this.setState({
                to_date: edate
            });
        }
    }
  
    onChangeShipType(shipType)
    {
        if(this.state.tabThree != "btn-current"){
            var oRowData= this.state.oRowData;
        }
        else{
            var oRowData= this.state.invalidDocketRecords;
        }

        if(shipType.value === "all")
        {
            var checkData = oRowData
        }
        else if(shipType.value === "PTL"){
            var checkData  = oRowData.filter((f)=>["47","48"].includes(f.shipping_type))
        }
        else if(shipType.value === "FTL"){
            var checkData  = oRowData.filter((f)=>["01", "02", "03", "04", "10", "11", "12", "14", "15", "17", "18", "21", "24", "25","29", "30", "31", "32", "45", "46"].includes(f.shipping_type))
        }
        else if(shipType.value === "ODC"){
            var checkData  = oRowData.filter((f)=>["00","06"].includes(f.shipping_type))
        }
        else{
            var checkData  = oRowData.filter((f)=>f.shipping_type == shipType.value)
        } 
        // console.log("checkData",checkData)       
        if(checkData.length > 0)
        {
            if(this.state.dataType === "pending"){
                checkData = checkData.filter(f => (f.is_submitted_pod === "" || f.is_submitted_pod === undefined || f.is_submitted_pod === 0 ))
            }
            else if(this.state.dataType === "invalid"){
                checkData = checkData
            }
            else{
                checkData = checkData.filter(e => (e.is_submitted_pod === 1 && e.process_status === 0))
            }
        }      
        this.setState({
            shipType : shipType,
            rowData : checkData
        })
    }

    newFilter = (filterType, selOption) => {
		console.log(filterType, selOption,"check")
		this.setState({ [filterType]: selOption });
	}

    submitTransporterType =(event) => {
        event.preventDefault();
        this.setState({
            overly:"show-m",
            loadshow:"show-m"
        })
        var fromdate = this.state.from_date
		var todate = this.state.to_date
        var transporter = this.state.flTransporter
        // var shipType = this.state.shipType
        
		if (!["",undefined].includes(fromdate) && !["",undefined].includes(todate)) {
			// this.setState({
			// 	from_date: fromdate,
			// 	to_date: todate,
            //     // shipType: shipType,
			// 	//loadshow: 'show-m'
			// });
            this.loadTrips()
		}
       
		
    }
    onClickTab(oVal){
        //  console.log(this.state.oRowData.length);
        if(oVal === "pending"){
            let data = [];
            // console.log("data",data)
            if(this.state.oRowData.length > 0)
            {
                data = this.state.oRowData.filter((f)=>(f.is_submitted_pod === "" || f.is_submitted_pod === undefined || f.is_submitted_pod === 0))
            }
            // console.log("data",data)
            // if(this.state.shipType.value !== "all")
            // {
            //     if(this.state.shipType.value === "PTL")
            //     {
            //          data  = data.filter((f)=>["47","48"].includes(f.shipping_type))
            //     }
            //     else 
            //     {
            //         data  = data.filter((f)=>f.shipping_type === this.state.shipType.value)
                   
            //     }
            // }
        
            // console.log("data",data)
            this.setState({
                tabOne:"btn-current",
                tabTow:"btn-defaultc",
                tabThree:"btn-defaultc",
                rowData:data,
                shipType:{label:"All",value:"all"},
                dataType:oVal
            })
        }
        if(oVal === "closed"){
            let data = [];
            if(this.state.oRowData.length > 0)
            {
                data = this.state.oRowData.filter(e => (e.is_submitted_pod === 1 && e.process_status === 0))
            }
            // if(this.state.shipType.value !== "all")
            // {
            //     if(this.state.shipType.value === "PTL")
            //     {
            //          data  = data.filter((f)=>["47", "48"].includes(f.shipping_type))
            //     }
            //     else 
            //     {
            //         data  = data.filter((f)=>f.shipping_type === this.state.shipType.value)
                   
            //     }
            // }
                      
            this.setState({
                tabOne:"btn-defaultc",
                tabTow:"btn-current",
                tabThree:"btn-defaultc",
                rowData:data,
                totalclosedpods:data,
                shipType:{label:"All",value:"all"},
                dataType:oVal
            })
        }
        if(oVal === "invalid"){
            let data = [];
            if(this.state.oRowData.length > 0)
            {
                data = this.state.oRowData.filter((f)=>(f.is_submitted_pod === "" || f.is_submitted_pod === undefined || f.is_submitted_pod === 0))
            }
            // if(this.state.shipType.value !== "all")
            // {
            //     if(this.state.shipType.value === "PTL")
            //     {
            //          data  = data.filter((f)=>["47","48"].includes(f.shipping_type))
            //     }
            //     else 
            //     {
            //         data  = data.filter((f)=>f.shipping_type === this.state.shipType.value)
                   
            //     }
            // }
            this.setState({
                tabOne:"btn-defaultc",
                tabTow:"btn-defaultc",
                tabThree:"btn-current",
                rowData:this.state.invalidDocketRecords,
                shipType:{label:"All",value:"all"},
                dataType:oVal
            })
        }
    }

    changeFileHandlerForupdate = (event) => {
        // console.log("event.target.files[0] ", event.target.files[0])
        this.setState({
            epodDoc: event.target.files[0]
            
        })
    }

	onUpdateConsignmentDetails = (e) => {
		e.preventDefault()
		// Commented as per the naveen's call with SAFE EXPRESS
		// if (this.state.transit_data_for_update != '' && this.state.errMsg == "d-none") {
            if (this.state.errMsg == "d-none") {
			console.log(this.state.status_for_update, "status_for_update")
			if (!["", undefined, "undefined", null].includes(this.state.status_for_update)) {
				if (this.state.status_for_update.value == 'Delivered') {
					if (this.state.delivered_date_for_update != "") {
						var params = {
							"consignment_status": this.state.status_for_update.value,
							"delivery_datetime": this.state.delivered_date_for_update,
							'delivery_station': '',
							// 'last_process_on': new Date(),
							'last_date': this.state.transit_data_for_update,
							'last_location': $("#location_for_update").val(),
							'last_status': this.state.status_for_update.value,
							'row_data':this.state.row_data,
						}
						var rownode = [this.state.row_data]
						var rowid = rownode[0]._id;
						var rownodes = rownode;
						const formData = new FormData();
						formData.append("podDoc", this.state.epodDoc);
						formData.append("delivered_date", this.state.delivered_date_for_update.split(" ")[0]);
						formData.append("rowid", rowid);
						formData.append("rownodes", JSON.stringify(rownodes)); 
                        console.log(formData,"formData")
						redirectURL.post("/billing/savepoddoc", formData,{
                            headers:{
                                'content-type': 'multipart/form-data'
                            }
                        })
						.then((resp) => {
							console.log(resp.data , "5001_checking")
							if (resp.data.status == 'success') {
								$("#epodDocforupdate").val("")
							}
						})
					}
					else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Please Select Delivery Date'
						})
					}
				}
				else {
					var params = {
						"consignment_status": this.state.status_for_update.value,
						'delivery_station': '',
						// 'last_process_on': new Date(),
						'last_date': this.state.transit_data_for_update,
						'last_location': $("#location_for_update").val(),
						'last_status': this.state.status_for_update.value,
						'row_data':this.state.row_data
					}
				}

                this.setState({
                    loadshow:'show-m',
                    overly:'show-m'
                })
                console.log(params,"params")
				redirectURL.post("/consignments/updateConsignmetsData",params).then(resposne => {
					console.log(resposne.data)
					if(resposne.data.status == 'success'){
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data Updateded Successfully',
							transit_data_for_update:'',
							status_for_update:'',
							delivered_date_for_update:'',
							loadshow:'show-n',
							overly:'show-n',
                            update_row_slider:'',
                            show_delivered_date: false,
						})
						$("#location_for_update").val("")
                        window.location.reload();
						// this.componentDidMount()
					}
				})

			}
			else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Please Select Status'
				})
			}
		}
		else {
			this.setState({
				show: true,
				basicType: 'danger',
				basicTitle: 'Please Select Valid Transit Date'
			})
		}

	}

    handlerTransitDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		console.log(event._d , "d_1729")
		var date = moment.parseZone(new Date(d)).format("YYYY-MM-DD HH:mm:ss")
        //console.log(date,"date")
        if(new Date(date.split(" ")[0]) >= new Date(this.state.gc_row_date.split(" ")[0])){
            this.setState({
                errMsg : 'd-none',
                transit_data_for_update : date,
            })
        }
        else{
            this.setState({
                errMsg: 'd-block',
                transit_data_for_update: date
            })
        }
	}

	handlerUpdateDeliveredDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var date = moment.parseZone(new Date(d)).format("YYYY-MM-DD HH:mm:ss")
		this.setState({
			delivered_date_for_update : date
		})
		

	}

    render() {
        const { show, basicTitle, basicType, oRowData,pending, liabilityCnt, modules, rowData, defaultColDef, detailCellRendererParams, sideBar, frameworkComponents, statusBar, paginationPageSize, rowSelection, sliderSupportshow, supportDocTitle, shipment_slider, manual_upload_slider,
            gc_way_bill_No_sel, gcWayBillList, multiplePodDocs,loadshow,overly
        } = this.state
        let checkSelect = true;
        if(this.state.tabThree === "btn-current" || this.state.tabTow === "btn-current"){
            checkSelect = false
        }
        if(this.state.tabTow === "btn-current")
        {
            var hh = true;
            var shh = false;
        }
        else{
            var hh = false;
            var shh = true;
        }
        var hideupdate = hh?true :localStorage.getItem("is_tracking_view") == "true" ? false : true; 
        console.log(localStorage.getItem("is_tracking_view"),hideupdate,"123")  
        let columnDefs = [
            // {
			// 	headerName: "",
			// 	field: "_id",
			// 	width: 70,
			// 	pinned: 'left',
            //     hide:hideupdate,
			// 	cellRenderer: 'courierActions',
			// 	filter: false,

			// },
            {
                headerName: Constants.TRIP_PLANT_CODE,
                field: "consigner_code",
                width: 120,
                checkboxSelection: checkSelect,
                headerCheckboxSelection:checkSelect
            },
            {
                headerName: Constants.TRIP_PLANT_NAME,
                field: "consigner_name",
                width: 100,
            },
            {
                headerName: Constants.TRIP_CONSIGN_NO,
                field: "consignment_code",
                width: 160,
            },
            {
                headerName: Constants.TRIP_TRUCK_NO,
                field: "truck_no",
                width: 120,
                // checkboxSelection: checkSelect
            },
            {
                headerName: Constants.GC_WAY_BILL_NO,
                field: "gc_waybill_no",
                width: 120,
                // checkboxSelection: checkSelect
            },
            {
                headerName: Constants.GC_DATE,
                field: "gc_date",
                width: 120,
                valueGetter: function (params) {
                    try {
                        if (params.data.gc_date != undefined && params.data.gc_date != "") {
                            return getHyphenDDMMMYYYYHHMM(params.data.gc_date);
                        }
                    }
                    catch (e) { }
                }
                // checkboxSelection: checkSelect
            },
            
            {
				headerName: Constants.STATUS,
				field: "consignment_status",
				width: 120,
				filter: "agSetColumnFilter",
				cellStyle: function (params) {
					if (params.value == 'Delivered') {
						//mark police cells as red
						return { color: 'green' };
					} else {
						return null;
					}
				},
				valueGetter: function (params) {
					if (params.data.consignment_status == "Cancelled") {
						return "Cancelled As Per Ashok Leyland"
					}
					else {
						return params.data.consignment_status
					}
				}
			},
            
            {
                headerName: "System Delivered Date",
                field: "delivery_datetime",
                width: 160,
                valueGetter: function (params) {
                    try {
                        if (params.data.delivery_datetime != undefined && params.data.delivery_datetime != "") {
                            return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime);
                        }
                    }
                    catch (e) { }
                }
                // checkboxSelection: checkSelect
            },
            
            {
                headerName: "Transporter Mark Delivered Date",
                field: "delivered_date",
                width: 160,
                hide:shh,
                valueGetter: function (params) {
                    try {
                        if (params.data.delivered_date != undefined && params.data.delivered_date != "") {
                            return getHyphenDDMMMYYYY(params.data.delivered_date);
                        }
                    }
                    catch (e) { }
                }
                // checkboxSelection: checkSelect
            },
            // {
            //     headerName: Constants.SHIPMENT_COMPLETION_TIME,
            //     field: "shipment_completion_date",
            //     width: 160,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.shipment_completion_date != undefined && params.data.shipment_completion_date != "") {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.shipment_completion_date);
            //             }
            //         }
            //         catch (e) { }
            //     }
            //     // checkboxSelection: checkSelect
            // },
            // {
            //     headerName: Constants.SHIPMENT_START_TIME,
            //     field: "shipment_start_date",
            //     width: 120,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.shipment_start_date != undefined && params.data.shipment_start_date != "") {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.shipment_start_date);
            //             }
            //         }
            //         catch (e) { }
            //     }
            //     // checkboxSelection: checkSelect
            // },
            {
                headerName: Constants.SHIPMENT_TYPE,
                field: "shipping_type",
                width: 120,
                valueGetter:function(params){
                    try {
                        var sdata = params.data.shipping_type; 
                        var sdataoVal = sdata;
                        if(sdata === "05" || sdata === 5)
                        {
                            var sdataoVal = "surface";
                        }
                        if(sdata === "07" || sdata === 7)
                        {
                            var sdataoVal = "air";
                        }
                        if(sdata === "22" || sdata === 22)
                        {
                            var sdataoVal = "train";
                        }
                        if(sdata === "48" || sdata === 48 || sdata === "47" || sdata === 47)
                        {
                            var sdataoVal = "Part truck Load";
                        }
                        if(["01", "02", "03", "04", "10", "11", "12", "14", "15", "17", "18", "21", "24", "25","29", "30", "31", "32", "45", "46"].includes(sdata))
                        {
                             var sdataoVal = "Full Truck Load";
                        }
                        if (sdata === "86" || sdata === 86) {
                            var sdataoVal = "Adjustment Load";
                        }
                        if (sdata === "00" || sdata === "06") {
                            var sdataoVal = "Over Dimensional Cargo";
                        }
                        if (sdata === "74" || sdata === 74) {
                            var sdataoVal = "Multi Point Delivery";
                        }
                        if (sdata === "HD") {
                            var sdataoVal = "Hand Delivery";
                        }
                        return sdataoVal;
                    } catch (error) {
                        
                    }
                }
                // checkboxSelection: checkSelect
            },
            // {
            //     headerName: Constants.CREDIT_SALES,
            //     field: "credit_sales",
            //     width: 120,
            //     // checkboxSelection: checkSelect
            // },
            {
                headerName: Constants.TRIP_TRANSPORTER_CODE,
                field: "transporter_code",
                width: 180,
            },
            {
                headerName: Constants.TRIP_TRANSPORTER_NAME,
                field: "service_provider",
                width: 180,
            },
          
            {
                headerName: Constants.TRIP_CUSTOMER_CODE,
                field: "consignee_code",
                width: 100,
            },
            {
                headerName: Constants.TRIP_CUSTOMER_NAME,
                field: "consignee_name",
                width: 180,
            },
            {
                headerName: Constants.TRIP_CUSTOMER_CITY,
                field: "consignee_city",
                width: 160,
            },
            {
                headerName: Constants.TRIP_CUSTOMER_STATE,
                field: "consignee_state",
                width: 150,
            },
            {
                headerName: Constants.TRIP_ZONE,
                field: "consignee_region",
                width: 140,
            },
            {
                headerName: Constants.TRIP_PIN_CODE,
                field: "consignee_pincode",
                width: 180,
            },
            // {
            //     headerName: Constants.TRIP_ACTUAL_DELIVERY_DATE,
            //     field: "actual_delivery_date",
            //     width: 180,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.actual_delivery_date != undefined && params.data.actual_delivery_date != "") {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.actual_delivery_date);
            //             }

            //         }
            //         catch (e) { }
            //     }
            // },
            // {
            //     headerName: Constants.TRIP_STATUS,
            //     field: "trip_status_txt",
            //     width: 160,
            // },
            // {
            //     headerName: Constants.TRIP_INV_DATE,
            //     field: "invoice_date",
            //     width: 140,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.invoice_date != undefined && params.data.invoice_date != "") {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
            //             }

            //         }
            //         catch (e) { }
            //     }
            // },
            // {
            //     headerName: Constants.TRIP_REACHED_DEST_DATE,
            //     field: "recent_dealer_reported",
            //     width: 140,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != "") {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
            //             }
            //         }
            //         catch (e) { }
            //     }
            // },
            // {
            //     headerName: Constants.TRIP_TOTAL_DISTANCE_KM,
            //     field: "total_distance_travelled_km",
            //     width: 140,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.total_distance_travelled_km != undefined && params.data.total_distance_travelled_km != "") {
            //                 return parseInt(parseInt(params.data.total_distance_travelled_km) / 1000);
            //             }
            //         }
            //         catch (e) { }
            //     }
            // },
            // {
            //     headerName: Constants.TRIP_TOTAL_TRIP_DISTANCE_KM,
            //     field: "total_travelled_trip_dist_km",
            //     width: 100,
            // },
            // {
            //     headerName: Constants.TRIP_DURATION_DAYS,
            //     field: "trip_duration_days",
            //     width: 100,
            // },
            // {
            //     headerName: Constants.TRIP_FREIGHT_COST,
            //     field: "freight_rate",
            //     width: 100,
            // },
            // {
            //     headerName: Constants.TRIP_TOTAL_COST,
            //     field: "total_cost",
            //     width: 100
            // },
            
            {
				headerName: "Update",
				field: "view",
				width: 150,
                pinned:'right',
				filter: false,
				hide : hideupdate,
				cellRenderer: function (params){
					if(params.data.consignment_status == 'Delivered'){
						return `<button class="btn btn-info" disabled>Update Tracking</button>`
					}else{
						return `<button class="btn btn-info">Update Tracking</button>`
					}
				}
			},
            {
                headerName: "Upload POD",
                field: "",
                width: 120,
                hide:!checkSelect,
                filter:false,
                pinned: "right",
                cellRendererSelector: function (params) {
                    if(params.data.consignment_status === "Delivered" || params.data.consignment_status === "DELIVERED")
                    {
                        return {
                            component: "UploadPOD"
                        }
                    }
                    else{
                        return " "
                    }
                    
                },
            },
            {
                headerName: "Revert POD",
                field: "",
                width: 120,
                hide:shh,
                filter:false,
                pinned: "right",
                cellRendererSelector: function (params) {
                    if(params.data.consignment_status === "Delivered" || params.data.consignment_status === "DELIVERED")
                    {
                        return {
                            component: "RevertPOD"
                        }
                    }
                    else{
                        return " "
                    }
                    
                },
            },
            {
                headerName: "View POD",
                field: "consignment_code",
                width: 120,
                hide:shh,
                pinned: "right",
                cellRendererSelector: function (params) {
                    return {
                        component: "ViewPOD"
                    }
                },
            }
        ]
        return (
            <div className="container-fluid">
                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="col-xl-12 h3 mb-0 text-gray-800">E-Pod
                    </h1>
                </div>
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row mb-1">
                    <div className='card col-xl-12 col-md-12'>
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-2">
                                    <label>Start Date </label>
                                    <Datetime value={this.state.from_date}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete: 'off', readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat = {false}
                                        name="fromDate"
                                        onChange={this.handlerStartDeliveryDateTime} />
                                </div>
                                <div className="col-2">
                                    <label>End Date </label>
                                    <Datetime
                                        value={this.state.to_date}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'To Date', name: 'toDate', autoComplete: 'off', readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat = {false}
                                        name="toDate"
                                        onChange={this.handlerEndDeliveryDateTime} />
                                </div>
                                {/* <div className="col-2">
                                    <label className="">Shipping Type</label>
                                    <Select
                                        value={this.state.shipType}
                                        name="shipType"
                                        options={[
                                            { value: "all", label: "All" },
                                            { value: "05", label: "Courier by surface" },//05 
                                            { value: "07", label: "Courier by air" }, //07
                                            { value: "22", label: "Courier by Train" },//22 
                                            { value: "PTL", label: "Part truck Load" }, //47,48 
                                            { value: "FTL", label: "Full truck Load" },//"01", "02", "03", "04", "10", "11", "12", "14", "15", "17", "18", "21", "24", "25","29", "30", "31", "32", "45", "46"
                                        ]}
                                        onChange={this.onChangeShipType.bind(this)}
                                    />
                                </div> */}
                                {localStorage.getItem("user_type") != "TRANSPORTER" ?
                                <div className="col-2">
										<label>Transporter   </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											closeMenuOnSelect={true}
											id="selectedTransporter"
											style={{ borderRadius: "0px" }}
											value={this.state.flTransporter}
											onChange={this.newFilter.bind(this, "flTransporter")}
											options={this.state.filterTransporters}
										/>
									</div>
                                    :""}
                                <div className="form-group col-xl-3 col-lg-3"   >
                                    <button className="btn btn-success mt-30p" type="submit" onClick={this.submitTransporterType.bind(this)}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card col-xl-12 col-md-12 beffect'>
                        <div className="card-body crm-numbers pb-0">
                            <div className="row">
                                <div className="col cursorDefault">
                                    <div className=" cirlce-d" onClick={() => this.setCounterData('total')}>
                                        <span className="sptile"><i className="fa fa-cubes f24 greenfont"></i><br /> Total PODs</span>
                                        <h4 className="txt-warning f40"><span className="counter"><CountUp end={this.state.totalrecords.length} /></span></h4>
                                    </div>
                                </div>
                                <div className="col cursorDefault" onClick={this.onClickTab.bind(this,"pending")}>
                                    <div className=" cirlce-d">
                                        <span className="sptile"><i className="fa fa-cubes f24 greenfont"></i><br /> Pending To Upload</span>
                                        <h4 className="txt-danger f40"><span className="counter"><CountUp end={pending.length} /></span></h4>
                                    </div>
                                </div>
                                <div className="col cursorDefault">
                                    <div className=" cirlce-d" onClick={this.onClickTab.bind(this,"closed")}>
                                        <span className="sptile"><i className="fa fa-cubes f24 greenfont"></i><br />Closed PODs</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.totalrecords.length-pending.length} /></span></h4>
                                    </div>
                                </div>
                                <div className="col cursorDefault" onClick={this.onClickTab.bind(this,"invalid")}>
                                    <div className=" cirlce-d">
                                        <span className="sptile"><i className="fa fa-cubes f24 greenfont"></i><br />Invalid Docket</span>
                                        <h4 className="txt-success f40"><span className="counter"><CountUp end={this.state.invalidDocketRecords.length} /></span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card col-xl-12 col-md-12 beffect'>

                        <div className='card-body'>
                            <div className="card-header d-sm-flex align-items-center justify-content-between mb-4">
                               
                                <div class="button-group">
                                    <button type="button" className={"btn "+(this.state.tabOne)} onClick={this.onClickTab.bind(this,"pending")}>Pending</button>
                                    <button type="button" className={"btn "+(this.state.tabTow)} onClick={this.onClickTab.bind(this,"closed")}>Closed</button>
                                    <button type="button" className={"btn "+(this.state.tabThree)} onClick={this.onClickTab.bind(this,"invalid")}>Invalid Docket</button>
                                </div>
                                <div className="col-2">
                                    {/* <label className="">Shipping Type</label> */}
                                    <Select
                                        value={this.state.shipType}
                                        placeholder="Shipping Type"
                                        name="shipType"
                                        options={[
                                            { value: "all", label: "All" },
                                            { value: "05", label: "Courier by surface" },//05 
                                            { value: "07", label: "Courier by air" }, //07
                                            { value: "22", label: "Courier by Train" },//22 
                                            { value: "PTL", label: "Part truck Load" }, //47,48 
                                            { value: "FTL", label: "Full truck Load" },//"01", "02", "03", "04", "10", "11", "12", "14", "15", "17", "18", "21", "24", "25","29", "30", "31", "32", "45", "46"
                                            { value:"86", label: "Adjustment Load"},
                                            { value:"74", label: "Multi Point Delivery"},
                                            { value:"ODC", label: "Over Dimensional Cargo"},
                                            { value:"HD", label: "Hand Delivery"}
                                        
                                        ]}
                                        onChange={this.onChangeShipType.bind(this)}
                                    />
                                </div>

                            </div>

                            {/* <div className="col-xl-3 col-lg-3 form-group">
                                <label>Select Month To Get Monthly Data</label>
                                <Datetime
                                    value={this.state.startDate}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: 'From', name: 'start_date', autoComplete: 'off', readOnly: true }}
                                    dateFormat="YYYY-MM"
                                    name="start_date"
                                    onChange={this.handlerStartDateTime} />
                            </div> */}

                            <div className="row">
                                <div className="col-xl-12 col-md-12 mb-4">
                                    <div className="card-body card shadow h-100 py-2">

                                        <div className="row">
                                            <div className="col-sm-12">
                                                {
                                                /*(this.state.tabOne === 'btn-current')?
                                                <button className="float-right btn btn-warning" onClick={this.setBulkUploadScan}>
                                                    Upload Scan QR
                                                </button>
                                                :""
                                                */}  
                                                {(this.state.tabOne === 'btn-current')?
                                                <button className="float-right btn btn-info" onClick={this.setBulkUploadEpod}>
                                                    Bulk Upload E-Pod
                                                </button>
                                                :""}
                                            </div>
                                        </div>
                                        
                                        <div style={{ width: "100%", height: "70vh" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={modules}
                                                rowData={rowData}
                                                columnDefs={columnDefs}
                                                defaultColDef={defaultColDef}
                                                enableRangeSelection={true}
                                                enableCharts={true}
                                                detailCellRendererParams={detailCellRendererParams}
                                                masterDetail={true}
                                                onGridReady={this.onGridReady}
                                                sideBar={sideBar}
                                                frameworkComponents={frameworkComponents}
                                                statusBar={statusBar}
                                                stopEditingWhenGridLosesFocus={true}
                                                paginationPageSize={paginationPageSize}
                                                suppressRowClickSelection={true}
                                                pagination={true}
                                                headerHeight={50}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                rowSelection={rowSelection}
                                                onCellClicked={this.onCellClicked}

                                            />
                                        </div>

                                    </div>

                                </div>

                                <div className={"dataLoadpage " + (loadshow)}>
									</div>
									<div className={"dataLoadpageimg " + (loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
									<div className={"overlay-part " + (overly)} onClick={this.onClickHideAll}></div>


                                
                            </div>

                            

                        </div>

                    </div>
                    {/* manual_upload_slider */}
                    <div className={"slide-r " + (manual_upload_slider)} style={{ overflow: "hidden" }}>
                                    <h3 className="subH">Upload Manual Orders
                                        <span
                                            className="float-right closebtn"
                                            style={{ marginRight: "25px" }}
                                            onClick={this.onCloseSliderDiv}
                                        >
                                            X
                                        </span>
                                    </h3>
                                    <div className="slide-r-body" style={{ position: "relative" }}>
                                        <div className="container-fluid">
                                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadManualBulkFormHandler}>
                                                <div className="row form-group col-xl-12 col-lg-12">
                                                    <label className="col-xl-4 col-lg-4">Select Gc Way Bill No</label>
                                                    <Select
                                                        className="border-radius-0 col-xl-6 col-lg-6"
                                                        isMulti={false}
                                                        id="selectedGcWayBillNo"
                                                        value={gc_way_bill_No_sel}
                                                        onChange={this.setSelectedGcWayNo}
                                                        style={{ borderRadius: "0px" }}
                                                        options={gcWayBillList} required />
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Upload File</label>
                                                    <input type="file" id="manualUploadFile" name="uploadFile" onChange={this.changeManualFileHandler} className="form-control" required />
                                                </div>
                                                {/* <div className='p-2' id="appednCSVData"></div> */}
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-success">Submit</button>
                                                    <button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
                                                </div>
                                            </form>
                                            {/* <p className='pl-3' style={{ color: 'red' }}> please format the dates in yyyy-mm-dd hh:mm before uploading</p> */}

                                            <div className="form-group">
                                                <a className="btn btn-primary" href={require('../../assets/json/bulk_manual_cons_upload.csv')} target="_blank">Sample Template</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* multiple bulk uploads of pods */}
                            <div className={"slide-r " + (this.state.bulkslidershow)}>
                                <div className="slide-r-title">
                                    <h4>Upload Bulk POD
                                        <span
                                            className="float-right closebtn"
                                            style={{ marginRight: "25px" }}
                                            onClick={this.onCloseSliderDiv}
                                        >
                                            X
                                        </span>
                                    </h4>
                                </div>
                                <div className="col-sm-12">
                                    <div className="slide-r-body">
                                        <form className="theme-form" id="formBulkPOD" method="POST" enctype="multipart/form-data" onSubmit={this.uploadDocument}>
                                            <div className="row p-20p">

                                                <div className="form-group col-sm-12 mb-20p">
                                        <label>Delivered Date<span className="rclr fbold">*</span></label>
                                        <Datetime
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: '', name: 'startDate',id: 'startDate', autoComplete: 'off', readOnly: true }}
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat= {false}
                                            // value={this.state.startDate}
                                            // timeFormat="HH:mm"
                                            name="startDate"
                                            value={this.state.delivered_date}
                                            onChange={this.changeDateTimeValue.bind(this)}
                                        />
                                    </div>
                                                <p className='pl-3' style={{ color: 'red' }}> please rename the file with gc_waybill_no, Before uploading DeliveryDate, e.g. gcwaybillno_28-02-2023</p>
                                                <p className='pl-3' style={{ color: 'red' }}> if gc_waybill_no mentioned in file name matches with row gc_waybill_no, then it is uploaded by mapping the file to that row.</p>
                                                <div className="form-group col-sm-12 mb-20p">
                                                    <label>Upload Document<span className="rclr fbold">*</span></label>
                                                    <input type="file" name="podDoc" id="podDoc" className="form-control" onChange={this.changeBulkFileHandler} required multiple />
                                                </div>
                                                {/* {multiplePodDocs.length > 0 ?
                                                    <>
                                                        <ul>
                                                            {multiplePodDocs.map((e, i) => (
                                                                <li key={i} className="p2">{i} .{e.name}</li>
                                                            ))}
                                                        </ul> */}
                                                        <div className="form-group col-sm-12 mb-20p text-center">
                                                            <button type="submit" className="btn btn-success">Upload</button>
                                                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>Cancel</button>
                                                        </div>
                                                    {/* </> : "" */}
                                                {/* // } */}

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className={"slide-r " + (this.state.sliderscanshow)}>
                                <div className="slide-r-title">
                                    <h4>Upload Bulk Scan code
                                        <span
                                            className="float-right closebtn"
                                            style={{ marginRight: "25px" }}
                                            onClick={this.onCloseSliderDiv}
                                        >
                                            X
                                        </span>
                                    </h4>
                                </div>
                                <div className="col-sm-12">
                                    <div className="slide-r-body">
                                        <form className="theme-form" id="formBulkSingle" method="POST" enctype="multipart/form-data" onSubmit={this.uploadScanCode}>
                                            <div className="row p-20p">

                                                <div className="form-group col-sm-12 mb-20p">
                                        <label>Delivered Date<span className="rclr fbold">*</span></label>
                                        <Datetime
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{ placeholder: '', name: 'startDate', autoComplete: 'off', readOnly: true }}
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            
                                            name="startDate"
                                            value={this.state.delivered_date}
                                            onChange={this.changeDateTimeValue.bind(this)}
                                        />
                                    </div>
                                                <p className='pl-3' style={{ color: 'red' }}> please rename the file with gc_waybill_no , Before uploading DeliveryDate, e.g. gcwaybillno_28-02-2023</p>
                                                <p className='pl-3' style={{ color: 'red' }}> if gc_waybill_no mentioned in file name matches with row gc_waybill_no, then it is uploaded by mapping the file to that row.</p>
                                                <div className="form-group col-sm-12 mb-20p">
                                                    <label>Upload Document<span className="rclr fbold">*</span></label>
                                                    <input type="file" multiple name="File" id="File" className="form-control" onChange={this.
                                                        changeBulkFileSaveCode} required />
                                                </div>
                                                {/* {multiplePodDocs.length > 0 ?
                                                    <>
                                                        <ul>
                                                            {multiplePodDocs.map((e, i) => (
                                                                <li key={i} className="p2">{i} .{e.name}</li>
                                                            ))}
                                                        </ul> */}
                                                        <div className="form-group col-sm-12 mb-20p text-center">
                                                            <button type="submit" className="btn btn-success">Upload</button>
                                                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>Cancel</button>
                                                        </div>
                                                    {/* </> : "" */}
                                                {/* // } */}

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                             {/*sliderBlock2 ,crd-bg p-10p, slide-body, theme  */}
                <div className={"slide-r " + (this.state.slidershow)}>
                    <div className="slide-r-title">
                        <h4>Upload POD
                            <span
                                  className="float-right closebtn"
                                  style={{ marginRight: "25px" }}
                                  onClick={this.onCloseSliderDiv}          
                                        >
                                 X
                            </span>
                        </h4>
                    </div>
                    

                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form className="theme-form" method="POST" id="formPOD" enctype="multipart/form-data" onSubmit={this.uploadDocument}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Delivered Date<span className="rclr fbold">*</span></label>
                                        {/* <input type="text" name="delivered_date" id="delivered_date" value={this.state.delivered_date} className="form-control" onChange={this.changeHandler} required /> */}
{/*                                         
                                        <Datetime
                                            inputProps={{ placeholder: '', name: 'startDate', autoComplete: 'off', readOnly: true }}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            // timeFormat="HH:mm"
                                            name="startDate"
                                            value={this.state.delivered_date}
                                            onChange={this.changeDateTimeValue.bind(this)}
                                        /> */}
                                        <input type="text" name="startDate" className="form-control" value={this.state.delivered_date} required />

                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Upload Document<span className="rclr fbold">*</span></label>
                                        <input type="file" name="epodDoc" id="epodDoc" className="form-control" onChange={this.changeFileHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Upload</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.update_row_slider)} style={{ overflow: "hidden" }}>
					<h3 className="subH"> Update Row Details
						<span
							className="float-right closebtn"
							style={{ marginRight: "25px" }}
							onClick={this.onCloseSliderDiv}
						>
							X
						</span>
					</h3>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onUpdateConsignmentDetails}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Transit Date*</label>
									<Datetime
										value={this.state.transit_data_for_update}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'Transit Date', name: 'transitdate', autoComplete: 'off' }}
										dateFormat="YYYY-MM-DD"
										timeFormat="HH:mm:ss"
										name="transitdate"
										className="col-xl-12 col-lg-12"
										onChange={this.handlerTransitDateTime.bind(this)}
									/>
                                    <p className = {`${this.state.errMsg} text-danger`}>Transit date should be greater than or equal to gc date</p>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Location*</label>
									<input type="text" placeholder="Enter Location"  id="location_for_update" name="location_for_update" className="form-control col-xl-12 col-lg-12" style={{marginLeft:'15px'}}  />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Status*</label>
									<Select
										className="col-xl-12"
										onChange={(e) =>this.setState({
											status_for_update : e,
											show_delivered_date : e.value == 'Delivered' ? true : false
										})}
										value={this.state.status_for_update}
										options={[
											{label : 'In Transit' , value : 'In Transit'},
											{label : 'Out For Delivery' , value : 'Out For Delivery'},
											{label : 'Delivered' , value : 'Delivered'},
											{label : 'CANCELLED' , value : 'CANCELLED'},
										]}
										required={true}
									/>
								</div>

								{this.state.show_delivered_date ?
									<>
										<div className="col-xl-12 col-lg-12 row form-group">
											<label className="col-xl-12 col-lg-12">Delivered Date *</label>
											<Datetime
												value={this.state.delivered_date_for_update}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'Delivery Date', autoComplete: 'off' }}
												dateFormat="YYYY-MM-DD"
												timeFormat="HH:mm:ss"
												className="col-xl-12 col-lg-12"
												onChange={this.handlerUpdateDeliveredDateTime.bind(this)}
											/>
										</div>
										<div className="form-group col-sm-12 mb-20p">
											<label>Upload Document</label>
											<input type="file" name="epodDocforupdate" id="epodDocforupdate" className="form-control" onChange={this.changeFileHandlerForupdate}   />
										</div>
									</>
									: ""}
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>

						</div>
					</div>
				</div>
            </div>
        )
    }
}

function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map11.get(key);
        if (!collection) {
            map11.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map11;
}