
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Datetime from 'react-datetime';
import Modal from 'react-responsive-modal';
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import 'react-datetime/css/react-datetime.css';
import redirectURL from '../redirectURL';

// import { useParams } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import ExportIcon from "../../assets/icons/export-arrow.svg";
import ImportIcon from "../../assets/icons/import.svg";
import Filter from '../../assets/icons/SEARCH.png'
import CountdownTimer from './runningtime.js';

//  import io from 'socket.io-client';
import { getHyphenYYYYMMDDHHMM,getHyphenYYYYMMDD } from '../common/utils';
// import { FaEdit, FaTrash, FaEye, FaTrashAlt, FaArrowRight, FaCheck, FaPen, FaBell, FaRegBell } from 'react-icons/fa';

const unitsOptions = [
    { value: "truck", label: "FTL" },
    { value: "metric_tons", label: "PTL" },
    { value: "kilogram", label: "Courier" },
    { value: "box", label: "FTL/PTL" }]

var moment = require('moment');


const customSelectStyles = {
    option: (provided) => ({
        ...provided,
        fontSize: '14px',
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '14px',
    }),
};


const Freightrateapprovalsummarycomponent = () => {
    // const { event_id } = useParams()
    const [open, setopen] = useState(false)
    const [openchat, setopenchat] = useState(false)
    const [open1, setopen1] = useState(false)
    const [selectedrowfornegotiate, setselectedrowfornegotiate] = useState({})
    const [openforadmin, setopenforadmin] = useState(false)
    const [baseprice, setbaseprice] = useState(0)
    const [selectedindent, setselectedindent] = useState({})
    const [uploadpaymentterms, setuploadpaymentterms] = useState('')
    const [loadshow, setLoadshow] = useState("show-n")
    const [overlay, setOverlay] = useState("show-n")
    const [isToggled, setIsToggled] = useState(0);  // 0 for off, 1 for on
    const [show, setShow] = useState(false)
    const [hoveredIconId, setHoveredIconId] = useState(null);
    const [hidebidnowbtn, sethidebidnowbtn] = useState(false)
    var [filterToggle, setFilterToggle] = useState(false)
    const [basicType, setBasicType] = useState("")
    const [basicTitle, setBasicTitle] = useState("")
    const [newBidRate, setNewBidRate] = useState('');
    const [newBidQuantity, setNewBidQuantity] = useState('');
    const [activeDateBtn, setactiveDateBtn] = useState('')
    var filterCardStyle1 = { minHeight: "234px" };
    const [showTooltip, setShowTooltip] = useState(false);
    const [hoveredIcon, setHoveredIcon] = useState(null)
    var filterCardStyle2 = { padding: '20px' };
    var mnFilterClass = { marginLeft: "0px", padding: "0px" };
    const [negotiatedetails, setnegotiatedetails] = useState([])
    const [rfqform, setrfqform] = useState({
        active: 'activet1',
        awarded: '',
        drafts: '',
        cancelled: '',
        myactivity: '',
        archieved: '',
        upcoming: '',
        selectedindentid: '',
        completed: '',
        current_bidding: 'activet',
        bidding_history: '' // Optionally, clear other tabs

    })
    const [indentdetails, setindentdetails] = useState({
        indent_id: '',
        bid_start_date: '',
        end_date: '',
        source: '',
        destination: '', distance: '',
        truck_type: '',
        material: '',
        quantity: 0,
        base_price: 0,
        quote: 0
    })
    const [biddetails, setbiddetails] = useState([])
    const [allindents, setallindents] = useState([])
    const [bulkUploadModal, setBulkUploadModal] = useState({
        overly: "show-n",
        bulkPrioritySlide: ""
    })
    const [modalloadData, setmodalloadData] = useState([])
    const [filterdata, setfiltereddata] = useState([])
    const [originalindents, setoriginalindents] = useState([])
    const [rankingdetails, setrankingdetails] = useState([])
    const [selected_bid_price, setselected_bid_price] = useState('')
    const [negotiate_value, setnegotiate_value] = useState('')

    const [selected_bid_quantity, setselected_bid_quantity] = useState('')
    const [transit_time, settransit_time] = useState('')

    const [bidsdata, setbidsdata] = useState([])
    const [rankedBids, setRankedBids] = useState([]);
    const [searchfilter, setsearchfilter] = useState({})
    const [isCardOpen, setIscardOpen] = useState("")
    const [isCardOpen1, setIscardOpen1] = useState("")
    const [isCardOpen2, setIscardOpen2] = useState("")
    const [isCardOpen3, setisCardOpen3] = useState('')
    const [indent_for_approve, setindent_for_approve] = useState([]);

    const [indent_for_approve_for_bid, setindent_for_approve_for_bid] = useState([]);

    const [indent_for_approve1, setindent_for_approve1] = useState([]);

    const [selecteddata, setselecteddata] = useState({})
    const [showAllRecords, setShowAllRecords] = useState(false);

    const [bidHistory, setBidHistory] = useState([])
    const [inputTextValue, setInputTextValue] = useState("")
    const [inputTextQuantity, setInputTextQuantity] = useState("")
    const [negotiations, setNegotiations] = useState([])
    const [negotiationsHistory, setNegotiationsHistory] = useState([])
    const [isProposedInputOpen, setIsProposedInputOpen] = useState("")
    const [isVendorInputOpen, setIsVendorInputOpen] = useState("")
    const [additionalComments, setAdditionalComments] = useState("")
    const [modelForAdditionalComments, setModelForAdditionalComments] = useState(false)
    const [vendorQuotes, setVendorQoutes] = useState([])
    const [modelForShowBids, setModelForShowBids] = useState(false)
    const [modelForNotifications, setModelForNotifications] = useState(false)
    const [notificationUnread, setNotificationUnread] = useState([])
    const [notificationRead, setNotificationRead] = useState([])
    const [modelForEditAssigned, setModelForEditAssigned] = useState(false)
    const [rejectedVendorBid, setRejectedVendorBid] = useState("")
    const [rejectedVendorIndent, setRejectedVendorIndent] = useState("")
    const [rejectionReason, setRejectionReason] = useState("")

    const [timeLeft, setTimeLeft] = useState(null);
    const [text, setText] = useState('');
    var [destinationdetails, setdestinationdetails] = useState([])

    const getbidhistory = async (indent_id) => {
        console.log(indent_id)
        await redirectURL.post("/consignments/getBidHistory", { indent_id }).then(response => {
            setBidHistory(response.data.bid_history)
            setNegotiations(response.data.negotiations)
            setNegotiationsHistory(response.data.negotiationslogs)
        })
    }
    useEffect(() => {
        
        fetchData();

    }, []);
    const fetchData = ()=>{
        setLoadshow('show-m')
        setOverlay('show-m')
        var params = {
            user_type: localStorage.getItem("user_type"),
            email: localStorage.getItem("email")
        }
        redirectURL.post("/consignments/fetchRFQdataforsourcewise", params).then(response => {
            var data = response.data;
            console.log(data,'173')
            data = data.filter((e)=>{
                var indent_assigned = getHyphenYYYYMMDD(e.indent_assigned_date)
                console.log(indent_assigned,'indent_assigned')
                const current_date = getHyphenYYYYMMDD(new Date());
    return indent_assigned == current_date;
            })
        const sourcedata = segregateBySource(data);
        console.log(sourcedata,'174')

        setallindents(sourcedata)
        setLoadshow('show-n')
        setOverlay('show-n')
        })
    }
    const segregateBySource = (array) => {
        const result = {};
    
        array.forEach(item => {
            const source = item.source;
    
            // If the source key doesn't exist, initialize it with an empty array
            if (!result[source]) {
                result[source] = [];
            }
    
            // Push the current item into the corresponding source group
            result[source].push(item);
        });
    
        return result;
    };
    const onClickHideall = () => {
        setLoadshow('show-m')
        setOverlay('show-n')

    }
    const onShowdealerdata = (indent_id) => {
        console.log(indent_id, 'indent_id')
        setisCardOpen3(true)
        var params = {
            indent_id: indent_id
        }
        redirectURL.post('/consignments/getstroreddata', params).then((resp) => {
            var data1 = resp.data
            var destinations = []
            data1.map((e) => {
                destinations.push({
                    "indent_id": indent_id,
                    "dealer_city": e.dealer_city,
                    'dealer_pincode': e.dealer_pincode,
                    "bid_price": e.bid_price,
                    "transit_days": e.transit_days
                })
            })
            setindent_for_approve1(destinations)

        })

    }
    const onClickAcceptNegotiation = (indent_id, bid_price, no_of_deliveries, vendor_email_id, indent_created_by) => {
        if (localStorage.getItem('roles') == 'TRANSPORTER') {
            setIscardOpen1(true)
            var data = originalindents.filter(e => e.indent_id == indent_id)
            console.log(data, '371')
            console.log(data[0].destination, 'data[0].destination')
            var destinations = []
            var data1 = data[0].destination
            data1 = JSON.parse(data1);

            data1.map((e) => {
                destinations.push({
                    "indent_id": indent_id,
                    "dealer_city": e.dealer_city,
                    'dealer_pincode': e.dealer_pincode
                })
            })
            setindent_for_approve(destinations)
            setselecteddata({
                indent_id: indent_id,
                bid_price: bid_price,
                no_of_deliveries: no_of_deliveries,
                vendor_email_id: vendor_email_id,
                indent_created_by: indent_created_by
            });
        }
        else if (localStorage.getItem('roles') == 'AL_ADMIN') {
            const params = {
                indent_id,
                bid_price,
                no_of_deliveries,
                vendor_email_id,
                email: localStorage.getItem("email"),
                user_type: localStorage.getItem("user_type"),
                indent_created_by
            };

            redirectURL.post("/consignments/acceptNegotiationValue", params).then(response => {
                const isSuccess = response.data.status === "success";
                setShow(true);
                setBasicType(isSuccess ? "success" : "danger");
                setBasicTitle(response.data.message);

                if (isSuccess) {
                    setIsVendorInputOpen("");
                    setIsProposedInputOpen("");
                    // socket.current.emit('bidUpdate', []);
                }
            }).catch(error => {
                console.log(error)
                setShow(true);
                setBasicType("danger");
                setBasicTitle("An error occurred while processing the request.");
            });
        }
    };
    const onShowdata = (indent_id) => {
        setIscardOpen2(true)
        var params = {
            indent_id: indent_id
        }
        redirectURL.post('/consignments/getstroreddata', params).then((resp) => {
            var data1 = resp.data
            var destinations = []
            data1.map((e) => {
                destinations.push({
                    "indent_id": indent_id,
                    "dealer_city": e.dealer_city,
                    'dealer_pincode': e.dealer_pincode,
                    "bid_price": e.bid_price,
                    "transit_days": e.transit_days
                })
            })
            setindent_for_approve1(destinations)

        })

    }

    const onClickAcceptQuotation = (indent_id, bid_price, no_of_deliveries, vendor_email_id, indent_created_by) => {
        const params = {
            indent_id,
            bid_price,
            no_of_deliveries,
            vendor_email_id,
            email: localStorage.getItem("email"),
            user_type: localStorage.getItem("user_type"),
            indent_created_by
        };
        console.log(params, 'params')
        redirectURL.post("/consignments/AcceptQuotation", params).then(response => {
            const isSuccess = response.data.status === "success";
            setShow(true);
            setBasicType(isSuccess ? "success" : "danger");
            setBasicTitle(response.data.message);

            if (isSuccess) {
                setIsVendorInputOpen("");
                setIsProposedInputOpen("");
                setIscardOpen1(false)
                //  socket.current.emit('bidUpdate', []);
            }
        })
            .catch(error => {
                console.log(error)
                setShow(true);
                setBasicType("danger");
                setBasicTitle("An error occurred while processing the request.");
            });
    };
    const onCloseModal = () => {
        setModelForAdditionalComments(false)
        setModelForShowBids(false)
        setModelForNotifications(false)
        setModelForEditAssigned(false)
        setIscardOpen1(false)
        setIscardOpen2(false)
        setisCardOpen3(false)
    };
    const fetchNotificationsData = (indent_id) => {
        var params = {
            email: localStorage.getItem("email"),
            indent_id: indent_id
        }
        redirectURL.post("/consignments/fetchNotificationsData", params).then(response => {
            setNotificationUnread(response.data.Unreadnotifications)
            setNotificationRead(response.data.Readnotifications)
            setModelForNotifications(true)
            const indent = allindents.find(e => e.indent_id === indent_id);
            if (indent) {
                indent.notification_count = 0;
            }
        })
    }


    const modalStyles = {
        width: '500px !important',
    }
    const modalStyles1 = {
        width: '300px !important',
    }
    const columnwithdefs = [
        {
            headerName: "Rank",
            field: "truck_no",
            width: 200,
            resizable: true,
        },
        {
            headerName: "Bid Price",
            field: "truck_no",
            width: 100,
            resizable: true,
        },
        {
            headerName: "Bid Quantity",
            field: "truck_no",
            width: 100,
            resizable: true,
        },
    ]

    const handleSelectChange = (name, selectedOption) => {
        // Check if selectedOption is not null before accessing value
        setsearchfilter({
            ...searchfilter,
            [name]: selectedOption ? selectedOption.value : null
        });
    };
  
    const getindents = (e) => {
        e.preventDefault();
        let data = []
        if (rfqform.active === 'activet') {
            data = originalindents.filter(e => e.indent_expired != 1)
        }
        else if (rfqform.cancelled === 'activet') {
            data = originalindents.filter(e => e.indent_expired == 1)
        }
      
    };
    const handleInputChangeforbid = (index, field, value) => {
        const updatedDestinations = [...indent_for_approve_for_bid];
        updatedDestinations[index][field] = value; // Update the specific field
        setindent_for_approve_for_bid(updatedDestinations); // Update state
    };
    const handleInputChangeforaccept = (index, field, value) => {
        const updatedDestinations = [...indent_for_approve];
        updatedDestinations[index][field] = value; // Update the specific field
        setindent_for_approve(updatedDestinations); // Update state
    };
    const handleInputChangeforaccept1 = (index, field, value) => {
        const updatedDestinations = [...indent_for_approve1];
        updatedDestinations[index][field] = value; // Update the specific field
        setindent_for_approve1(updatedDestinations); // Update state
    };

    const onSubmitdata = (e) => {
        const hasInvalidFields = indent_for_approve.some(
            (destination) =>
                !destination.bid_price || !destination.transit_days
        );

        if (hasInvalidFields) {
            alert("Please fill in all Bid Price and Transit Days fields before submitting.");
            return;
        }
        const submissionData = indent_for_approve.map((destination) => ({
            indent_id: destination.indent_id,
            dealer_city: destination.dealer_city,
            dealer_pincode: destination.dealer_pincode,
            bid_price: destination.bid_price,
            transit_days: destination.transit_days,
        }));
        console.log("Prepared submission data:", submissionData);
        var params1 = {
            submissionData: submissionData
        }
        redirectURL.post('/consignments/storedata', params1).then((resp) => {

        })
        var { indent_id, bid_price, no_of_deliveries, vendor_email_id, indent_created_by } = selecteddata
        const params = {
            indent_id,
            bid_price,
            no_of_deliveries,
            vendor_email_id,
            email: localStorage.getItem("email"),
            user_type: localStorage.getItem("user_type"),
            indent_created_by
        };

        redirectURL.post("/consignments/acceptNegotiationValue", params).then(response => {
            const isSuccess = response.data.status === "success";
            setShow(true);
            setBasicType(isSuccess ? "success" : "danger");
            setBasicTitle(response.data.message);
            setIscardOpen1(false)

            if (isSuccess) {
                setIsVendorInputOpen("");
                setIsProposedInputOpen("");
                // socket.current.emit('bidUpdate', []);
            }
        }).catch(error => {
            console.log(error)
            setShow(true);
            setBasicType("danger");
            setBasicTitle("An error occurred while processing the request.");
        });



    }
    const onSubmitdata1 = (e) => {
        const hasInvalidFields = indent_for_approve.some(
            (destination) =>
                !destination.bid_price || !destination.lr_no
        );

        if (hasInvalidFields) {
            alert("Please fill LR No field before submitting");
            return;
        }
        const submissionData = indent_for_approve1.map((destination) => ({
            indent_id: destination.indent_id,
            dealer_city: destination.dealer_city,
            dealer_pincode: destination.dealer_pincode,
            bid_price: destination.bid_price,
            transit_days: destination.transit_days,
            lr_no: destination.lr_no
        }));
        console.log("Prepared submission data:", submissionData);
        var params1 = {
            submissionData: submissionData
        }
        redirectURL.post('/consignments/storelrno', params1).then((resp) => {
            if (resp.data == 'success') {
                setShow(true);
                setBasicType("success");
                setBasicTitle('Successfully Submitted');
                setIscardOpen2(false)
            }

        })
            .catch(error => {
                console.log(error)
                setShow(true);
                setBasicType("danger");
                setBasicTitle("An error occurred while processing the request.");
            });


    }

    const handleInputChange = (e) => {
        setselected_bid_quantity(e)
        // setNewBidQuantity(e)
    };
    const handlertransittime = (e) => {
        settransit_time(e)
    }
    const handleInputChange1 = (e) => {
        setselected_bid_price(e)
        // setNewBidRate(e)
    };
    const handleInputChange2 = (e) => {
        setnegotiate_value(e)
    };

    const onSubmitquote = (e) => {
        e.preventDefault()
        console.log(indent_for_approve_for_bid, 'biddetails')
        var selected_bid_price = 0
        var selected_bid_quantity = selectedindent.destination
        selected_bid_quantity = JSON.parse(selected_bid_quantity)
        console.log(selected_bid_quantity.length, 'selected_bid_quantityselected_bid_quantity')
        var transit_time = 0
        indent_for_approve_for_bid.forEach(item => {
            selected_bid_price += parseFloat(item.bid_price); // Sum the bid prices
            transit_time += parseInt(item.transit_days); // Sum the transit days
        });

        var params = {
            bid_price: selected_bid_price,
            no_of_deliveries: selected_bid_quantity.length,
            email: localStorage.getItem('email'),
            indent_id: selectedindent.indent_id,
            username: localStorage.getItem('user_name'),
            transit_time: transit_time
        }
        redirectURL.post('consignments/submitQuote', params).then((response) => {
            if (response.data.status == 'success') {
                setIscardOpen("")
                setShow(true)
                setBasicType("success")
                setBasicTitle('Your Bid has been successfully placed')
                setselected_bid_price("")
                setselected_bid_quantity("")
                // if (response.data.status == "success") socket.current.emit('bidUpdate', []);
                // setopen(false)
                // fetchgetindents()
            }
        })


    }

    const closeAlert = () => {
        setShow(false)
        setopen(false)
    }

    const onIconHover = (rowId) => {
        setHoveredIconId(rowId); // Set the hovered icon ID to the specific row ID
    };
    const onClickparentTab = (e) => {
        var current_date = new Date()
        let data = []
        if (e === 'Expired') {
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: 'activet1',
                active: '',
                completed: '',
                awarded: '',
                drafts: '' // Optionally, clear other tabs
            }));

            data = []
            if (localStorage.getItem('user_type') === 'TRANSPORTER') {
                data = originalindents.filter((e) => {
                    // Check if `vendorquotes` exists and is an array
                    if (e.vendorquotes && Array.isArray(e.vendorquotes)) {
                        // Filter `vendorquotes` to find entries that match the email and have `bid_winner` set to 0
                        const vendorquote = e.vendorquotes.find(
                            (quote) =>
                                quote.vendor_email_id === localStorage.getItem('email') &&
                                quote.bid_winner === 0
                        );
                        // Return `true` if a matching vendor quote is found, else `false`
                        return vendorquote !== undefined;
                    }
                    return false; // If `vendorquotes` is empty or not an array, exclude this indent
                });
            }

            //  data = originalindents.filter(e => {
            //     const bidEndDate = moment.parseZone(e.bid_start_date)
            //         .utcOffset("-05:30")
            //         .add(parseInt(e.quotation_validity_hours, 10), 'hours');

            //     return current_date < bidEndDate && (e.vendordetails && e.vendordetails.length === 0);
            // });

        }
        if (e === 'Awarded') {
            setrfqform((prevForm) => ({
                ...prevForm,
                awarded: 'activet1',
                cancelled: '',
                active: '',
                completed: '',
                drafts: '' // Optionally, clear other tabs
            }));
            data = []
            if (localStorage.getItem('user_type') === 'TRANSPORTER') {
                data = originalindents.filter((e) => {
                    // Check if `vendorquotes` exists and is an array
                    if (e.vendorquotes && Array.isArray(e.vendorquotes)) {
                        // Filter `vendorquotes` to find entries that match the email and have `bid_winner` set to 0
                        const vendorquote = e.vendorquotes.find(
                            (quote) =>
                                quote.vendor_email_id === localStorage.getItem('email') &&
                                quote.bid_winner === 1
                        );
                        // Return `true` if a matching vendor quote is found, else `false`
                        return vendorquote !== undefined;
                    }
                    return false; // If `vendorquotes` is empty or not an array, exclude this indent
                });
            }
            else {
                data = originalindents.filter(e => e.indent_assigned == 1)
            }

        }
        else if (e === 'Active') {
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: '',
                completed: '',
                awarded: '',
                active: 'activet1',
                drafts: '' // Optionally, clear other tabs
            }));
            // data = originalindents
            data = originalindents.filter(function (e) {
                return e.vendordetails && e.vendordetails.length > 0 && e.indent_assigned != 1;
            });

        }
        else if (e === 'Completed') {
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: '',
                completed: 'activet1',
                awarded: '',
                active: '',
                drafts: '' // Optionally, clear other tabs
            }));
            data = originalindents.filter(e => e.completed_indent == 1)
        }

        else if (e === 'Drafts') {
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: '',
                active: '',
                drafts: 'activet1' // Optionally, clear other tabs
            }));
            data = originalindents.filter(e => e.is_active == 0)
        }

    };
    const onclickapprovedata=()=>{
        setShow(true);
        setBasicType("success");
        setBasicTitle('Successfully Approved');
    }
    const onClickViewPOD = (e) => {
        // console.log("documnent_view", e)

        let params = {
            pod_document: e,
        }
        // console.log("params",params)
        redirectURL.post("/billing/downloadfileforroad", params, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
            }
        }).then(res => {
            console.log(res, '652')
            const downloadUrl = decodeURIComponent(res.data.url);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", e || ""); // Optional: specify download filename
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        }).catch(function (error) {
            // console.log(error);
        })
    }
    const columnDef = []
    console.log(bulkUploadModal, 'isVendorInputOpen')
    const toggleShowAllRecords = () => {
        setShowAllRecords((prev) => !prev);
    };
    return (
        <div className="container-fluid">
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="row d-flex align-items-center justify-content-between">
                <h5 className="col-sm-6 mt-30p fbold f20 mb-10p">
                    <span>Freight Rate Approval Summary - [{new Date().toISOString().split('T')[0]}]</span>
                </h5>
            </div>
           <div className='card'>
           <div className="row" style={{marginLeft:'35px',marginRight:'30px'}}>
    {Object.keys(allindents).map((source, index) => (
        <div className="col-xl-12 col-lg-12 mb-5" key={index}>
            {/* Source Heading */}
            <h5 className="col-sm-6 mt-30p">{source} 
                <span style={{color:''}}> - Dispatch</span>
            </h5>

            {/* Table */}
            <table className="table table-bordered table-hover text-center" style={{ width: '100%' }}>
    <thead className="table-primary">
        <tr>
            {/* Fixed headers */}
            <th>S.No</th>
            <th>Indent ID</th>
            <th>GR No</th>
            <th>Date</th>
            <th>From</th>
            <th>Destination</th>
            <th>Vehicle Type</th>
            <th>Material</th>
            <th>Freight Bifurcation</th>
            {/* Dynamic headers for vendor email IDs */}
            {allindents[source] &&
    allindents[source].length > 0 &&
    [...new Set(allindents[source].flatMap(e => e.vendorsquotedata.map(vendor => vendor.vendor_name)))].map((vendorName, idx) => (
        <th key={idx}>{vendorName || "Unknown Vendor"}</th>
    ))}

            <th>Tentative Savings</th>
        </tr>
    </thead>
    <tbody>
        {allindents[source] && allindents[source].length > 0 ? (
            allindents[source].map((e, idx) => {
                // Extract bid prices for the current indent's vendors
                const bidPrices = e.vendorsquotedata.map(vendor => vendor.bid_price || 0);
                const maxBidPrice = Math.max(...bidPrices);
                const minBidPrice = Math.min(...bidPrices);
                const tentativeSavings = maxBidPrice - minBidPrice;

                return (
                    <tr key={idx}>
                        {/* Fixed columns */}
                        <td>{idx + 1}</td>
                        <td>{e.indent_id || "N/A"}</td>
                        <td>{e.lr_no || "N/A"}</td>
                        <td>{getHyphenYYYYMMDD(e.indent_assigned_date) || "N/A"}</td>
                        <td>{e.source || "N/A"}</td>
                        <td>{e.dealer_city || "N/A"}</td>
                        <td>{e.type_of_truck || "N/A"}</td>
                        <td>{e.material || "N/A"}</td>
                        <td>{e.bid_price || "N/A"}</td>

                        {/* Dynamic columns for vendor bid prices */}
                        {Array.isArray(e.vendorsquotedata) &&
                            [...new Set(allindents[source].flatMap(el => el.vendorsquotedata.map(vendor => vendor.vendor_email_id)))].map((vendorEmail, idx) => {
                                const vendorData = e.vendorsquotedata.find(vendor => vendor.vendor_email_id === vendorEmail);
                                return <td key={idx}>{vendorData ? vendorData.bid_price : "N/A"}</td>;
                            })}

                        {/* Tentative Savings column */}
                        <td>{bidPrices.length > 1 ? tentativeSavings : "N/A"}</td>
                    </tr>
                );
            })
        ) : (
            <tr>
                <td colSpan={10 + (allindents[source] && allindents[source][0] && Array.isArray(allindents[source][0].vendorsquotedata) ? [...new Set(allindents[source].flatMap(e => e.vendorsquotedata.map(v => v.vendor_email_id)))].length : 0)}>
                    No data available for this source
                </td>
            </tr>
        )}
    </tbody>
</table>
<div className="col-md-12 d-flex justify-content-end">
                                            <button onClick={onclickapprovedata} type="submit" className="btn btn-success mr-2">Approve</button>
                                            <button type="button" className="btn btn-secondary">Reject</button>
                                        </div>



        </div>
    ))}
</div>
           </div>

            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay " + (overlay)} onClick={onClickHideall}></div>
        </div >
    );
};
export default Freightrateapprovalsummarycomponent;

function giveUTCDate(date) {
    return moment.parseZone(new Date(date)).utcOffset("-05:30")._d
}
const generateUniqueId = () => {
    return 'icon-' + Math.random().toString(36).substr(2, 9); // Generate a simple unique ID
};