import React from "react";

const EditCellRenderer = (props) => {
  const handleClick = (e) => {
    e.stopPropagation();
    // console.log("test ", props);
    props.onClick(props.data);
    // props.context.componentParent.onShowCourierTrack(props);
  };

  return (
    <div>
      <button
        onClick={handleClick}
        className="custom-btn label label-success"
        title="Edit Dealer"
        style={{ cursor: "pointer" }}
      >
        <i className="icofont icofont-pencil-alt-2" />
      </button>
    </div>
  );
};

export default EditCellRenderer;
