const axios = require('axios');

const axiosInstance = axios.create({
  /* Setting the base URL for the axios instance. */
  baseURL: 'https://alqa.autometrics.in/ashokleyland_app/',
  //  baseURL: 'https://al-autometrics.in/ashokleyland/', //prod server

  // baseURL:'http://localhost:5006/ashokleyland',
  /* other custom settings */
});

module.exports = axiosInstance;
