import React, { Component } from 'react'; 
const   UpdateWareHouseChargesBtn= (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        if (props.context&& props.context&&props.context.onClickWareHouseChargesUpdateBtn) {
            props.context.onClickWareHouseChargesUpdateBtn(props);
        } else {
            console.error("componentParent or onClickWareHouseChargesUpdateBtn is not defined.");
        }
    };
    return (
        <div>
            {(props.data.invoice_no !== "Total") &&
                <button type="button"
                    className={"btn btn-warning nopadding font12"}
                    title="ViewRequest"
                    onClick={handleClick} >
                    <i className="icofont icofont-edit f16"></i>&nbsp;Update Charges
                </button>
            }
        </div>
    );
};
export default UpdateWareHouseChargesBtn;